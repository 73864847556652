import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import './header.css'
import logo from '../images/terlemez-3.png'
import { burgerMenu, closeburgerMenu } from '../redux/actions'

function Header(props){

  const[state,setstate]=useState(false)
  useEffect(()=>{
   
   window.addEventListener('scroll', ()=>{
    if(window.pageYOffset<23){
      setstate(false)
      }else{
      setstate(true)
     }
   })    
 },[])


 
 
    return(
      <>
          <div className={props.nav.burgerState==false?'closedMenu':'openedMenu'}>
            <ul>
              {
                props.nav.nav.map((item,index)=>{
                  return <li key={index}><Link onClick={()=>{props.dispatch(closeburgerMenu())}} to={item.href}>{item.name}</Link></li>
                })
              }
            </ul>
          </div>

        <header className='container'>
          
          <div className={state==true?'stycky':'row'}>
          <div className='col-md-6'>
          <Link to='/'>
            <img className='logo1' src={logo}/>
            </Link>
          </div>
          <div className='col-md-6'>
          <div className={props.nav.burgerState==false?"burgerMenu":"burgerMenu change"} onClick={()=>{props.dispatch(burgerMenu())}}>
            <div className="bar1"></div>
            <div className="bar2"></div>
            <div className="bar3"></div>
          </div>
            <nav>
              <ul className='navigation'>
                      <li className='nav-item'>
                       <Link to='/' className='nav-link'>Գլխավոր</Link>
                            <div 
                            className='subMenu'>
                                <ul>
                                  <li><Link to='/history'>Պատմություն</Link></li>
                                  <li><Link to='/admission'>Ընդունելություն</Link></li>
                                  <li><Link to='/programm'>Ծրագրեր</Link></li>
                                </ul>
                            </div>
                      </li>
                
                      <li className='nav-item'>
                        <Link to='#' className='nav-link'>Մեր մասին</Link>
                            <div className='subMenu'>
                                <ul>
                                  <li><Link to='/overview'>Պատմական ակնարկ</Link></li>
                                  <li><Link to='/mission'>Առաքելություն</Link></li>
                                  <li><Link to='/structure'>Կառուցվածք</Link></li>
                                  <li><Link to='/documents'>Փաստաթղթեր</Link></li>
                                  <li><Link to='/library'>Գրադարան</Link></li>
                                  <li><Link to='/contact'>Հետադարձ կապ</Link></li>
                                  
                                </ul>
                            </div>
                      </li>
                   
                      <li className='nav-item'>
                        <Link to='#' className='nav-link'>Բաժիններ</Link>
                            <div className='subMenu'>
                                <ul>
                                  <li><Link to='/painting'>Գեղանկարչություն</Link></li>
                                  <li><Link to='/decor'>Զարդակիրառական արվեստ</Link></li>
                                  <li> <Link to='/sculpture'>Քանդակագործություն</Link></li>
                                  <li><Link to='/design'>Դիզայն</Link></li>
                                </ul>
                            </div>
                      </li>
              
                      <li className='nav-item'>
                        <Link to='#' className='nav-link'>Ուսանող</Link>
                            <div className='subMenu'>
                                <ul>
                                  <li><Link to='/advice'>Ուս. խորհուրդ</Link></li>
                                  <li><Link to='/graduates'>Շրջանավարտներ</Link></li>
                                  <li><Link to='/career'>Կարիերայի և որակի կենտրոն</Link></li>
                                  <li><Link to='/links'>Հղումներ</Link></li>
                                </ul>
                            </div>
                      </li>
              </ul>
            </nav>
          </div>
          </div>
      </header>
      </>
    )
}

export default connect(r=>r)(Header)