import React, { useEffect,useState } from 'react'
import { connect } from 'react-redux'
import Contact from '../../parts/contactForm'
import img1 from '../../images/linkp.jpg'
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import sl1 from '../../images/linkimg1.jpg'
import sl2 from '../../images/linkimg2.jpg'
import sl3 from '../../images/linkimg3.jpg'
import sl4 from '../../images/linkimg4.jpg'
import sl5 from '../../images/linkimg5.jpg'
import sl6 from '../../images/linkimg6.jpg'

import Carousel from 'react-elastic-carousel'
import Preloader from '../../parts/preloader';
import './links.css'

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      marginBottom:'40px'
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: 'bolder',

      
    },
  }));

function Links(){
  document.title='Links - Terlemezyan'
  const[load,setLoad]=useState(true)
  
    useEffect(()=>{
      window.scrollTo(0, 0);
      setLoad(false)
    },[])

  
    const classes = useStyles();
    const informs = [
        {title:'ՀԱՅԱՍՏԱՆԻ ՀԱՆՐԱՊԵՏՈՒԹՅԱՆ ԿՐԹՈՒԹՅԱՆ, ԳԻՏՈՒԹՅԱՆ, ՄՇԱԿՈՒՅԹԻ ԵՎ ՍՊՈՐՏԻ ՆԱԽԱՐԱՐՈՒԹՅՈՒՆ',link:'edu'},
        {title:'ԿՐԹԱԿԱՆ ԾՐԱԳՐԵՐԻ ԿԵՆՏՐՈՆ',link:'cfep'},
        {title:'ԿՐԹԱԿԱՆ ՏԵԽՆՈԼՈԳԻԱՆԵՐԻ ԱԶԳԱՅԻՆ ԿԵՆՏՐՈՆ',link:'ktak'},
        {title:'ՄԱՍՆԱԳԻՏԱԿԱՆ ԿՐԹՈՒԹՅԱՆ և ՈՒՍՈՒՑՄԱՆ ԶԱՐԳԱՑՄԱՆ ԱԶԳԱՅԻՆ ԿԵՆՏՐՈՆ',link:'mkuzak'},
        {title:'ՄԱՍՆԱԳԻՏԱԿԱՆ ԿՐԹՈՒԹՅԱՆ և ՈՐԱԿԻ ԱՊԱՀՈՎՄԱՆ ԱԶԳԱՅԻՆ ԿԵՆՏՐՈՆ ՀԻՄՆԱԴՐԱՄ',link:'anqa'},
        {title:'ՀՀ ԳԻՏՈՒԹՅԱՆ ԿՈՄԻՏԵ',link:'scs'},
        {title:'ՀՀ ԲԱՐՁՐԱԳՈՒՅՆ ՈՐԱԿԱՎՈՐՄԱՆ ԿՈՄԻՏԵ',link:'scs'},
        {title:'ԳՆԱՀԱՏՄԱՆ ԵՎ ԹԵՍՏԱՎՈՐՄԱՆ ԿԵՆՏՐՈՆ',link:'bok'},
    ]
    return (
        <>
          {
            load==true?(
              <Preloader/>
            ):(
              <div className='painting-page'>
        <div style={{height:'390px',background: 'rgba(0,0,0,0.4)'}}>
            <img style={{height: '100%',width: '100%',objectFit: 'cover',objectPosition: '50% 65%'}} src={img1} />
        </div>
            <section>
                <h3 style={{fontSize:'36px'}}>ՀՂՈՒՄՆԵՐ</h3>
                <div className={classes.root}>
                    {
                        informs.map((item,index)=>{
                            return(
                                <Accordion key={index}>
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                  >
                                    <Typography className={classes.heading}>{item.title}</Typography>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <Typography>
                                        <a target="_blank" href={`http://www.${item.link}.am`} style={{color:'#560000',fontSize:'20px'}}>{`www.${item.link}.am`}</a>
                                    </Typography>
                                  </AccordionDetails>
                                </Accordion>
                            )
                        })
                    }
                 </div>

            </section>
            <Carousel 
          itemsToShow={3} 
          enableAutoPlay={true} 
          autoPlaySpeed={3000} 
          showArrows={false} 
          enableMouseSwipe={true}>
          <img src={sl1}/>
          <img src={sl2}/>
          <img src={sl3}/>
          <img src={sl4}/>
          <img src={sl5}/>
          <img src={sl6}/>
        </Carousel>
            <Contact/>
        </div>
 
            )
          }
        </>
    )
}

export default connect(r=>r)(Links)