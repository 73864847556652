import React from 'react'
import style from './load.module.css'
import CircularProgress from '@material-ui/core/CircularProgress';

export default function Loading(){
    return(
        <div className={style.load}>
        <CircularProgress/>
        </div>
    )
}