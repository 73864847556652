import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {createStore,applyMiddleware} from 'redux'
import {Provider} from 'react-redux'
import rootState from './redux/reducers/root'
import thunk from 'redux-thunk';
import Preloader from './parts/preloader';
 


 
let store = createStore(rootState,applyMiddleware(thunk))


ReactDOM.render(
  
  <Provider store={store}>
    <Suspense fallback={<Preloader/>}>
    <App />
    </Suspense>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
