import React, { useEffect,useState } from 'react'
import { connect } from 'react-redux'
import bg1 from '../../images/bgoverlay.jpg'
import Contact from '../../parts/contactForm'
import Preloader from '../../parts/preloader'

import './mission.css'
function Mission(){
    document.title='Mission - Terlemezyan'
    const[load,setLoad]=useState(true)
  
    useEffect(()=>{
      window.scrollTo(0, 0);
      setLoad(false)
    },[])

   
    const styles={
        head:{
            backgroundColor: `rgba(188,174,173,0.13)`,
            backgroundImage:`url(${bg1})`,
            backgroundPosition: '50% 40%',
            backgroundSize: 'cover',
            height:'366px'
        },
        overlay:{
            width:'1100px',
            height:'256px',
            backgroundColor:'#560000',
            opacity: 0.4,
            position:'absolute',
            right:'10%',
            top:'40px'
        },
        text:{
            textAlign:'center',
            padding:'80px 0px 80px 0px',
            color:'#ffcc99'
            
        }
    }
    return(
       <>
            {
                load==true?(
                    <Preloader/>
                ):(
                    <>
                    <div className='mission-page' style={styles.head}/>
                    <article>
                        <h1>Առաքելություն</h1>
                        <p style={{color: '#560000', fontSize: '18px', fontWeight: '100',}}>&nbsp;&nbsp;&nbsp;&nbsp;Թերլեմեզյան քոլեջի առաքելությունն է, 
                            ստեղծել բարենպաստ պայմաններ անձնական որակների 
                            զարգացման համար, լինելով պատանիների շրջանում նոր 
                            ստեղծագործողների բացահայտման կարևորագույն օղակ, իրականացնել գեղարվեստական կրթություն:</p>
                            <br/>
                        <p>Փորձենք ձևակերպել գեղարվեստական կրթության սահմանումը.</p><br/>
                        <ul>
                            <li>Պրակտիկ գործունեության համակարգ, որը ենթադրում է 
                                իրավական դաշտի կարգավորման, նյութատեխնիկական բազայի, 
                                կադրային քաղաքականության, ֆինանսական աղբյուրների ապահովման 
                                գործառույթների փոխկապակցված և շարունակական շղթա:</li>
                            <br/>
                            <li>Կրթական դաշտի կարևորագույն բաղադրիչ, որն անհրաժեշտ է
                                 ինքնաբավ, ստեղծագործական միտք կրող, հարուստ ներաշխարհով
                                  անհատականություն, առողջ և քաղաքակիրթ սերունդ, ինչպես նաև, 
                                  որպես հեռահար նպատակ, քաղաքացիական հասարակություն կերտելու նպատակով:</li>
                            <br/>
                            <li>Ազգային, գեղարվեստական մշակույթի յուրացման գործընթաց, որն ուղղորդվում է 
                                հատուկ կարողությունների և հմտությունների զարգացման միջոցով, մշակույթի 
                                պահպանման և տարածման յուրօրինակ մեթոդ:</li>
                        </ul>
            
                        <p>Թերլեմեզյանի քոլեջը նաև իրականացնում է շատ կարևոր սոցիալ-մշակութային` 
                            մասնագիտությունների պահպանման և վերարտադրության գործառույթ /մասնավորապես 
                            շուկայական հարաբերություններում մոռացվող և կորսվող մասնագիտությունների 
                            պարագայում/, Հայաստանում ձևավորելով սոցիալ-մշակութային և տեղեկատվական 
                            միջավայրի հասարակական պահանջարկ:</p>
                    </article>
                    <Contact/>
                    </>
            
                )
            }
       </>
    )
}

export default connect(r=>r)(Mission)
