import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { StylesProvider } from '@material-ui/core';
import styles from '../components/loadingComponent/load.module.css'
function Preloader(){
        return (
            <div className={styles.load}>
                <CircularProgress/>
            </div>
        )
}
 
export default Preloader;