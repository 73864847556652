import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import img1 from '../../images/designP.jpg'
import imgs1 from '../../images/design1.jpg'
import imgs2 from '../../images/design2.jpg'
import imgs3 from '../../images/design3.jpg'
import imgs4 from '../../images/design4.jpg'
import imgs5 from '../../images/design5.jpg'
import imgs6 from '../../images/design6.jpg'
import imgs7 from '../../images/design7.jpg'
import imgs8 from '../../images/design8.jpg'
import imgs9 from '../../images/design9.jpg'
import img6 from '../../images/desP.jpg'
import Contact from '../../parts/contactForm'
 import Carousel from 'react-elastic-carousel'
 import './design.css'
import Preloader from '../../parts/preloader'
function Design(){
  document.title='Design - Terlemezyan'
  const[load,setLoad]=useState(true)
  
    useEffect(()=>{
      window.scrollTo(0, 0);
      setLoad(false)
    },[])
 
    return(
        <>
            {
                load==true?(
                    <Preloader/>
                ):(
                    <div className='painting-page'>
        <div style={{height:'390px',background: 'rgba(0,0,0,0.4)'}}>
            <img style={{height: '100%',width: '100%',objectFit: 'cover'}} src={img1} />
        </div>
        <article>
            <h1 style={{fontSize:'40px',textAlign:'center'}}>Դիզայն</h1><br/>
            <p>Երևանի Փ. Թերլեմեզյանի անվան գեղարվեստի 
                պետական քոլեջը պետական կրթական չափորոշիչների, 
                հաստատված ուսումնական պլանների և առարկայական 
                ծրագրերի միջոցով պատրաստում է բարձր որակի 
                մասնագետներ ընդհանուր դիզայնի, ինտերիերի, 
                էքստերիերի, դիզայներական գեղագիտական որակներ 
                պահանջող արտադրության և այլ ոլորտների համար: 
            </p><br/>
            <p>Ուսուցման ձևը` առկա, անվճար և վճարովի համակարգերով:</p>
            <ul>
                <li>Ուսուցման տևողությունը` չորս տարի:</li><br/>
                <li>Ուսման վարձը` 350000 դրամ:</li><br/>
                <li>Որակավորումը` դիզայներ: </li>
            </ul>
        </article>
        <section>
        <Carousel 
          itemsToShow={2} 
          enableAutoPlay={true} 
          autoPlaySpeed={3000} 
          showArrows={false} 
          enableMouseSwipe={true}>
          <img src={imgs1}/>
          <img src={imgs2}/>
          <img src={imgs3}/>
          <img src={imgs4}/>
          <img src={imgs5}/>
          <img src={imgs6}/>
           
        </Carousel>
 
        </section>
        <article>
            <h1>«Դիզայն» առարկայական հանձնաժողովի նախագահ</h1>
            
            <div className='main-card'>
                <div className='cardImg' data-aos='fade-up'>
                    <img src={img6}/>
                </div>
                <div className='cardDescr'>
                    <h1>Վարդանուշ Ղազարյան</h1>
                    <ul>
                        <li>1960թ.-ին ծնվել է ք. Երևանում, ՀՀ </li> 
                        <li>1987թ.-ին ավարտել է Երևանի Խ. Աբովյանի անվան պետական Մանկավարժական  ինստիտուտի,  նկարչա-գծագրական ֆակուլտետը:</li>
                        <li>1990թ-ից` դասավանդում է Փ. Թերլեմեզյանի անվան Գեղարվեստի պետական քոլեջում: </li> 
                        <li>2000թ-ից` ՀՀ Նկարիչների միության անդամ :</li> 
                        <li>Մասնակցել է բազմաթիվ ցուցահանդեսների:</li>
                    </ul>
                </div>
            </div>
        </article>
        <section>
        <Carousel 
          itemsToShow={2} 
          enableAutoPlay={true} 
          autoPlaySpeed={3000} 
          showArrows={false}
          enableMouseSwipe={true}>
            <img src={imgs7}/>
            <img src={imgs8}/>
            <img src={imgs9}/>
        </Carousel>
        </section>
        <Contact/>
      </div>

                )
            }
        </>
        )
}

export default connect(r=>r)(Design)