import React, { useState,useEffect } from 'react'
import { connect } from 'react-redux'
import Contact from '../../parts/contactForm'

import './contactp.css'
import img1 from '../../images/contdir.jpg'
import 'react-slideshow-image/dist/styles.css'
import Preloader from '../../parts/preloader'


 

function ContactComponent(props){ 
    const[load,setLoad]=useState(true)
  
    useEffect(()=>{
      window.scrollTo(0, 0);
      setLoad(false)
    },[])

  
    document.title='Contact - Terlemezyan'  
    return(
      <>
        {
            load==true?(
                <Preloader/>
            ):(
                <div className='home-page'>
        <div style={{height:'390px',background: 'rgba(0,0,0,0.4)'}}></div>
        <section className='contactSection' style={{backgroundColor:'white'}}>
            <div className='contacts'>
                <h3> ՀԵՏԱԴԱՐՁ ԿԱՊ </h3>
               <div className='contact-cards'>
                    {
                        props.contact.contacts.map((item,index)=>{
                            return(
                                <div className='contact-card' key={index} data-aos='fade-up'>
                                    <div className='card-img'>
                                        <img src={item.img}/>
                                    </div>
                                    <div className='card-content'>
                                        <h2 className='card-title'>{item.name}</h2>
                                        <hr/>
                                        <p>{item.position}</p>
                                        <div className='contact-detales'>
                                            <p>{item.contact1}</p>
                                            <p>{item.contact2}</p>
                                        </div>
                                    </div>
                                </div>

                            )
                        })
                    }  
               </div>
            </div>    
        </section>
        <section className='bgc'>
        <div className='analitics'>
          <div className='overlay2'></div>
             
        </div>  
        </section>

       <Contact/>
      </div>

            )
        }
      </>
    )
}

export default connect(r=>r)(ContactComponent)