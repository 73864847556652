import React, {useState,useEffect} from 'react'
import { connect } from 'react-redux'
import Contact from '../../parts/contactForm'
import img1 from '../../images/GrP.jpg'
import './gr.css'
import img2 from '../../images/footerLogo.png' 
import Preloader from '../../parts/preloader'
 


function Graduates(props){ 
    const[load,setLoad]=useState(true)
  
    useEffect(()=>{
      window.scrollTo(0, 0);
      setLoad(false)
    },[])

   
    document.title='Graduates - Terlemezyan'
    const cardstate=
        [
            {h3:'ՊԱՀԱՆՋՆԵՐ',p:'ԴԻՊԼՈՄԱՅԻՆ ԹՂԹԱՊԱՆԱԿԻ ՁԵՎԱՎՈՐՄԱՆ ՊԱՀԱՆՋՆԵՐ',href:"https://drive.google.com/file/d/1j39qxKC8w4TTnsK5Fo2lAlpXEjdi25yh/view"},
            {h3:'ՏԻՏՂՈՍԱԹԵՐԹ',p:'ԴԻՊԼՈՄԱՅԻՆ ԹՂԹԱՊԱՆԱԿԻ ԱՌԱՋԻՆ ԷՋ',href:"https://drive.google.com/file/d/1bPSU0gMy6g08e5d-4g6x_sRK3Tt4-b59/view"},
            {h3:'ԴԻՊԼՈՄԱՅԻՆ ԱՇԽԱՏԱՆՔԻ ՆԵՐԿԱՅԱՑՈՒՄ',p:'ՂԵԿԱՎԱՐԻ ՀԱՍՏԱՏՈՒՄ',href:"https://drive.google.com/file/d/1dVBoRqoNjzn6Dcai7uQ8LUgXTQdnMtim/view"},
            {h3:'ԿԵՆՍԱԳՐՈՒԹՅՈՒՆ',p:'ՇՐՋԱՆԱՎԱՐՏԻ ԻՆՔՆԱԿԵՆՍԱԳՐԱԿԱՆ /CV/',href:"https://drive.google.com/file/d/1v3W_H941eJNEvIxNPOrhGlvZHWY2ydcX/view"},
        ]
     
    return(
        <>
            {
                load==true?(
                    <Preloader/>
                ):(
                    <div className='graduates-page'>
        <div style={{height:'390px',background: 'rgba(0,0,0,0.4)'}}>
            <img style={{height: '100%',width: '100%',objectFit: 'cover'}} src={img1} />
        </div>
         <article className='grpArt'>
         <h1 style={{fontSize:'36px'}}>ՇՐՋԱՆԱՎԱՐՏՆԵՐ</h1>
         <p>Ավարտական կուրսի հարգելի ուսանողներ, </p>
             <p>Այստեղ կարող եք տեսնել վերջին ուսումնական տարվա ժամանակացույցը, ուսումնական տարվա վերջում ներկայացվելիք թղթապանակի (portfolio) բովանդակությունը: </p>
            <h2 style={{textAlign:'center'}}>Ժամանակացույց</h2>
            <p>Դեկտեմբերի առաջին շաբաթ – դիպլոմային աշխատանքների էսքիզների նախնական դիտում, առաջին կիսամյակի քննություններ,</p>
            <br/>
            <p>Մարտ- էսքիզների հաստատում,</p>
            <br/>
            <p>Ապրիլ – երկրորդ կիսամյակի քննություններ,</p>
            <br/>
            <p>Մայիսի առաջին շաբաթ – Գեղանկարչություն, Քանդակագործություն` «Մանկավարժություն, մասնագիտական առարկաների</p>
            <p>դասավանդման մեթոդիկա» առարկայի պետական քննություն,</p>
            <br/>
            <p>Հունիսի առաջին շաբաթ – ընդիմախոսի նշանակում, թղթապանակների հանձնում,</p>
            <br/>
            <p>Հունիսի 2-րդ տասնօրյակում – դիպլոմային աշխատանքների պաշտպանություն,</p>
            <br/>
            <p>Հունիսի 28 – հուլիսի 2-ը դիպլոմների բաշխում:</p>
         
         
         </article>
         <div className='docCards'> 
                {
                    cardstate.map((item,index)=>{
                        return(
                        <div key={index} className='doccard' data-aos='fade-up'>
                            <div>
                                <img src={img2} width='60px' />
                            </div>
                            <div className='cardContent'>
                                <h3>{item.h3}</h3>
                                <p>{item.p}</p>
                            </div>
                            <a href={item.href}>Ներբեռնել</a>
                        </div>
                        )
                    })
                }
            </div>
         <section className='bgGr'>
            <div>
                <div className='overlaygr'></div>
                <div className='overlaygr2'>
                <div className='bgoverlayGR'>
                    <h1>ԴԻՊԼՈՄԱՅԻՆ ԱՇԽԱՏԱՆՔՆԵՐ</h1>
                    <a href="https://www.facebook.com/pg/terlemezyan.college/photos/?tab=album&album_id=2472265806165576&ref=page_internal">ԱՎԵԼԻՆ</a>
                </div>
                </div>
                
            </div>
         </section>

         
       <Contact/>
      </div>

                )
            }
        </>    
    )
}

export default connect(r=>r)(Graduates)