import contactState from "../states/contactState";


export default function contactReducer(state=contactState,action){
    let temp = {...state}

    if(action.type==='inpchange'){
        temp.contactInputs[action.key]=action.value
        return temp
    }

    if(action.type==='validate'){
        if(!temp.contactInputs.name){
            temp.errors.error1='The field is required.'
        }else{
            temp.errors.error1='' 
        }

        if(!temp.contactInputs.email){
            temp.errors.error2='The field is required.'
        }else{
            temp.errors.error2='' 
        }
    }

    return temp
}