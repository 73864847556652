import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import './home.css'
import sl1 from '../images/slider1.jpg'
import sl2 from '../images/slider2.jpg'
import sl3 from '../images/slider3.jpg'
import item from '../images/Anna-signature.jpg'
import director from '../images/director.jpg'
import bg1 from '../images/bg7.jpg'
import bg2 from '../images/bg-1-1.jpg'
import bg3 from '../images/Untitled-1-2.jpg'

import 'react-slideshow-image/dist/styles.css'
import { Fade } from 'react-slideshow-image';
import { Link } from 'react-router-dom';
import Contact from '../parts/contactForm'
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import VisibilitySensor from 'react-visibility-sensor'
import CountUp from 'react-countup'
import Preloader from '../parts/preloader'
 


function Home(props,{ className, ...rest }){ 
  
  document.title='Home - Terlemezyan'
  const[load,setLoad]=useState(true)
  
    useEffect(()=>{
      window.scrollTo(0, 0);
       
      setLoad(false)
     },[])
 
  const [viewPortEntered, setViewPortEntered] = useState(false);
    return(
      <>
      {
        load==true?(
          <Preloader/>
        ):(
          <div className='home-page'>
        <div>
        <Fade easing="ease">
          <div className="each-slide">
            <div className='caption' style={{'backgroundImage': `url(${sl1})`}}>
              <span>Արվեստը կյանքի առջևից է քայլում և լուսավորում ապագան: <br/><Link to='/' >Կոստան Զարյան</Link>  </span>
                <div className='sliderMask'></div>
            </div>
          </div>
          <div className="each-slide">
            <div className='caption' style={{'backgroundImage': `url(${sl2})`}}>
              <span>Գեղարվեստի առաջին  կրթօջախը Հայաստանում</span>
                <div className='sliderMask'></div>
            </div>
          </div>
          <div className="each-slide">
            <div className='caption' style={{'backgroundImage': `url(${sl3})`}}>
              <span>«Երևանի Փանոս Թերլեմեզյանի անվան գեղարվեստի պետական քոլեջ» ՊՈԱԿ<br/><a href='https://drive.google.com/file/d/1K0wmW6-MLMwKOx4m-7ZFX1iyx9cLl-ps/view'>Ավելին</a></span>
                <div className='sliderMask'></div>
            </div>
          </div>
        </Fade>
        </div>
          <div style={{backgroundColor:'white', textAlign:'center'}}>
            <ExpandMoreIcon style={{width:'60px',height:'60px'}}/>
          </div>
        <div className='row1'>
          <Link data-aos='fade-up' to='/history'>
            <div className='card1'>
               
              <img src={bg1}/>
              
              <div className="box-with-icon">
                <div className='abouteIcon'>
                <i className="fa fa-info"></i>
                </div>
                <div className='abouteText'>
                <h3 className="icon-title">Մեր մասին</h3>
                <Typography className="box-sub-title icon-subtitle">պատմություն</Typography>
                </div>
              </div>
            </div>
          </Link>
          
          <Link className='adapt' data-aos='fade-up' to='/admission'>
            <div className='card'>
            <img src={bg2}/>
            <div className="box-with-icon">
                <div className='abouteIcon'>
                <i className="fa fa-product-hunt"></i>
                </div>
                <div className='abouteText'>
                <h3 className="icon-title">Դիմորդ</h3>
                <Typography className="box-sub-title icon-subtitle">Ընդունելություն</Typography>
                </div>
              </div>
            </div>
          </Link>
          
          <Link className='adapt' data-aos='fade-up' to='/programm'>
            <div className='card'>
            <img src={bg3}/>
            <div className="box-with-icon">
                <div className='abouteIcon'>
                <i className="fa fa-tablet"></i>
                </div>
                <div className='abouteText'>
                <h3 className="icon-title">Մեդիա</h3>
                <Typography className="box-sub-title icon-subtitle">ԾՐԱԳՐԵՐ</Typography>
                </div>
              </div>
            </div>
          </Link>
          
        </div>

        <section className='firstSection'>
          <div className='first-col'>
            <div className='heading' data-aos='fade-up'>
              <div className='col1'>
                <div className='divider'></div>
              </div>
              <div className='col2'>
                  <h2 style={{color:'black'}}>Տնօրեն՝ <br/><Typography className='director'>ԱՆՆԱ ՄՆԱՑԱԿԱՆՅԱՆ</Typography></h2>
              </div>
            </div>
            <div className='content1' data-aos='fade-up'>
              <img src={director}/>
            </div>
          </div>

          <div className='second-col'>
            <div className='maintext'  data-aos='fade-up'>
            <b style={{fontSize:'16px',color:'black'}}>Բարի գալուստ Երևանի Փ. Թերլեմեզյանի անվան գեղարվեստի պետական քոլեջ</b>
              <br/>
              <br/>
            <p className='typ'> Անհերքելի փաստ է, որ երիտասարդությունը յուրաքանչյուր երկրի ռազմավարական
                 ամենահզոր ռեսուրսն է: Երկրի ապագան պայմանավորված է երիտասարդությամբ, և
                  հատկապես նրանց առաջ քաշած նպատակներով: Հենց երիտասարդությանն է 
                  պատկանում երկրի կայուն զարգացման հարցում արդիական մտածողության դերը:</p>
                  &nbsp; 
                  
              <p className='typ'>Սերը, բարին, ճշմարիտը, գեղեցիկը, ազատությունը, 
                արժանապատվությունը մնում են առանցքային այն արժեքները,
                 որոնք գեղագիտության հիմնարար հենքն են: Թերլեմեզյանի քոլեջը
                  արժեքների և գեղագիտության ընդհանրական տարածք է, որտեղ մեր
                   ուսանողներին տրվում է հնարավորություն հասնելու իրենց նպատականերին,
                    իրացնելու իրենց տաղանդը, անսպառ էներգիան, ստեղծագործական մտածողությունը:
                     Չափաձողը, որ սահմանված է քոլեջի գունագեղ աշխարհում, հավակնոտ բարձր է:
                      Արդյունքում ունենում ենք սերունդ, որը կենսունակ է, սակայն նաև իր խենթ
                       մտածողությամբ մեզ է մատուցում նոր ժամանակների ողջ հմայքը:</p>
                       <div className='lastText' data-aos='fade-up'>
              <div className='elm1'>
                <img src={item}/>
              </div>
              <div className='elm2'>
                  <h3><span className='name'>Աննա Մնացականյան</span><br/><span className='dir'>ՔՈԼԵՋԻ ՏՆՕՐԵՆ</span></h3>
              </div>
            </div>
            </div>
          
           
          </div>
        </section>
        
        <section className='bgAnalitics'>
        <div className='analitics'>
          <div className='overlay'></div>
          
            <div className='analiticBlock' data-aos='fade-up'>
              <div className='analiticHeading'>
                <h1>Վիճակագրություն</h1>
                <Typography>ՓԱՍՏԵՐ ԹՎԵՐՈՎ...</Typography> 
              </div>
              <div className='analiticIcons'>

                <div className='analiticGlobal'>
                <div className='analiticCard'>
                  <i className='fa fa-institution'></i>
                 </div>
                 <div className='counter'>
                 <CountUp {...rest} start={viewPortEntered ? null : 0} end={new Date().getFullYear() - 1921} duration={3} delay={0.3}>
                    {({ countUpRef }) => {
                      return (
                        <VisibilitySensor
                          active={!viewPortEntered}
                          onChange={isVisible => {
                            if (isVisible) {
                              setViewPortEntered(true);
                            }
                          }}
                          delayedCall
                        >
                          <span className={className} ref={countUpRef} />
                        </VisibilitySensor>
                      );
                    }}
                  </CountUp>
                      <Typography>ՏԱՐՎԱ ՊԱՏՄՈՒԹՅՈՒՆ</Typography>
                  </div>
                </div>

                <div className='analiticGlobal'>
                <div className='analiticCard'>
                  <i className='fa fa-group'></i>
                </div>
                  <div className='counter'>
                  <CountUp {...rest} start={viewPortEntered ? null : 0} end={809} duration={3} delay={0.3}>
                    {({ countUpRef }) => {
                      return (
                        <VisibilitySensor
                          active={!viewPortEntered}
                          onChange={isVisible => {
                            if (isVisible) {
                              setViewPortEntered(true);
                            }
                          }}
                          delayedCall
                        >
                          <span className={className} ref={countUpRef} />
                        </VisibilitySensor>
                      );
                    }}
                  </CountUp>                   
                      <Typography>ՈՒՍԱՆՈՂ</Typography>
                  </div>
                </div>
                  
                <div className='analiticGlobal'>
                <div className='analiticCard'>
                  <i className='fa fa-graduation-cap'></i>
                </div>
                <div className='counter'>
                <CountUp {...rest} start={viewPortEntered ? null : 0} end={7395} duration={3} delay={0.3}>
                    {({ countUpRef }) => {
                      return (
                        <VisibilitySensor
                          active={!viewPortEntered}
                          onChange={isVisible => {
                            if (isVisible) {
                              setViewPortEntered(true);
                            }
                          }}
                          delayedCall
                        >
                          <span className={className} ref={countUpRef} />
                        </VisibilitySensor>
                      );
                    }}
                  </CountUp>  
                      <Typography>ՇՐՋԱՆԱՎԱՐՏ</Typography>
                  </div>
                </div>
                
                <div className='analiticGlobal'>
                <div className='analiticCard'>
                  <i className='fa fa-book'></i>
                </div>
                <div className='counter'>
                <CountUp {...rest} start={viewPortEntered ? null : 0} end={13000} duration={3} delay={0.3}>
                    {({ countUpRef }) => {
                      return (
                        <VisibilitySensor
                          active={!viewPortEntered}
                          onChange={isVisible => {
                            if (isVisible) {
                              setViewPortEntered(true);
                            }
                          }}
                          delayedCall
                        >
                          <span className={className} ref={countUpRef} />
                        </VisibilitySensor>
                      );
                    }}
                  </CountUp>  
                       <Typography>ԳԻՐՔ՝ ԳՐԱԴԱՐԱՆՈՒՄ</Typography>
                  </div>
                </div>
                
              </div>
            </div>
        </div>  
        </section>

       <Contact/>
      </div>

        )
      }
      </>
    )
}

export default connect(r=>r)(Home)