import axios from 'axios'
import homePage from './states/homePage'

export function burgerMenu(){
    return{
        type:'openBurger',
        
    }
}

export function closeburgerMenu(){
    return{
        type:'closeburgerMenu'
    }
}

export function inpchange(key,value){
    return{
        type:'inpchange',
        key,
        value
    }
}

export function validate(){
    return{
        type:'validate'
    }
}

export function loadCards(){
    return (dispatch)=>{

        async function fetchData(){
            const gotData = await axios
            .get('http://localhost:5000/gallery')
            .then(r=>{
                dispatch(updateCards(r.data))
            })
                dispatch(loadSpinner(true))
        }

        fetchData()
    }
    
}
 
export function updateCards(data){
    return{
        type:'updateCards',
        data
    }
}

export function selectChange(key){
    return(dispatch)=>{
        async function fetchData(){
            const gotData = await axios
            .post('http://localhost:5000/filter',{years:key})
            .then(r=>{
                dispatch(updateCards(r.data))
            })
                dispatch(loadSpinner(true))
        }
        fetchData()     
    }
}

export function getEvent(id){
    return (dispatch)=>{
        async function fetchData(){
            dispatch(loadSpinner(true))

            const gotData = await axios
            .post('http://localhost:5000/get-event',{id:id})
            .then(r=>{
                 dispatch(updateEvent(r.data));
                })
        }
        fetchData()
    }
}

export function loadSpinner(data){
    return{
        type:'loadSpinner',
        data
    }
}

export function updateEvent(data){
    return{
        type:'updateEvent',
        data
    }
}

export function openSlider(target){
    return{
        type:'openSlider',
        target
    }
}

export function closeSlide(){
    return{
        type:'closeSlide'
    }
}

