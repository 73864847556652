import React from 'react';
import { connect } from 'react-redux';
import './event.css'
import HighlightOffSharpIcon from '@material-ui/icons/HighlightOffSharp';
import { closeSlide } from '../../redux/actions';
function Slider(props){
  console.log(props);
    return(
     <div className={props.gal1.open==true?'slider':'noslide'}>
       <HighlightOffSharpIcon onClick={()=>{props.dispatch(closeSlide())}}/>
       <div>
         
        <img src={`${props.gal1.slidePhoto}`} />
       </div>
     </div>

    )
}
  
export default connect(r=>r)(Slider);