import React, { useState,useEffect } from 'react'
import { connect } from 'react-redux'
import img1 from '../../images/history1.jpg'
import img2 from '../../images/Hovhannes.jpg'
import './history.css'
import Contact from '../../parts/contactForm'
import Preloader from '../../parts/preloader'
function History(){
    document.title='History - Terlemezyan'
    const[load,setLoad]=useState(true)
  
    useEffect(()=>{
      window.scrollTo(0, 0);
    
    setLoad(false)
    },[])
 
    return(
    <>
    {
        load==true?(
            <Preloader/>
        ):(
            <div className='history'>
        <div className='history-page'>
        
            <img src={img1} />
        </div>
        <article>
            <h1 style={{fontSize:'36px'}}>Պատմություն</h1>
            <p>&nbsp;&nbsp;&nbsp;Երևանի Փանոս Թերլեմեզյանի անվան գեղարվեստի
                 պետական քոլեջ,ՀՀ կրթության, գիտության, 
                 մշակույթի և սպորտի նախարարության ենթակայության 
                 տակ գտնվող պետական ոչ առևտրային կազմակերպություն, 
                 Հայաստանի առաջին նկարչական դպրոց։ </p>

            <p>&nbsp;&nbsp;&nbsp;Նկարչական դպրոցը ստեղծվել է 1921 թվականին՝ 
                Ժողովրդական կոմիսարների խորհրդի հատուկ դեկրետով, 
                Ալեքսանդր Մյասնիկյանի ստորագրությամբ, ՀԽՍՀ առաջին 
                լուսժողկոմ Աշոտ Հովհաննիսյանի օրոք։ Սկզբնապես կոչվել 
                է «Գեղարդ»  գեղարվեստա-արդյունաբերական տեխնիկում, 
                1938 թվականին՝ գեղարվեստական ուսումնարան, 1941 
                թվականին վերանվանվել է հայ մեծ նկարիչ Փանոս Թերլեմեզյանի
                 անունով և կարճ ժամանակահատվածում դարձել գեղարվեստի 
                 միջնակարգ լավագույն կրթարաններից մեկը։</p>

            <div className='inline-text'>
                <div className='art-img'>
                <img src={img2}/>
                </div>
                <div className='text'>
                    <p>  &nbsp;&nbsp;&nbsp;Առաջին տնօրենն է եղել Հովհաննես Թադևոսյանը, 
                        որը գլխավորել է ուսումնական աշխատանքներն ու
                         կազմել դասատուների կազմը։
                          Հաստատության ակունքներում են
                           կանգնած ժամանակին Եվրոպայի լավագույն 
                           կենտրոններում՝ Վիեննայում, Փարիզում, Մյունխենում,
                            Մոսկվայում, կրթություն ստացած և մեծ հեղինակություն 
                            վայելող արվեստագետներ Մարտիրոս Սարյանը, Վրթանես Ախիկյանը, 
                            Ստեփան Աղաջանյանը, Սեդրակ Առաքելյանը, Վահրամ Գայֆեճյանը, 
                            Գաբրիել Գյուրջյանը, Հակոբ Կոջոյանը, Արա Սարգսյանը և այլք։ 
                            1927 թվականին ուսումնարանը տվել է իր առաջին շրջանավարտները, 
                            որոնց թվում էին անվանի հայ գեղանկարիչներ Մհեր Աբեղյանը, Սեդրակ 
                            Ռաշմաճյանը, Բաբկեն Քոլոզյանը, Արմեն Չիլինգարյանը և այլք։ Մյուս 
                            տարիների շրջանավարտներից են Հովհաննես Զարդարյանը, Գրիգոր Խանջյանը, 
                            Ղուկաս Չուբարյանը, Մարիամ Ասլամազյանը, Արա Բեքարյանը, Էդուարդ Իսաբեկյանը, 
                            Կարապետ Տիրատուրյանը և այլք։</p>
                    <p>
                    &nbsp;&nbsp;&nbsp;Դասավանդման առանձնահատկություններից է այն, 
                    որ այստեղ դասավանդում են ակտիվ ստեղծագործական կյանքով ապրող նկարիչները։
                    </p>
                </div>
            </div>
        </article>
        <Contact/>
        </div>
 
        )
    }
    </>    
    )
}

export default connect(r=>r)(History)