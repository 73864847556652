import React,{useEffect,useState} from 'react'
import {connect} from 'react-redux'
import bg1 from '../../images/overviewpage.jpg'
import img1 from '../../images/descrip1.jpg'
import img2 from '../../images/descrip2.jpg'
import img3 from '../../images/descrip3.png'
import img4 from '../../images/descrip4.jpg'
import img5 from '../../images/descrip5.jpg'
import img6 from '../../images/descrip6.jpg'
import img7 from '../../images/descrip7.jpg'
import './over.css'

import Aos from 'aos';
import 'aos/dist/aos.css';
import Preloader from '../../parts/preloader'

function Overview(){
    document.title='Overview - Terlemezyan'
    const[load,setLoad]=useState(true)
  
    useEffect(()=>{
      window.scrollTo(0, 0);
      setLoad(false)
    },[])

  
    const styles={
        head:{
            backgroundColor: `rgba(188,174,173,0.13)`,
            backgroundImage:`url(${bg1})`,
            backgroundSize: 'cover',
            height:'366px'
        }
    }
    return(
        <>
            {
                load==true?(
                    <Preloader/>
                ):(
                    <>
             <div className='overview-page' style={styles.head}></div>
             <article>
                 <h1 style={{fontSize:'36px',textAlign:'center'}}>
                    Պատմական ակնարկ
                 </h1>
                 <p>
                 Գեղարվեստական կրթությունը Հայաստանում ունի 100-ամյակների պատմություն: 
                 Դվինը, որ Հայոց մայրաքաղաք դարձավ 4-րդ դարում, հայտնի էր իր դրամահատարանով, 
                 խեցեգործությամբ, ապակե արտադրությամբ, գորգագործությամբ: 9-14 րդ դարերում 
                 Հայաստանում առաջացան 30 մեծ ու փոքր քաղաքներ և 20-ից ավելի գյուղաքաղաքներ, 
                 որտեղ արագ թափով զարգացան արհեստները: Արտադրության ձևերը կատարելագործվեցին: 
                 Դեռևս 10-րդ դարում ստեղծվեցին առաջին համքարությունները: Դրանք կազմակերպություններ էին, 
                 որ իրենց մեջ միավորում էին նույն արհեստով զբաղվող մարդկանց:
                 </p>
                  
                 <p>
                 Հայաստանի առաջին և երկրորդ հանրապետություններում այս 
                 դաշտի ձևավորման հարցում լրջագույն դեր է խաղացել հենց 
                 միջին մասնագիտական կրթությունը: Այն իրականացվում էր այսօրվա 
                 բառապաշարով ասված որպես պիլոտային-փորձարարական ծրագիր, ինչից 
                 հետո նոր բացվում էին բարձրագույն կրթական հաստատությունները:
                 </p>
                 
                 <p>
                 Հայաստանում թերևս քիչ կգտնվեն նկարիչներ, քանդակագործներ, դիզայներներ, 
                 որոնք անցած չլինեն կյանքի այն դպրոցը, որն այժմ կոչվում է Երևանի Փանոս 
                 Թերլեմեզյանի անվան գեղարվեստի քոլեջ։ Ուսումնարանն իր պատմությունը սկսել է 
                 դեռևս 1921 թվականին։ Այս տարիներին նոր ձևավորված հայկական երկրորդ հանրապետության 
                 ղեկավար Ալեքսանդր Մյասնիկյանի ջանքերով հայրենիքում հավաքվեցին աշխարհով մեկ սփռված 
                 հայ արվեստագետներ, որոնք կրթություն էին ստացել Եվրոպայի ժամանակի լավագույն ուսումնական 
                 հաստատություններում։ Նա մեծ նշանակություն էր տալիս իր հայրենիքի ապագայի համար 
                 կրթության դերին, և առաջին դեկրետներից մեկը, որն ընդունվեց Մյասնիկյանի ստորագրությամբ, 
                 Նկարչական դպրոցի բացման մասին էր։ Կրթօջախը տասնամյակների ընթացքում մի քանի անգամ վերանվանվել է, 
                 սակայն պահպանվել են դեռևս հիմնադիրների կողմից ստեղծված ավանդույթները։ 1926 թվականին այն անվանվել է 
                 «Գեղարդ» գեղարվեստա-արդյունաբերական տեխնիկում, 1938 թվականից՝ Գեղարվեստական ուսումնարան, 1941 թվականին` 
                 կոչվել է հայ մեծ նկարիչ Փանոս Թերլեմեզյանի անունով։
                 </p>
             </article>
             <section>
                 <div className='descripsBlock'>
                 <div className='firstDescrip'>
                    <div className='descripDetales' data-aos='fade-up'>
                        <div className='descripPhoto'>
                            <img src={img1}/>
                        </div>
                        <div className='descripVideo'>
                        <iframe className="elementor-video-iframe" allowFullScreen="" title="youtube Video Player" src="https://www.youtube.com/embed/leuMU7orpak/watch?feature=oembed&amp;start&amp;end&amp;wmode=opaque&amp;loop=0&amp;controls=1&amp;mute=0&amp;rel=0&amp;modestbranding=0" id="fitvid48329"></iframe>
                        </div>
                    </div>
                    
                     <div className='description1'>
                         <p>
                         Հիմնադրման տարիներին ուսումնարանի 
                         համար ընտրվել էր ժամանակի ամենագեղեցիկ շենքերից մեկը։ 
                         Մոտ ութ տասնամյակ ուսումնարանը գտնվել է այժմյան Լեոյի փողոցում։ 
                         20-րդ դարի 20-ական թվականներին այդ շենքն առանձնանում էր շրջապատի 
                         կավաշեն տներից իր վեհությամբ, սրբատաշ սև տուֆե ճակատամասով։ 
                         Այն կառուցվել էր դեռևս 1900 թվականին, լրակառուցվել՝ 1921-1922թթ.։ 
                         Ցավոք, մեր օրերում շենքը փլուզվող և անվերականգնելի դարձավ:
                         </p>
                     </div>
                 </div>
                 <div className='firstDescrip'>
                     <div className='descripDetales' data-aos='fade-up'>
                     <div className='descripPhoto'>
                         <img src={img2}/>
                     </div>
                     <div className='descripVideo'>
                     <iframe className="elementor-video-iframe" allowFullScreen="" title="youtube Video Player" src="https://www.youtube.com/embed/k5B9dni-cc0?feature=oembed&amp;start&amp;end&amp;wmode=opaque&amp;loop=0&amp;controls=1&amp;mute=0&amp;rel=0&amp;modestbranding=0" id="fitvid157464"></iframe>
                     </div>
                     </div>
                     <div className='description2'>
                         <p>
                         Ուսումնարանի տնօրեն Հովհաննես Տեր-Թադևոսյանը (1889-1974) 
                         հրավիրել էր ժամանակի լավագույն մասնագետներին: 
                         Ուսումնարանի առաջին դասախոսներից էին Մարտիրոս Սարյանը, 
                         Վրթանես Ախիկյանը, Ստեփան Աղաջանյանը, Սեդրակ Առաքելյանը, 
                         Վահրամ Գայֆեճյանը, Գաբրիել Գյուրջյանը, Հակոբ Կոջոյանը, 
                         Արա Սարգսյանը և այլ մեծանուն վարպետներ։ Հիմնադրման օրվանից 
                         ուսումնարանն ունեցել է նկարչության և քանդակագործության բաժիններ, 
                         սեփական բրուտանոց։ 1925 թվականին ավելացել են նաև ոսկերչության, 
                         փայտագործության, ասեղնագործության (ավելի ուշ՝ գորգագործության) բաժինները։ 
                         </p>
                     </div>
                 </div>
                 </div>
                 
             </section>
             <article className='lastArt'>
                 <div className='descrips' >
                 <div className='rightdescrip' data-aos='fade-up'>
                     <img src={img3}/>
                 </div>
                 <div className='leftdescrip'>
                     <p>
                     Ուսանողների առաջին ցուցահանդեսը բացվեց հիմնադրումից հինգ ամիս անց։ 
                     1927 թվականին   ուսումնարանը տվել է իր առաջին շրջանավարտները, որոնց 
                     թվում էին անվանի հայ գեղանկարիչներ Մհեր Աբեղյանը, Սեդրակ Ռաշմաջյանը, 
                     Բաբկեն Քոլոզյանը, Արմեն Չիլինգարյանը և այլոք։  Շրջանավարտներից շատերն 
                     իրենց ուսումը շարունակեցին Խորհրդային միության լավագույն բարձրագույն 
                     ուսումնական հաստատություններում, իսկ երբ 1945թ. Երևանում բացվեց Գեղարվեստական 
                     ինստիտուտը, որը 1952 թվականին միացվեց  թատերական ինստիտուտին և ստացավ 
                     Գեղարվեստա-թատերական անունը, նրա ուսանողների մեծ մասն ուսումնարանի շրջանավարտներն էին։
                      
                     </p>
                      <p>
                          Թերլեմեզյանի ուսումնարանը ոչ միայն հաստատություն էր, այլև հատուկ մթնոլորտ, որտեղ 
                     ձևավորվել և ձևավորվում են բազմակողմանի զարգացած անհատականություններ։ 
                     Նշենք, որ նրա պատերից դուրս են եկել բազմաթիվ երաժիշտներ, դերասաններ։
                      </p>
                     
                 </div>
                 </div>
                <p>
                     
                     Թերլեմեզյանական շունչն էր ոգեշնչում ուսանողների նաև հայրենիքի համար ծանր տարիներին՝ 
                     թե Հայրենական մեծ պատերազմի, թե Արցախյան ազատամարտի ժամանակ, երբ նրանցից շատերը զենք վերցրին՝ 
                     պաշտպանելու համար նաև այն գաղափարները, որոնցով ապրում էր ուսումնարանը։
                </p>
                <p>
                 1960-ական թվականների «ձնհալի» և սերնդափոխության տարիներն այստեղ անցան առանց բարդությունների։ 
                 Եվ հենց ուսումնարանի շրջանավարտները ստեղծեցին հայ նորագույն արվեստի զարգացման հիմնական միտումները, 
                 որոնցով շարունակում է զարգանալ արդի կերպարվեստը։ Նրանց թվում էին Մինաս Ավետիսյանը, Էդուարդ Իսաբեկյանը, 
                 Ռուդոլֆ Խաչատրյանը, Մարտին Պետրոսյանը, Ալեքսանդր Գրիգորյանը, Անատոլի Գրիգորյանը, Սամվել Պետրոսյանը և այլոք։
                 </p><br/>
                 <p>
                 Ի տարբերություն բազմաթիվ կրթօջախների, Փ. Թերլեմեզյանի անվան գեղարվեստի ուսումնարանը կարողացավ 
                 հաղթահարել նաև 1990-ականների ճգնաժամը՝ պահպանելով ուսուցման բարձր որակն ու տասնամյակների ավանդույթները։
                 </p><br/>
                 <p>
                 Նոր շունչ մտավ այս կրթօջախ 2007թ.-ին, երբ քոլեջը տեղափոխվեց նոր ուսումնական շենք, 
                 որտեղ տնօրենի՝ գեղանկարիչ Նարեկ Առուշանյանի մեծ ջանքերի արդյունքում ստեղծվեցին բոլոր 
                 անհրաժեշտ պայմանները ստեղծագործական բարենպաստ աշխատանքի համար: 
                 </p>

                 <div className='secondDescrip'>
                    <div className='secondPhotos'>
                        <div className='sphoto' data-aos='fade-up'>
                            <img src={img4}/>
                        </div>
                        <div className='sphoto' data-aos='fade-up'>
                            <img src={img5}/>
                        </div>
                        <div className='sphoto' data-aos='fade-up'>
                            <img src={img6}/>
                        </div>
                    </div>
                    <p>
                    Այսօր քոլեջում գործում են գեղանկարչության, քանդակագործության,  
                    դիզայնի և զարդային կիրառական արվեստի ու ժողովրդական արհեստների բաժիններ, 
                    որտեղ սովորում է շուրջ  750 ուսանող։ Ընդհանուր մասնագիտական կրթության մեջ 
                    կարևոր տեղ է հատկացվում տեսական բաժնին, որտեղ արվեստի պատմության լսարանին 
                    կից գործում է ժամանակակից տեխնիկայով հագեցած կինոսրահ, որը հնարավորություն 
                    է տալիս ուսանողներին յուրացնել դասավանդվող նյութը, ծանոթանալ համաշխարհային 
                    արվեստի պատմության էջերին վավերագրական, պատմական, գեղարվեստական ու արվեստաբանական 
                    տեսանյութերի օգնությամբ, ինչպես նաև վիրտուալ այցելություններ կատարել աշխարհի տարբեր թանգարաններ։
                    </p><br/>
                    <p>
                    Բացի դասական հմտություններից, ուսանողները ստանում են նաև ինքնուրույն մտածելու 
                    և ստեղծագործելու հնարավորություն, ինչի շնորհիվ քոլեջի շրջանավարտները, 
                    հիմնականում, արդեն կայանում են որպես ինքնատիպ արվեստագետներ։ Նրանցից շատերը 
                    հետագայում վերադառնում են հարազատ կրթօջախ` արդեն որպես դասախոս, պահպանելով քոլեջի ավանդույթները։
                    </p>
                 </div>
                 <div className='descrips'>
                 <div className='rightdescrip' data-aos='fade-up'>
                     <img src={img7} className='leftImg'/>
                 </div>
                 <div className='leftdescrip'>
                     <p>
                     2007 թվականին քոլեջի տարածքում բացվեց  «Թերլեմեզյան» ցուցասրահը, 
                     որը երիտասարդների համար իրենց աշխատանքները ցուցադրելու և իրենց մասին 
                     հայտարարելու առաջին հնարավորությունն է։ Ցուցասրահը կազմակերպում է 
                     համահայկական մշակութային փառատոններ, ուսանողական և դասախոսական ամենամյա 
                     ցուցահանդեսներ` «Թերլեմեզյանցիներ» խորագրի ներքո։
                     </p><br/>
                     <p>
                     Թերլեմեզյանի քոլեջը կրթամշակութային գործառույթների տեսանկյունից Հայաստանի ամենշանակալի 
                     սոցիալական ինստիտուտներից է: Քոլեջի կրթական ծրագրերը ընդօրինակվել են ռուսական «շկոլայից», 
                     սակայն ճշգրիտ տեղայնացվել են, այդ պատճառով ծրագրերը կենսունակ են առ այսօր: 
                     </p>
                     <p>Խորհրդային տարիներին, տնտեսության զարգացմանը համահունչ, ավելացել կամ պակասել են որոշ բաղադրիչներ, սակայն առանցքայինները 
                     մնացել են և գործում են առ այսօր:</p>
                 </div>
                 </div>
                 <p>
                      Մեր երկիրն անշուշտ մոնոէթնիկ է, սակայն հաշվի առնելով, որ բոլոր 
                     ժամանակներում հայերն արտագաղթել են տարբեր երկրներ, ինչպես նաև Սփյուռքի առկայությունը, բերում են 
                     նրան, որ այս կամ այն ժամանակաշրջանում ներգաղթելով, մեր հայրենակիցներն իրենց հետ բերում են այն 
                     երկրների նյութական և հոգևոր մշակութային տարրեր, որտեղ բնակվել են որոշ ժամանակաշրջան: 
                     Տեղի է ունենում մշակութային փոխազդեցություն, մշակութային երկխոսություն, և արդյունքում 
                     ծնվում է նոր ժամանակների մշակույթ: Այս գործընթացը նպաստում է նոր մշակութային ավանդույթների ձևավորմանը: 
                     Այսօր քոլեջում սովորում է 27 սփյուռքահայ ուսանող հետևյալ երկրներից. Վրաստան, ՌԴ, Ղազախստան, ԱՄՆ, Սիրիա, 
                     Իրան, Իտալիա, Ֆրանսիա, Դանիա և այլն:
                     </p>
                 <p>
                 Թերլեմեզյանի քոլեջն իրենից ներկայացնում է յուրատիպ հաստատություն, որը կրում է 
                 հսկայական հոգևոր-մշակութային ներուժ: Հիմնադրման իսկ պահից, բացի կրթական և սոցիալական 
                 գործառույթները, էլիտայից զրկված, ազգի սերուցքը կորցրած ազգի համար, հաստատությունն ունեցել 
                 է նոր մշակութային էլիտայի ձևավորման առաքելություն: Բացի հոգևոր-մշակութային առաքելությունը, 
                 քոլեջը նաև տնտեսական օղակ է, որը հանդիսանում է կրթական, ազգագրական և մշակութային տուրիզմի 
                 խթանման աղբյուր:
                 </p>
             </article>
        </>
 
                )
            }
        </>
    )
}

export default connect(r=>r)(Overview)