import React from 'react'
import { connect } from 'react-redux'
import './footer.css'
import footerLogo from '../images/footerLogo.png'
import { Link } from 'react-router-dom'

function Footer(){
    return(
        <footer>
            <div className='footer-contents'>
                <div className='footer-logo'>
                    <img src={footerLogo} />
                </div>
                <div className='footer-contact'>
                    <div>
                        <p><i className='fa fa-building'></i>ՀՀ, Երևան Արշակունյաց պող, 39 շենք</p>
                        <hr/>
                        <p><i className='fa fa-phone-square'></i>+(374) 10 442542</p>
                        <hr/>
                        <p><i className='fa fa-envelope'></i>terlemezyanartcollege@gmail.com</p>
                    </div>
                </div>
                <div className='footer-slogan'>
                    <div className='slogan'>
                        <p>Արվեստը կյանքի առջևից է քայլում և լուսավորում ապագան:</p>
                        <p>Կոստան Զարյան</p>
                    </div>
                    <div className='footer-icons'>
                        <a href='https://www.facebook.com/terlemezyan.college/'><i className='fa fa-facebook'></i></a>
                        <a href="https://www.instagram.com/terlemezyan_gallery/"><i className='fa fa-instagram'></i></a>
                    </div>                     
                </div>
            </div>
            <hr/>
            <div className='copyright'>
                    <p>Design by <a target="_blank" href='https://www.facebook.com/vahan.muradyan.927' style={{color:'#AAAAAA'}}>AlinaBoyajyan</a>, Developed by <a target="_blank" href='https://www.facebook.com/boyajyan.alina' style={{color:'#AAAAAA'}}>Vahan Muradyan</a>  2019</p>
            </div>
        </footer>
    )
}

export default connect(r=>r)(Footer)