import galleryState from '../states/galleryState'


export default function homePageReducer(state=galleryState,action){
    const temp = {...state}

     
    if(action.type==='updateCards'){
        temp.generations=action.data
        return temp
    }

    
     if(action.type==='updateEvent'){
         temp.events=action.data.info
         const images=[]
         
         action.data.imgs.map(item=>{
            let int 
            if(item.id%2==0){
                int=325
            }else{
                int=250
            }
            images.push({src:`http://localhost:5000/${item.photo}.jpg`,event_id:item.id,width:281, height:int})
         })
       
          temp.imgs=images
         return temp
     }
     
     if(action.type==='openSlider'){
          temp.slidePhoto=action.target
          temp.open=true
         console.log(temp.slidePhoto);

          return temp
     }

     if(action.type==='closeSlide'){
        temp.slidePhoto=''
        temp.open=false
        return temp
     }

    if(action.type==='loadSpinner'){
            temp.loadSpinner=action.data
            return temp 
    }

     
    return temp
}