import React, { useState,useEffect } from 'react'
import { connect } from 'react-redux'
import img1 from '../../images/mainzard.jpg'
import img2 from '../../images/decor1.jpg'
import img3 from '../../images/decor2.jpg'
import img4 from '../../images/decor3.jpg'
import img5 from '../../images/decor4.jpg'
import img6 from '../../images/decor5.jpg'
import Contact from '../../parts/contactForm'
import './decor.css'
import Preloader from '../../parts/preloader'
function Decorative(){
    const[load,setLoad]=useState(true)
  
    useEffect(()=>{
      window.scrollTo(0, 0);
      setLoad(false)
    },[])

   
    document.title='Decoration - Terlemezyan'
    return (
        <>
            {
                load==true?(
                    <Preloader/>
                ):(
                    <div className='painting-page'>
        <div style={{height:'390px',background: 'rgba(0,0,0,0.4)'}}>
            <img style={{height: '100%',width: '100%',objectFit: 'cover'}} src={img1} />
        </div>
        <article className='decorSec'>
            <h1 style={{fontSize:'36px',textAlign:'center'}}> Զարդային կիրառական արվեստ և ժողովրդական արհեստներ</h1>
            <p>Երևանի Փ. Թերլեմեզյանի անվան գեղարվեստի պետական քոլեջը 
                պետական կրթական չափորոշիչների, հաստատված ուսումնական 
                պլանների և առարկայական ծրագրերի միջոցով պատրաստում է 
                բարձր որակի մասնագետներ զարդայի կիրառական արվեստի և 
                ժողովրդական արհեստների բնագավառի համար: 
            </p><br/>
            <p>Ուսուցման ձևը` առկա, անվճար և վճարովի համակարգերով:</p>
            <ul>
                <li>Ուսուցման տևողությունը` չորս տարի: </li><br/>
                <li>Ուսման վարձը` 350000 դրամ:</li><br/>
                <li>Որակավորումը` նկարիչ-վարպետ:</li>
            </ul>
        </article>
        <section className='images'>
             <div className='paintCards'><img src={img2}/></div>
             <div className='paintCards'><img src={img3}/></div>
             <div className='paintCards'><img src={img4}/></div>
             <div className='paintCards'><img src={img5}/></div>
        </section>
        <article className='decorSec'>
            <h1>«Զարդային կիրառական արվեստ և ժողովրդական արհեստներ»</h1>
            <h1>առարկայական հանձնաժողովի նախագահ</h1>
            <div className='main-card'>
                <div className='cardImg' style={{height:'420px'}}>
                    <img src={img6}/>
                </div>
                <div className='cardDescr'>
                    <h1>Անի Քանանյան</h1>
                    <ul>
                        <li>1990թ.-ին ծնվել է Վրաստանում։</li> 
                        <li>2005-2010թթ. Սովորել է Փ. Թերլեմեզյանի անվ. գեղարվեստի պետ. քոլեջի գեղանկարչության բաժնում։</li> 
                        <li>2010-2016թթ. Սովորել է Երևանի Գեղարվեստի պետ. ակադեմիայի կիրառական արվեստի բաժնում։</li> 
                        <li>2013-ից աշխատում է «Որմնանկարների Վերականգնամ Գիտահետազոտական կենտրոնում»  որպես նկարիչ վերականգնող։</li> 
                        <li>2012թ.-ին անհատական ցուցահանդես «Նարեկացի» ցուցասրահում։</li> 
                        <li>2013թ.-ին անհատական ցուցահանդես «Ալբերտ և Թովէ Բոյաջյան» ցուցասրահում։</li>
                        <li>2016թ.-ին անհատական ցուցահանդես «Գևորգ Գրիգորյան» (ՋՈՏՏՈ) տուն-թանգարանում։</li>
                        <li>2018թ.-ին Ստամբուլի բիենալե։</li>
                        <li>2019թ.-ից «Շուշան» ցուցասրահի համահիմնադիր։</li>
                        <li>2020-ից դասավանդում է Փանոս Թերլեմեզյանի անվան պետ. քոլեջում։</li>
                        <li>Ինչպես նաև մասնակցել է մի շարք միջազգային  ցուցահանդեսների Հայաստան, Վրաստան, Ռուսաստան, Լիտվա, Իսրայել։</li>
                    </ul>
                </div>
            </div>
        </article>
        <Contact/>
      </div>
 
                )
            }
        </>
        
    )
}

export default connect(r=>r)(Decorative)