export default{
    nav:[
        {name:'Գլխավոր', href:'/'},
        {name:'Պատմություն', href:'/history'},
        {name:'Ընդունելություն', href:'/admission'},
        {name:'Ծրագրեր', href:'/programm'},
        {name:'Պատմական ակնարկ', href:'/overview'},
        {name:'Առաքելություն', href:'/mission'},
        {name:'Կառուցվածք', href:'/structure'},
        {name:'Փաստաթղթեր', href:'/documents'},
        {name:'Գրադարան', href:'/library'},
        {name:'Հետադարձ կապ', href:'/contact'},
        {name:'Գեղանկարչություն', href:'/painting'},
        {name:'Զարդակիրառական արվեստ', href:'/decor'},
        {name:'Քանդակ', href:'/sculpture'},
        {name:'Դիզայն', href:'/design'},
        {name:'Ուս. խորհուրդ', href:'/advice'},
        {name:'Կարիերայի և որակի կենտրոն', href:'/career'},
        {name:'Շրջանավարտներ', href:'/graduates'},
        {name:'Հղումներ', href:'/links'},
    ],
    burgerState:false
}
   