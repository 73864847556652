import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route} from 'react-router-dom'
import { connect } from 'react-redux';
import Header from './parts/header'
import Home from './components/homeContent';
import Footer from './parts/footer'
import History from './components/historyPage/HistoryComponent';
import Admission from './components/admissionPage/Admission';
import Programm from './components/progs/ProgrammComponent';
import Overview from './components/OverviewPage/OverviewComponent';
import Mission from './components/MissionPage/MissionComponent';
import Structure from './components/StructurePage/StructureComponent';
import DocumentComponent from './components/DocumentPage/DocumentComponent';
import LibraryComponent from './components/LibraryPage/LibraryComponent';
import contactComponent from './components/contactPage/contactComponent';
import PaintingComponent from './components/PaintingPage/PaintingComponent';
import GraduatesComponent from './components/GraduatesPages/GraduatesComponent';
import CareerComponent from './components/careerPage/careerComponent';
import SculptureComponent from './components/sculpturePage/SculptureComponent';
import DecorativeComponent from './components/decorativePage/DecorativeComponent';
import DesignComponent from './components/designPage/DesignComponent';
import StudentAdviceComponent from './components/studentadvicePage/studentAdviceComponent';
import LinksComponent from './components/linksPage/LinksComponent';
import ScrollToTop from 'react-scroll-up';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import './App.css'
import Aos from 'aos';
import 'aos/dist/aos.css';
import galleryComponent from './components/galleryPage/galleryComponent';
import loader from './images/loading-cube.gif'
import Preloader from './parts/preloader';
import Eventpage from './components/EventsComponent/Eventpage';

function App() {
  const routes = [
    {path:'/',component:Home},
    {path:'/history',component:History},
    {path:'/admission',component:Admission},
    {path:'/programm',component:Programm},
    {path:'/overview',component:Overview},
    {path:'/mission',component:Mission},
    {path:'/structure',component:Structure},
    {path:'/documents',component:DocumentComponent},
    {path:'/library',component:LibraryComponent},
    {path:'/contact',component:contactComponent},
    {path:'/painting',component:PaintingComponent},
    {path:'/graduates',component:GraduatesComponent},
    {path:'/career',component:CareerComponent},
    {path:'/sculpture',component:SculptureComponent},
    {path:'/decor',component:DecorativeComponent},
    {path:'/design',component:DesignComponent},
    {path:'/advice',component:StudentAdviceComponent},
    {path:'/links',component:LinksComponent},
    {path:'/gallery',component:galleryComponent},
    {path:'/gallery/events/:id',component:Eventpage}
  ]
  
    useEffect(()=>{
    Aos.init({duration:1000})
      
  },[])
   
    return (
    <Router>
        {
          <>
          <Header/>
            <div className='App'>
              {
                routes.map((item,index)=>{
                  return <Route key={index} path={item.path} exact component={item.component}/>
                })
              }
            </div>
          <Footer/>   
          </>  
        }
      
      <ScrollToTop showUnder={160} style={{borderRadius: '200px',textAlign: 'center',padding: '10px',background:'#333333'}}>
        <ArrowUpwardIcon style={{color:'white'}}/>
      </ScrollToTop>
    </Router>
  );
}

export default connect(r=>r)(App);
