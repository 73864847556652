import React, { useState,useEffect } from 'react'
import { connect } from 'react-redux'
import img1 from '../../images/sculptureP.jpg'
import img2 from '../../images/sculpture1.jpg'
import img3 from '../../images/sculpture2.jpg'
import img4 from '../../images/sculpture3.jpg'
import img5 from '../../images/sculpture4.jpg'
import img6 from '../../images/sculpture5.jpg'
import Contact from '../../parts/contactForm'
import './sculpture.css'
import Preloader from '../../parts/preloader'
function Sculpture(){
    const[load,setLoad]=useState(true)
  
    useEffect(()=>{
      window.scrollTo(0, 0);
      setLoad(false)
    },[])

  
    document.title='Sculpture - Terlemezyan'
    return(
        <>
            {
                load==true?(
                    <Preloader/>
                ):(
                    <div className='painting-page'>
        <div style={{height:'390px',background: 'rgba(0,0,0,0.4)'}}>
            <img style={{height: '100%',width: '100%',objectFit: 'cover',objectPosition: '42% 37%'}} src={img1} />
        </div>
        <article>
            <h1 style={{fontSize:'40px',textAlign:'center'}} >Քանդակագործություն</h1>
            <p>Քանդակագործության բաժինը Փ. Թերլեմեզյանի անվան գեղարվեստի 
                պետական քոլեջի հնագույն և հիմնական բաժիններից մեկն է, որտեղ 
                ժամանակին դասավանդել են վարպետներ Վաղարշ Մելիք-Հակոբյանը, Կարո 
                Հալաբյանը, Արա Սարգսյանը, Սուրեն Ստեփանյանը, Ստեփան Թարյանը և այլ 
                մեծանուն քանդակագործներ: Տարիներ շարունակ այստեղ դասավանդվում է 
                ակադեմիական դպրոցի քանդակագործության ավանդույթներն ու խնդիրները, 
                ինչպես նաև կիրառվում են մեր երախտավոր քանդակագործ-դասախոսների 
                կողմից մշակված և հաստատված ուսումնական ծրագրերը: Այդ ծրագրերումՙ 
                հիմնական մասնագիտական առարկաների, անատոմիայի և տեխնոլոգիայի 
                դասաժամերին զուգահեռ, հատուկ տեղ է հատկացվում բնորդի անատոմիական 
                ուսումնասիրմանը, ամառային աշխատանքներին (հիմնականում ամառային 
                աշխատանքները կատարվում են ուսումնական շենքի բակում հատուկ հատկացված 
                տարածքում), որոնք որպես ավանդույթ ցուցադրվում են տարեվերջյան հաշվետու ցուցահանդեսներին:
            </p><br/>
            <p>
            Երևանի Փ. Թերլեմեզյանի անվան գեղարվեստի պետական քոլեջը պետական կրթական 
            չափորոշիչների, հաստատված ուսումնական պլանների և առարկայական ծրագրերի 
            միջոցով պատրաստում է բարձր որակի մասնագետներ քանդակագործության ստեղծագործական 
            աշխատանքների, ինչպես նաև քանդակագործության, գծանկարչության գծով մանկավարժական 
            գործունեության համար:
            </p>
            <ul>
                <li>Ուսուցման ձևը` առկա, անվճար և վճարովի համակարգերով:</li><br/>
                <li>Ուսուցման տևողությունը` հինգ տարի:</li><br/>
                <li>Ուսման վարձը` 350000 դրամ:</li><br/>
                <li>Որակավորումը` քանդակագործ-դասավանդող:</li>
            </ul>
        </article>
        <section className='images' data-aos='fade-up'>
             <div className='paintCards'><img src={img2}/></div>
             <div className='paintCards'><img src={img3}/></div>
             <div className='paintCards'><img src={img4}/></div>
             <div className='paintCards'><img src={img5}/></div>
        </section>
        <article>
            <h1>Քանդակագործություն առարկայական հանձնաժողովի նախագահ</h1>
            
            <div className='main-card'>
                <div className='cardImg' data-aos='fade-up' style={{height:'515px'}}>
                    <img src={img6}/>
                </div>
                <div className='cardDescr'>
                    <h1>Իսակ Ապրեյան</h1>
                    <ul>
                        <li>1977թ.-ին ծնվել է ք. Գյումրիում, ՀՀ</li><br/>
                        <li>1998թ.-ին   ավարտել է Երևանի Փ. Թերլեմեզյանի անվան գեղարվեստի պետական քոլեջ,  «Քանդակագործություն»  մասնագիտություն:</li><br/>
                        <li>1996-1998թթ. Ժամկետային զինծառայություն,  ք. Երևանի համար 5165 Հ.Մ.Ս.</li><br/>
                        <li>2004թ.-ին ավարտել է Երևանի գեղարվեստի պետական ակադեմիա, Կերպարվեստի ֆակուլտետ, «Քանդակագործություն»  բաժին:</li><br/>
                        <li>2011թ-ից`  դասավանդում է Երևանի Փ. Թերլեմեզյանի անվան գեղարվեստի պետական քոլեջում:</li><br/>
                        <li>2013թ-ից` ՀՀ Նկարիչների միության անդամ:</li>
                    </ul>
                </div>
            </div>
        </article>
        <Contact/>
      </div>

                )
            }
        </>
        )
}

export default connect(r=>r)(Sculpture)