import { Typography } from '@material-ui/core'
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react'
import { connect } from 'react-redux'
import bg4 from '../images/1.jpg'
import bg5 from '../images/Untitled-3-2.jpg'
import { inpchange, validate } from '../redux/actions'
import './contact.css'
function Contact(props){
   let contact = props.contact.contactInputs
  let error = props.contact.errors
     return(
        <section className='contact'>
        <div className='map'>
        <div className='row1'>
             <div className='card2'  data-aos='fade-up'>
             <iframe frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" 
              src="https://maps.google.com/maps?q=%D5%A5%D6%80%D6%87%D5%A1%D5%B6%20%D5%A1%D6%80%D5%B7%D5%A1%D5%AF%D5%B8%D6%82%D5%B6%D5%B5%D5%A1%D6%81%20%D5%BA%D5%B8%D5%B2%D5%B8%D5%BF%D5%A1%2039&amp;t=m&amp;z=12&amp;output=embed&amp;iwloc=near" 
              aria-label="երևան արշակունյաց պողոտա 39"></iframe>
            </div>
           
             <div className='fcard'  data-aos='fade-up'>
              <img src={bg4}/>
                <div className="box-with-icon">
                <div className='abouteIcon'>
                <i className="fa fa-phone"></i>
                </div>
                <div className='abouteText'>
                <h3 className="icon-title">Հեռախոս՝</h3>
                <p className="box-sub-title icon-subtitle">+(374) 10 44 25 42</p>
                </div>
               </div>
              </div>
           
          
              <div className='fcard' data-aos='fade-up'>
            <img src={bg5}/>
            <div className="box-with-icon">
                <div className='abouteIcon'>
                <i className="fa fa-inbox"></i>
                </div>
                <div className='abouteText' style={{marginLeft:0}}>
                <h3 className="icon-title">Էլ. փոստ`</h3>
                <p className="box-sub-title icon-subtitle">terlemezyanartcollege@gmail.com</p>
                </div>
              </div>
            </div>
            </div>
          
          
        </div>

          
          
        
        <div className='contactForms'>
        <div className='heading1'>
            <div className='col1'>
              <div className='divider'></div>
            </div>
            <div className='col2'>
                <h2>Հետադարձ կապ</h2>
            </div>
          </div>
          <div className='form' data-aos='fade-up'>
            <h3>Հարցերի և առաջարկությունների համար դիմել.</h3>
           <form method='Post'>
             <div>
               <input placeholder='Անուն' value={contact.name} onChange={(e)=>{props.dispatch(inpchange('name',e.target.value))}}/>
              <h6>{error.error1}</h6>
             </div>
             <div><input placeholder='Ազգանուն' value={contact.surname} onChange={(e)=>{props.dispatch(inpchange('surname',e.target.value))}}/></div>
             <div>
               <input placeholder='Էլ․ հասցե' value={contact.email} onChange={(e)=>{props.dispatch(inpchange('email',e.target.value))}}/>
             <h6>{error.error2}</h6>
             </div>
             <div><textarea placeholder='Հաղորդագրություն' value={contact.message} onChange={(e)=>{props.dispatch(inpchange('message',e.target.value))}}></textarea></div>
                <button onClick={()=>{props.dispatch(validate())}}>Ուղարկել</button>
           </form>
          </div>
        </div>
      </section>
    )
}

export default connect(r=>r)(Contact)