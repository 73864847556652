import React, { useState,useEffect } from 'react'
import { connect } from 'react-redux'
import img1 from '../../images/usxorhurd.jpg'
import './adv.css'
import img2 from '../../images/Безымянный-1.png'
import Preloader from '../../parts/preloader'

function Advice(){
    document.title='St. advice - Terlemezyan'
    const[load,setLoad]=useState(true)
  
    useEffect(()=>{
      window.scrollTo(0, 0);
      setLoad(false)
    },[])

   
    return (
        <>
            {
                load==true?(
                    <Preloader/>
                ):(
                    <div className='painting-page'>
        <div style={{height:'390px',background: 'rgba(0,0,0,0.4)'}}>
            <img style={{height: '100%',width: '100%',objectFit: 'cover',objectPosition:'50% 35%'}} src={img1} />
        </div>
        <section style={{paddingBottom:'40px'}}>
            <h1 style={{fontSize:'36px',textAlign:'center'}}>ՈՒՍԱՆՈՂԱԿԱՆ ԽՈՐՀՈՒՐԴ</h1>
            <p> Երևանի Փ. Թերլեմեզյանի անվան գեղարվեստի պետական քոլեջի ուսանողական խորհուրդը ստեղծվել է  2007 թվականին: Կառույցի նպատակն ու գերխնդիրն է ներկայացնել ու պաշտպանել ուսանողների շահերը:</p>
            <p>Ուսանողական խորհուրդը ներքոլեջային կյանքում ունի ակտիվ դերակատարում: Ստեղծման առաջին իսկ օրից ՈւԽ հիմնական առաքելությունը եղել և մնում է ուսանողների շահերի և իրավունքների պաշտպանությունը:</p>
            <p>Ուսանողական խորհուրդը ուսանողության խնդիրներով զբաղվող ինքնակառավարվող մարմին է, որի գործունեությունը սահմանվում է ՀՀ օրենքների, քոլեջի կանոնադրության և ՈՒԽ կանոնադրության հիման վրա:</p>
            <p>Ուսանողական խորհրդի գործունեության մեջ առանձնակի կարևոր մաս է կազմում  միջոցառումների կազմակերպումը, որոնք ուսանողների մեջ մեծ մասսայականություն են վայելում:</p>
            <h1 style={{fontSize:'30px',textAlign:'center'}}>ԿԱՌՈՒՑՎԱԾՔ</h1>
            <div className='advimg' data-aos='fade-up'>
                <img src={img2}/>
            </div>
            <article>
                <h1 style={{fontSize:'30px',textAlign:'center'}}>ԿԱՆՈՆԱԴՐՈՒԹՅՈՒՆ</h1>
                <h4>1. Ընդհանուր դրույթներ</h4>
                 
                1.1  Երևանի Փ. Թերլեմեզյանի անվան գեղարվեստի պետական քոլեջի ուսանողական խորհուրդը (այսուհետև ՝ ՈւԽ) ուսանողության  ներկայացուցչական, ինքնակառավարման և նրա շահերը պաշտպանող ու ներկայացնող ապաքաղաքական, ապակուսակցական ընտրովի մարմին է:
<br/>
<br/>
1.2 ՈւԽ-ն արտահայտում և պաշտպանում է ուսանողության շահերը, նպաստում նրանց կրթական որակի բարձրացմանը:  
<br/>
<br/>
1.3 ՈւԽ-ն իր գործունեությունը իրականցնում է ՀՀ Սահմանադրության, ՀՀ գործող օրենսդրությանը, Փ. Թերլեմեզյանի անվան գեղարվեստի պետական քոլեջի կանոնադրությանը  և  սույն կանոնադրությանը համապատասխան:
<br/>
<br/>
1.4 ՈւԽ-ի հասցեն է՝ ք. Երևան, Արշակունյաց 39, Երևանի Փ. Թերլեմեզյանի անվան գեղարվեստի պետական քոլեջ:
<br/>
<br/>
<h4>2. Ուսանողական Խորհրդի նպատակները և խնդիրները</h4>
<br/>
<br/>
2.1 Քոլեջի կոլեգիալ կառավարման խորհրդի կազմում ներկայացնել երեք ուսանող, իսկ քոլեջն ավարտելու, հրաժարվելու, քոլեջից ազատվելու կամ նմանատիպ այլ  պարագաներում  կազմակերպել ընտրություններ և ներկայացնել նոր թեկնածուներ:
<br/>
<br/>
2.2 Քոլեջի կառավարման համապատասխան մարմինների քննարկմանը ներկայացնել ուսանողությանն առնչվող հարցերը:
<br/>
<br/>
2.3 Մասնակցել ուսումնական գործընթացի կազմակերպմանը և ուսուցման արդյունավետության բարձրացմանն ուղղված ծրագրերի մշակմանն ու իրականցմանը:
<br/>
<br/>
2.4 Զորավիգ լինել ուսանողների ընկերային խնդիրների լուծմանը, պաշտպանել նրանց շահերն ու իրավունքները:
<br/>
<br/>
2.5 Մասնակցել քոլեջի տարածքում ներքին կարգապահության պահպանմանը:
<br/>
<br/>
2.6 Անմիջականորեն մասնակցել ազգային հարստության պահպանման գործընթացներին:
<br/>
<br/>
2.7 Նպաստել ուսնողների ուսումնառության արդյունավետության բարձրացմանը, կենսագործունեության ակտիվացմանը, նրանց գիտա-կրթական, մտավոր, ստեղծագործական, բարոյական և հոգևոր զարգացմանը:
<br/>
<br/>
2.8 Կազմակերպել ուսանողության ազատ ժամանցը, հանգիստը:
<br/>
<br/>
2.9 Կազամակերպել ցուցահանդեսներ, ցուցահանդես-վաճառքներ, պլեներներ:
<br/>
<br/>
2.10 Ստեղծել կապեր ՀՀ և արտերկրի ԲՈՒՀ-երի, ինչպես նաև գործող երիտասարդական կազմակերպությունների հետ:
<br/>
<br/>
2.11 ՈւԽ-ն սահմանված կարգով կարող է իրականացնել հրատարակչություն:
<br/>
<br/>
<h4>3. Ուսանողական Խորհրդի կառուցվածքը և ղեկավար մարմինները</h4>
<br/>
<br/>
3.1 ՈւԽ-ի բարձրագույն ղեկավար մարմինը քոլեջի ուսանողական Խորհրդի ընդհանուր ժողովն է:
<br/>
<br/>
3.2 ՈւԽ-ի ընդհանուր ժողովին մասնակցում են բաժինների ՈւԽ-ի ներկայացուցիչները՝ ըստ քոլեջի ուսանողների թվի համամասնության, յուրաքանչյուր կուրսից 1 ներկայացուցիչ:
<br/>
<br/>
3.3 Ընդհանուր ժողովն իրավական է, եթե նրան մասնակցում է ընդհանուր կազմի երկու երրորդից ավելին:
<br/>
<br/>
3.4 ՈւԽ-ի նախագահության որոշմամբ, ինչպես նաև ՈւԽ-ի անդամների ընդհանուր թվի առնվազն մեկ երրորդի պահանջով կարող է գումարվել ՈւԽ-ի արտահերթ ընդհանուր ժողով:
<br/>
<br/>
3.5 Ընդհանուր ժողովի որոշումներն ընդունվում են մասնակիցների ընդհանուր թվի ձայների պարզ մեծամասնությամբ՝ բաց քվեարկությամբ:
<br/>
<br/>
3.6 ՈւԽ-ի նախագահությունը կազմված է ՈւԽ-ի նախագահից, փոխնախագահից, քարտուղարից և բաժինների նախագահներից:
<br/>
<br/>
<h4>4. Ընդհանուր ժողովը՝</h4>
<br/>
<br/>
4.1 Քննարկում է ՈւԽ-ի կանոնադրությունը, դրանում կատարում լրացումներ ու փոփոխություններ և ներկայացնում քոլեջի տնօրենի հաստատմանը:
<br/>
<br/>
4.2 Քննարկում է ընթացիկ և հեռանկարային հարցեր:
<br/>
<br/>
4.3 Ընտրում է ՈւԽ-ի նախագահ, փոխնախագահ, քարտուղար և բաժինների նախագահներ:
<br/>
<br/>
4.4 Լսում է նախագահության հաշվետվությունը ընդհանուր ժողովների միջև ընկած ժամանակահատվածում ՈւԽ-ի կատարած աշխատանքների մասին:
<br/>
<br/>
<h4>5. ՈւԽ-ի նախագահությունը՝</h4>
<br/>
<br/>
5.1 Ընդհանուր ժողովների միջև ընկած ժամանակահատվածում իրականացնում է ՈւԽ-ի աշխատանքների ղեկավարումը:
<br/>
<br/>
5.2 Համակարգում է բաժինների գործունեությունը:
<br/>
<br/>
5.3 Նիստեր է գումարում ամիսը մեկ անգամ, որտեղ քննարկվում է ՈւԽ-ի ընթացիկ հարցերը, լսվում են զեկուցումներ բաժիններում և հանձնաժողովներում կատարվող աշխատանքների մասին:
<br/>
<br/>
5.4 Անհրաժեշտության դեպքում հրավիրում է նախագահության արտահերթ նիստեր:
<br/>
<br/>
5.5 ՈւԽ-ի նախագահը, որը հանդիսանում է նաև ՈւԽ-ի նախագահության նախագահ, ներկայացնում է ՈւԽ-ն ՀՀ և արտերկրի բոլոր պետական և ոչ պետական մարմիններում, մասնավոր անձանց մոտ, տալիս է լիազորագրեր՝ գործելով սույն կանոնադրությամբ ՈւԽ-ին վերպահված հարցերի շրջանակում:
<br/>
<br/>
5.6 Քոլեջն ավարտելու կամ ակադեմիական արձակուրդում գտնվելու դեպքում ՈւԽ-ի նախագահը վայր է դնում իր լիազորությունները: Հայտարարվում է նոր ընտրություն:
<br/>
<br/>
5.7 Պատասխանատու քարտուղարը կազմում է ընդհանուր ժողովների և հերթական նիստերի արձանագրությունները և կազմակերպում է քարտուղարության այլ աշխատանքներ: Քոլեջն ավարտելու կամ ակադեմիական արձակուրդում գտնվելու դեպքում ՈւԽ-ի քարտուղարը վայր է դնում իր լիազորությունները, փոխանցում է ՈւԽ-ի հետ առնչվող բոլոր փաստաթղթերը, այդ թվում արձանագրությունները նոր ընտրված քարտուղարին:
<br/>
<br/>
<h4>6. Փոխնախագահ</h4>
<br/>
<br/>
6.1 ՈւԽ-ի  նախագահի բացակայությամբ լրիվ ծավալով կատարում է նախագահի պարտականությունները:
<br/>
<br/>
6.2 Զբաղվում է բաժինների նախագահների և հանձնաժողովների աշխատանքների համակարգմամբ և այլ կազմակերպչական հարցերով:
<br/>
<br/>
<h4>7. Ֆինանսական միջոցները</h4>
<br/>
<br/>
7.1 ՈւԽ-ն  ՀՀ օրենսդրությամբ սահմանված կարգով կարող է ձեռք բերել և տնօրինել նյութական և գույքային միջոցներ: Նրա  ֆինանսական միջոցները գոյանում են նվիրատվություններից, հանգանակություններից, դրամաշնորհներից և ՀՀ օրենսդրությանը չհակասող այլ աղբյուրներից: ՈւԽ-ն ՀՀ օրենսդրությամբ սահմանված կարգով անվճար օգտվում է քոլեջի տարածքային և գույքային միջոցներից:
<br/>
<br/>
<h4>8. Լուծարումը և վերակազմավորումը</h4>
<br/>
<br/>
8.1 ՈւԽ-ն լուծարվում և վերակազմավորվում է ՀՀ օրենսդրությամբ, քոլեջի կանոնադրությամբ նախատեսված դեպքերում:
<br/>
<br/>
8.2 Լուծարման կապակցությամբ ստեղծվում է լուծարքային հանձնաժողով, որը կազմակերպում է հանձնման-ընդունման աշխատանքները: Լուծարված ՈւԽ-ի գույքը համարվում է  Երևանի Փ. Թերլեմեզյանի անվան գեղարվեստի պետական քոլեջի սեփականությունը:
 
            </article>
        </section>
      </div>

                )
            }
        </>
    )
}

export default connect(r=>r)(Advice)