import React, { useReducer, useRef, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { loadCards, selectChange } from '../../redux/actions'
import { makeStyles } from '@material-ui/core/styles';
import Loading from '../loadingComponent/Loading'
import Pagination from '@material-ui/lab/Pagination';
import './gallery.css'
 
const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      marginTop: theme.spacing(2),
      padding:'25px'
    },
  },
}));



function Gallery(props){
  const [currentPage, setCurrentPage]= useState(1)
  const [itemsPerPage]=useState(6)
  const pageNumbers=[]

  for (let i=1;i<=Math.ceil(props.gal1.generations.length/itemsPerPage);i++){
    pageNumbers.push(i)
  }

  const indexOfLastItem = currentPage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage

  const currentItems = props.gal1.generations.slice(indexOfFirstItem,indexOfLastItem)
  const handleChange = (event, value) => {
    setCurrentPage(value);
  };
  useEffect(()=>{
    props.dispatch(loadCards())
    window.scrollTo(0, 0)
  },[])
  const classes = useStyles();
  return(
    <div className='galleryPage'>
      <div className='filter'>
        <p>Դիտել ըստ տարեթվի․</p>
      <select onChange={(e)=>props.dispatch(selectChange(e.target.value))}>
      <option value='All'>Ամբողջ</option>
      <option value='2020'>2020</option>
      <option value='2019'>2019</option>
      <option value='2018'>2018</option>
      <option value='2017'>2017</option>
    </select>
      </div>
    <main className="page-content">
      {
        props.gal1.loadSpinner==false?(
          <Loading/>
      ):(
        currentItems.map((item,index)=>{
          return(
            <>
            <div 
            key={index} 
            className="card5" 
            style={{backgroundImage:`url(http://localhost:5000/${item.img}.jpg)`,backgroundRepeat: 'no-repeat',backgroundSize: 'cover'}}
            >
              <div className="content">
              <h2 className="title">{item.title}</h2>
              <p className="copy">
                {item.text.length>120?item.text.substring(0,110)+'...':item.text}
              </p>
              <Link to={`/gallery/events/${item.id}`} className="btn">Տեսնել ավելին</Link>
              </div>
            </div>
            
            </>
          )
        })
      )
      }
    </main>
    <div className={classes.root}>
       <Pagination count={pageNumbers.length}
       variant="outlined" 
       color="secondary"
       page={currentPage}
       onChange={handleChange}
       />
      </div>
    </div>
  )
}
 

export default connect(r=>r)(Gallery)