import React, { useState,useEffect } from 'react'
import { connect } from 'react-redux'
import Contact from '../../parts/contactForm'
import img1 from '../../images/careerP.jpg'
// import bg4 from '../images/1.jpg'
import img2 from '../../images/footerLogo.png' 
import './career.css'
import Preloader from '../../parts/preloader'


function Carerr(props){ 
    document.title='Career - Terlemezyan'

    const[load,setLoad]=useState(true)
  
    useEffect(()=>{
      window.scrollTo(0, 0);
      setLoad(false)
    },[])

   
    return(
      <>
        {
            load==true?(
                <Preloader/>
            ):(
                <div className='Carerr-page'>
        <div style={{height:'390px',background: 'rgba(0,0,0,0.4)'}}>
            <img style={{height: '100%',width: '100%',objectFit: 'cover'}} src={img1} />
        </div>
         <article>
         <h1 style={{fontSize:'30px'}}>ԿԱՐԻԵՐԱՅԻ ԵՎ ՈՐԱԿԻ ԿԵՆՏՐՈՆ</h1>
            <p>«Երևանի Փ. Թերլեմեզյանի անվան գեղարվեստի պետակ քոլեջ» ՊՈԱԿ-ի մասնագիտական կոմնորոշման  և կարիերայի ուղղորդման կենտրոնը ստեղծվել է 2019թ.-ին` կոլեգիալ կառավարման խորհրդի որոշմամբ (նույն թվականին կոլեգիալ կառավարման խորհրդի կողմից հաստատվել է կենտրոնի  կանոնակարգը ): Կարիերայի կենտրոնի հիմնական              նպատակն է` կարիերայի տեղեկատվության, խորհրդատվության, ուղղորդման աշխատանքների, ինչպես նաև կարիերայի պլանավորման կարողությունների ձևավորման միջոցով            նպաստել ուսանողների և շրջանավարտների աշխատաշուկայում մրցունակության բարձրացմանը, աջակցել իրենց մասնագիտությամբ զբաղվածության հնարավորությունների  բացահայտմանը: </p>
         <h1 style={{fontSize:'30px'}}>«ԵՐԵՎԱՆԻ Փ. ԹԵՐԼԵՄԵԶՅԱՆԻ ԱՆՎԱՆ ԳԵՂԱՐՎԵՍՏԻ ՊԵՏԱԿԱՆ ՔՈԼԵՋ» ՊՈԱԿ-ի ՄԱՍՆԱԳԻՏԱԿԱՆ ԿՈՂՄՈՆՈՐՈՇՄԱՆ ԵՎ ԿԱՐԻԵՐԱՅԻ ՈՒՂՂՈՐԴՄԱՆ ԿԵՆՏՐՈՆԻ ԿԱՆՈՆԱԿԱՐԳ</h1>
             <p>1. ԸՆԴՀԱՆՈՒՐ ԴՐՈՒՅԹՆԵՐԸ </p>
            <br/>
            <p>1.1. «Երևանի Փ. Թերլեմեզյանի անվան գեղարվեստի պետական քոլեջ» պետական ոչ առևտրային կազմակերպության կարիերայի կենտրոնը (այսուհետև՝ կարիերայի կենտրոն) ստեղծվում է ուսումնական հաստատության տնօրենի հրամանով և պատասխանատու է ուսանողների կարիերայի արդյունավետ ուղղորդման, ծառայությունների մատուցման համար:</p>
            <br/>
            <p>1.2. «Երևանի Փ. Թերլեմեզյանի անվան գեղարվեստի պետական քոլեջ» պետական ոչ առևտրային կազմակերպության շրջանավարտների աջակցման  և կարիերայի կենտրոնի հիմնական նպատակն է նպաստել աշխատանքային շուկայում ԵՓԹԳՊՔ ուսանողների և շրջանավարտների մրցունակության, շրջանավարտների և քոլեջի միջև մշտական կապի ամրապնդմանն ու համագործակցության զարգացմանը։</p>
            <br/>
            <p>1.3. Կենտրոնը գործում է՝ ԵՓԹԳՊՔ կանոնադրության, սույն կանոնակարգի, ՀՀ օրնեսդրության համապատասխան և այլ իրավական ակտերի հիման վրա ։</p>
            <br/>
            <p>1.4. Կենտրոնի կանոնակարգը հաստատում է ԵՓԹԳՊՔ-ի կոլեգիալ կառավարման խորհուրդը:</p>
            <br/>
            <p>1.5. Կենտրոնը կարիերայի ուղղորդման գործառույթների շրջանակում համագործակցում է ՀՀ աշխատանքի և սոցիալական հարցերի նախարարության «Մասնագիտական կողմնորոշման մեթոդական կենտրոն» ՊՈԱԿ-ի (այսուհետև` Կենտրոն), «Զբաղվածության պետական գործակալության» (այսուհետև՝ ԶՊԳ) տարածքային կենտրոնների, սոցիալական գործընկերների, համապատասխան պետական և մասնավոր այլ կազմակերպությունների հետ:</p>
            <br/>
            <p>1.6. Կարիերայի կենտրոնի աշխատանքները պլանավորում և իրականացնում է կարիերայի ուղղորդման պատասխանատուն (կարիերայի համակարգող), իսկ նրա բացակայության դեպքում` տնօրենի հրամանով կամ պաշտոնի անձնագրով նախատեսված փոխարինող մասնագետը:</p>
            <br/>
            <p>1.7. Կարիերայի կենտրոնն իր գործունեությունը կազմակերպում և իրականացնում է տնօրենի կողմից հաստատվող տարեկան աշխատանքային ծրագրերով:</p>
            <br/>
            <p>1.8. Կենտրոնի գործունեությունը ֆինանսավորվում է ԵՓԹԳՊՔ-ի բյուջեից, դրամաշնորհներից, ինչպես նաև ՀՀ օրենսդրությամբ չարգելված այլ աղբյուրներից։</p>
            <br/>
            <p>1.9. Սույն կանոնակարգի մեջ փոփոխություններն ու լրացումներն իրականացվում են ԵՓԹԳՊՔ-ի տնօրենի առաջարկությամբ՝ ԵՓԹԳՊՔ-ի կոլեգիալ կառավարման խորհուրդի հաստատմամբ:</p>
            <br/>
            <p>2. ՀԻՄՆԱԿԱՆ ՆՊԱՏԱԿԸ, ԽՆԴԻՐՆԵՐՆ ՈՒ ԳՈՐԾԱՌՈՒՅԹՆԵՐԸ</p>
            <br/>
            <p>2.1. Կարիերայի կենտրոնի հիմնական նպատակն է` կարիերայի տեղեկատվության, խորհրդատվության, ուղղորդման աշխատանքների, ինչպես նաև կարիերայի պլանավորման կարողությունների ձևավորման միջոցով նպաստել աշխատաշուկայում ուսանողների և շրջանավարտների մրցունակության բարձրացմանը, աջակցել իրենց մասնագիտությամբ զբաղվածության հնարավորությունների բացահայտմանը:</p>
            <br/>
            <p>2.2. Կենտրոնի խնդիրներն են՝</p>
            <br/>
            <p>2.2.1. ԵՓԹԳՊՔ-ի  ուսանողների և շրջանավարտների կողմից աշխատանք գտնելու հիմնախնդիրների ուղղությամբ քոլեջում տարվող աշխատանքների կազմակերպումը, համակարգումը և ամփոփումը:</p>
            <br/>
            <p>2.2.2.Քոլեջի կրթական ծրագրերի արդիականության և շարունակական բարելավման ապահովում շրջանավարտների ուսումնասիրման միջոցով:</p>
            <br/>
            <p>2.2.3. Քոլեջի ուսանողների, շրջանավարտների և պոտենցիալ գործատուների միջև արդյունավետ կապի ապահովում։</p>
            <br/>
            <p>2.2.4 Քոլեջի սովորողների մոտ ուսումնառության հետ զուգահեռ աշխատանքային որոշակի փորձի ձևավորման ուղղությամբ աշխատանքների կազմակերպումը:</p>
            <br/>
            <p>2.3. Կենտրոնի գործառույթներն են՝</p>
            <br/>
            <p>2.3.1. Սեմինարների, կլոր սեղանների, նպատակային և լրացուցիչ պատրաստման և վերապատրաստման դասընթացների, աշխատաշուկայում արդի պահանջների և աշխատանքի որոնման տեխնոլոգիաների ու հմտությունների, մասնագիտական կողմնորոշման և ուրիշ խնդիրների վերաբերյալ խորհրդատվական ու այլ միջոցառումների կազմակերպում, համապատասխան դասընթացներ և այլ ծրագրեր իրականացնող կազմակերպությունների հետ համագործակցություն:</p>
            <br/>
            <p>2.3.2. Ըստ անհրաժեշտության, ուսանողի անձնային որակների, նախասիրությունների, կարողությունների բացահայտում, անհատական ծրագրի կազմում:</p>
            <br/>
            <p>2.3.3. Շարունակական և լրացուցիչ կրթության, ուսուցման, տնտեսության գերակա ոլորտների, աշխատաշուկայի վերլուծությունների և կանխատեսումների (տեղական, մարզային, հանրապետական և միջազգային), ինչպես նաև ըստ մասնագիտությունների զբաղմունքների մասին տեղեկատվության հավաքագրում և ուսանողներին ծանուցում:</p>
            <br/>
            <p>2.3.4. Գործատուի հետ բանակցել արտադրական պրակտիկայի ձևաչափը, հնարավոր պայմանները, տևողությունը և փոխհամաձայնությամբ կազմակերպել ԵՓԹԳՊՔ-ի ուսանողների արտադրական պրակտիկան:</p>
            <br/>
            <p>2.3.5. Զբաղվածության ծառայությունների ու գործակալությունների հետ հաստատել կապեր և ապահովել զբաղվածությանն առնչվող տեղեկատվության փոխանցումն ու տարածումը:</p>
            <br/>
            <p>2.3.6. Պրակտիկայի, կամավոր աշխատանքի, վերապատրաստման և փորձի ձեռքբերման այլ ժամանակակից ձևերի և միջոցների վերաբերյալ առաջարկությունների, ծրագրերի մշակումը և համապատասխան աշխատանքների իրականացումը:</p>
            <br/>
            <p>2.3.7. Պոտենցիալ գործատուների, տարբեր կազմակերպությունների հետ համաձայանագրերի կնքում, դրանց իրականացման ապահովում:</p>
            <br/>
            <p>2.3.8. Աշխատանք առաջարկող պետական, մասնավոր, միջազգային և այլ կազմակերպությունների կողմից առաջնային համարվող պահանջների և դրանց զարգացման միտումների պարբերաբար ուսումնասիրություն:</p>
            <br/>
            <p>2.3.9. Պետական և մասնավոր սեկտորում զբաղվածության խնդիրներով զբաղվող պետական և մասնավոր կազմակերպությունների, ինչպես նաև տարբեր հիմնադիրների, միջազգային դոնոր կառույցների, հասարակական և այլ կազմակերպությունների հետ համագործակցության կազմակերպում:</p>
            <br/>
            <p>2.3.10. Կապեր հաստատել տարբեր գործատուների հետ և ներկայացնել վերջինիս պահանջարկների առանձնահատկությունները քոլեջում:</p>
            <br/>
            <p>2.3.11. Տարբեր ցուցահանդեսների (մասնավորապես՝ «աշխատանքի տոնավաճառ», «կարիերայի օր» և այլն), գործատուների հետ հանդիպումների, կազմակերպությունների ներկայացումների (շնորհանդեսների), ձեռնարկությունների էքսկուրսիաների, փոխայցելությունների և այլ միջոցառումների կազմակերպում:</p>
            <br/>
            <p>2.3.12. Հաշվետվություններ կազմել, մշակել, ձևավորել և շարունակաբար թարմացնել ուսանողների և շրջանավարտների տեղեկատվական համակարգերը:</p>
            <br/>

         
         
         </article>  
         <div className='cards-big'>        
            <div className='CareerCard'>
                <img src={img2} alt='someimg'/>
                <p>ՄԱՍՆԱԳԻՏԱԿԱՆ ԿՈՂՄՈՆՈՐՈՇՄԱՆ ԵՎ ԿԱՐԻԵՐԱՅԻ ԿԵՆՏՐՈՆԻ ԿԱՆՈՆԱԿԱՐԳ</p>
                <div data-aos='fade-up' style={{backgroundColor:'#591017',minHeight:'70px',display:'flex',justifyContent:'center'}}>
                    <h2>
                        <a style={{color:'#fff'}} href="https://drive.google.com/file/d/1shOQ8d5xp4kXkkPoAauBiggYOg2m4EWg/view">PDF</a>
                    </h2>
                </div>
            </div>
            <div className='CareerCard'>
                <img src={img2} alt='someimg'/>
                <p>ԱՆՉԱՓԱՀԱՍ՝ ՄԻՆՉԵՎ 18 ՏԱՐԵԿԱՆ ԱՆՁԱՆՑ ԱՇԽԱՏԱՆՔԻ ԻՐԱՎՈՒՆՔԻ ԿԱՐԳԱՎՈՐՈՒՄՆԵՐ</p>
                <div data-aos='fade-up' style={{backgroundColor:'#591017',minHeight:'70px',display:'flex',justifyContent:'center'}}>
                    <h2>
                        <a style={{color:'#fff'}} href="https://drive.google.com/file/d/1H1FQBuYoyyCLXrRlw0tRV1ZTjoXJ7rD0/view">PDF</a>
                    </h2>
                </div>
            </div>
         </div>  
       <Contact/>
      </div>

            )
        }
      </>
    )
}

export default connect(r=>r)(Carerr)