import React, { useEffect,useState } from 'react'
import { connect } from 'react-redux'
import Contact from '../../parts/contactForm'

// import './contactp.css'
import img1 from '../../images/gexankar2.jpg'
import img2 from '../../images/paint1.jpg'
import img3 from '../../images/paint2.jpg'
import img4 from '../../images/paint3.jpg'
import img5 from '../../images/paint4.jpg'
import img6 from '../../images/paint5.jpg'
import img7 from '../../images/paint6.jpg'
import img8 from '../../images/paint7.jpg'
import img9 from '../../images/paint8.jpg'
import img10 from '../../images/paint9.jpg'
import img11 from '../../images/paint10.jpg'
import './paint.css'

import 'react-slideshow-image/dist/styles.css'
import Preloader from '../../parts/preloader'
 

function Painting(props){ 
    document.title='Painting - Terlemezyan'
    const[load,setLoad]=useState(true)
  
    useEffect(()=>{
      window.scrollTo(0, 0);
      setLoad(false)
    },[])

   
    return(
      <>
        {
            load==true?(
                <Preloader/>
            ):(
                <div className='painting-page'>
        <div style={{height:'390px',background: 'rgba(0,0,0,0.4)'}}>
            <img style={{height: '100%',width: '100%',objectFit: 'cover',objectPosition:'50% 0%'}} src={img1} />
        </div>
         <article>
         <h1 style={{fontSize:'40px'}}>Գեղանկարչություն</h1>
         <p>Գեղանկարչության բաժինը Փ. Թերլեմեզյանի անվան 
             գեղարվեստի պետական քոլեջի հնագույն և հիմնական 
             բաժիններից է, որտեղ ժամանակին դասավանդել են մեծ 
             վարպետներ Սեդրակ Առաքելյանը, Վրթանես Ախիկյանը, 
             Գաբրիել Գյուրջյանը, Ստեփան Աղաջանյանը և այլոք: 
             Տարիներ շարունակ այստեղ դասավանդվում են ակադեմիական 
             գեղանկարչության, գծանկարչության և կոմպոզիցիայի 
             սկզբունքներն ու խնդիրները, ինչպես նաև կիրառվում 
             են մեր երախտավոր նկարիչ-դասախոսների կողմից մշակված 
             և հաստատված ուսումնական ծրագրերը: Այդ ծրագրերումՙ 
             հիմնական մասնագիտական առարկաների, անատոմիայի և 
             տեխնոլոգիայի դասաժամերին զուգահեռ, հատուկ տեղ է 
             հատկացվում ամառային /պլեներային/ աշխատանքներին, 
             որոնք, որպես ավանդույթ, ցուցադրվում են տարեվերջյան 
             հաշվետու ցուցահանդեսներին: </p><br/>
             <p>Երևանի Փ. Թերլեմեզյանի անվան գեղարվեստի պետական 
                 քոլեջը պետական կրթական չափորոշիչների, հաստատված 
                 ուսումնական պլանների և առարկայական ծրագրերի միջոցով 
                 պատրաստում է բարձր որակի մասնագետներ հաստոցային 
                 գեղանկարչության ստեղծագործական աշխատանքի, ինչպես 
                 նաև գեղանկարչության, գծանկարչության գծով մանկավարժական 
                 գործունեության համար: </p>
            <ul>
                <li>Ուսուցման ձևը` առկա, անվճար և վճարովի համակարգերով: </li>
                <li>Ուսուցման տևողությունը` հինգ տարի:</li>
                <li>Ուսման վարձը` 350000 դրամ:</li>
                <li>Որակավորումը` նկարիչ-դասավանդող: </li>
            </ul>
         </article>
         <section className='images'>
             <div className='paintCards'><img src={img2}/></div>
             <div className='paintCards'><img src={img3}/></div>
             <div className='paintCards'><img src={img4}/></div>
             <div className='paintCards'><img src={img5}/></div>
         </section>
         <div className='big-cards'>
            <h1>Գեղանկարչություն առարկայական հանձնաժողովի նախագահ</h1>
            <div className='main-card'>
                <div className='cardImg'>
                    <img src={img6}/>
                </div>
                <div className='cardDescr'>
                    <h1>Ռոբերտ Խաչատրյան</h1>
                    <ul>
                        <li>1950թ.-ին ծնվել է Թալինի շրջանի Գառնահովիտ գյուղում: </li><br/>
                        <li>1971թ.-ին ավարտել է Երևանի Փ. Թերլեմեզյանի անվան գեղարվեստի պետական ուսումնարանը: </li><br/>
                        <li>1977թ.-ին ավարտել է Երևանի Գեղարվեստա-թատերական ինստիտուտի «Գեղանկարչություն» ֆակուլտետը:</li><br/>
                        <li>1984թ-ից` Հայաստանի Նկարիչների Միության անդամ: </li><br/>
                        <li>1999թ-ից` UNESCO-ի նկարիչների միության անդամ: </li><br/>
                        <li>1977թ-ից` դասավանդում է Փ. Թերլեմեզյանի անվան Գեղարվեստի պետական քոլեջում: </li><br/>
                        <li>Մասնակցել է բազմաթիվ ցուցահանդեսների:</li>
                    </ul>
                </div>
            </div>
         </div>

         <div className='big-cards'>
            <h1>Գծանկարչություն առարկայական հանձնաժողովի նախագահ</h1>
            <div className='main-card'>
                <div className='cardImg'>
                    <img src={img7}/>
                </div>
                <div className='cardDescr'>
                    <h1 >Տարոն Շահինյան</h1>
                    <ul>
                        <li>Ծնվել է 1994թ.-ին Կոտայքի մարզի Աբովյան քաղաքում,ՀՀ</li><br/>
                        <li>2014թ.-ին ավարտել է Երևանի Փ.Թերլեմեզյանի անվան գեղարվեստի պետական քոլեջը։</li><br/>
                        <li>2018թ.-ին ավարտել է Հայաստանի Գեղարվեստի Պետական Ակադեմիաի կերպարվեստի ֆակուլտետի Գեղանկարչություն բաժինը։</li><br/>
                        <li>2023թ.-ից հանդիսանում է Հայաստանի Նկարիչների միության անդամ։</li><br/>
                        <li>2021թ.-ից Դասավանդում է Երևանի Փ.Թերլեմեզյանի անվան գեղարվեստի պետական քոլեջում։</li><br/>
                        <li>Մասնակցել է բազմաթիվ հանրապետական և միջազգային ցուցահանդեսների ։</li><br/>
                    </ul>
                </div>
            </div>
         </div>
         <section className='images'>
             <div className='paintCards'><img src={img8}/></div>
             <div className='paintCards'><img src={img9}/></div>
             <div className='paintCards'><img src={img10}/></div>
             <div className='paintCards'><img src={img11}/></div>
         </section>
       <Contact/>
      </div>

            )
        }
      </>
    )
}

export default connect(r=>r)(Painting)