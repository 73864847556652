import React, { useState,useEffect } from 'react'
import { connect } from 'react-redux'
import bg1 from '../../images/kar1.jpg'
import img1 from '../../images/kar2.png'

import Contact from '../../parts/contactForm'
import Preloader from '../../parts/preloader'
import './structure.css'
function Structure(){
    const[load,setLoad]=useState(true)
  
    useEffect(()=>{
      window.scrollTo(0, 0);
      setLoad(false)
    },[])

  
    document.title='Structure - Terlemezyan'
    const styles={
        head:{
            backgroundColor: `rgba(188,174,173,0.13)`,
            backgroundImage:`url(${bg1})`,
            backgroundPosition: '50% 50%',
            backgroundSize: 'cover',
            height:'320px'
        },
        overlay:{
            width:'1100px',
            height:'256px',
            backgroundColor:'#4e0f0f',
            opacity: 0.4,
            position:'absolute',
            right:'10%',
            top:'40px'
        },
        text:{
            textAlign:'center',
            padding:'80px 0px 80px 0px',
            color:'#ffcc99'
        }
    }
    return(
        <>
            {
                load==true?(
                    <Preloader/>
                ):(
                    <>
        <div className='structure-page' style={styles.head}/>        
        <section>
            <div className='secImg'>
                <img src={img1}/>
            </div>
        </section>
        <Contact/>
        </>
                )
            }
        </>
    )
}

export default connect(r=>r)(Structure)