import React, { useState,useEffect } from 'react'
import { connect } from 'react-redux'
import bg1 from '../../images/doc1.jpg'
import img1 from '../../images/footerLogo.png' 
import Contact from '../../parts/contactForm'
import './doc.css'

import Aos from 'aos';
import 'aos/dist/aos.css';
import Preloader from '../../parts/preloader'

function Document(props){
    document.title='Documents - Terlemezyan'
    const[load,setLoad]=useState(true)
  
    useEffect(()=>{
      window.scrollTo(0, 0);
      setLoad(false)
    },[])

  
    const styles={
        head:{
            backgroundColor: `rgba(188,174,173,0.13)`,
            backgroundImage:`url(${bg1})`,
            backgroundPosition: 'center center',
            backgroundSize: 'cover',
            height:'320px'
        },
        overlay:{
            width:'1100px',
            height:'256px',
            backgroundColor:'#4e0f0f',
            opacity: 0.4,
            position:'absolute',
            right:'10%',
            top:'40px'
        },
        text:{
            textAlign:'center',
            padding:'80px 0px 80px 0px',
            color:'#ffcc99'
        }
    }

    let docCards=[
        {text:'Հայաստանի Հանրապետության Կրթության մասին օրենք',href:'https://drive.google.com/file/d/1SnhUemHZp_GHYl77zR7qoc_l704iuAHc/view'},
        {text:'Երևանի Փ. Թերլեմեզյանի անվան պետական գեղարվեստի քոլեջի կանոնադրություն',href:'https://drive.google.com/file/d/1fCLuSKQtFLkUUz6jQP-0Qt-OOF_RRQqs/view'},
        {text:'Երևանի Փ. Թերլեմեզյանի անվան պետական գեղարվեստի քոլեջի ռազմավարական պլան /2018-2023/',href:'https://drive.google.com/file/d/1jFZkE9FEwBpM3-V1BKA01-JB23u1isO1/view'},
        {text:'Միջին մասնագիտական կրթության պետական կրթական չափորոշիչներ',href:'https://drive.google.com/file/d/10dxhDg0J-UhBr8zdDoAy_zR8NoiJaL1p/view'},
        {text:'Դիզայն մասնագիտության չափորոշիչ',href:'https://drive.google.com/file/d/167sfm1fsE5w6yU8p1hOfkkVsO5xPxUfZ/view'},
        {text:'Գեղանկարչություն մասնագիտության չափորոշիչ',href:'https://drive.google.com/file/d/14HE5yCXJX9glyU5tScmm_IrS4DaSId_W/view'},
        {text:'Քանդակագործություն մասնագիտության չափորոշիչ',href:'https://drive.google.com/file/d/1dzLnrZRsQSzeIMRfaTtvS-n4CqVtHVjJ/view'},
        {text:'Զարդային կիրառական արվեստ և ժողովրդական արհեստներ մասնագիտության չափորոշիչ',href:'https://drive.google.com/file/d/1bPSU0gMy6g08e5d-4g6x_sRK3Tt4-b59/view'},
        {text:'Ուսումնական հաստատությունում ուսումնառությունը անավարտ թողած անձի ուսանողական իրավունքի վերականգման կարգ',href:'https://www.arlis.am/DocumentView.aspx?DocID=73373'},
        {text:'Միջին մասնագիտական կրթական ծրագրեր իրականացնող պետական ուսումնական հաստատությունների ընդունելության կարգ',href:'https://www.arlis.am/DocumentView.aspx?DocID=107448'},
        {text:'Միջին մասնագիտական կրթական ծրագրեր իրականացնող պետական ուսումնական հաստատություններում ուսանողական նպաստ և պետական կրթաթոշակ տրամադրելու կարգ',href:'https://www.arlis.am/DocumentView.aspx?DocID=114533'},
        {text:'Միջին մասնագիտական պետական ուսումնական հաստատություններում դիմորդների ընդունելության արտոնությունների որոշում',href:'https://www.arlis.am/DocumentView.aspx?DocID=114533'},
    ]
    return(
       <>
       {
           load==true?(
               <Preloader/>
           ):(
            <>
            <div className='document-page' style={styles.head}/>
            <section>
                 <h1 style={{textAlign:'center', fontSize:'40px'}}>Փաստաթղթեր</h1>
                <div className='docCards'> 
                    {
                        docCards.map((item,index)=>{
                            return(
                            <div key={index} className='doccard' data-aos='fade-up'>
                                <div>
                                    <img src={img1} width='60px' />
                                </div>
                                <div className='cardContent'>
                                    <h3>{item.text}</h3>
                                </div>
                                <a href={item.href}>Ներբեռնել</a>
                            </div>
                            )
                        })
                    }
                </div>
            </section>
            <Contact/>
            </>
    
           )
       }
       </> 
    )
}

export default connect(r=>r)(Document)