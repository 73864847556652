import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import img1 from '../../images/admission.jpg'
import img2 from '../../images/adm2.jpg'
import img3 from '../../images/adm3.jpg'
import image1 from '../../images/image1.png'
import image2 from '../../images/image2.png'
import './Admission.css'
import Table from './table'

import Aos from 'aos';
import 'aos/dist/aos.css';
import Preloader from '../../parts/preloader'

const styles = {
    head: {
        backgroundColor: `rgba(188,174,173,0.13)`,
        backgroundImage: `url(${img1})`,
        backgroundPosition: '50% 46%',
        backgroundSize: 'cover',
        height: '366px'
    },
}

function Admission() {
    const [load, setLoad] = useState(true)

    useEffect(() => {
        window.scrollTo(0, 0);

        setLoad(false)

    }, [])


    document.title = 'Admission - Terlemezyan'
    return (
        <>
            {
                load == true ? (
                    <Preloader />
                ) : (
                    <>
                        <div className='admission-head' style={styles.head}></div>
                        <section className='admSection'>

                            <div>
                                <div className='sectitle'>
                                    <h1 style={{ fontSize: '36px' }}>Ընդունելություն 2024-2025</h1>
                                </div>
                                <article className='admArt'>

                                    <>
                                  
                                        <p className="c8 c44 c46" id="h.gjdgxs">
                                            <span className="c15 c48">ԸՆԴՈՒՆԵԼՈՒԹՅԱՆ ՊԱՅՄԱՆՆԵՐԸ</span>
                                        </p>
                                        <p className="c8 c30 c44 c46">
                                            <span className="c15 c48" />
                                        </p>
                                        <p className="c8 c30 c44 c46">
                                            <span className="c15 c48" />
                                        </p>
                                        <p className="c36">
                                            <span className="c15 c13">
                                                ՀՀ ԿԳՄՍ նախարարության «Երևանի Փ. Թերլեմեզյանի անվան գեղարվեստի պետական
                                                քոլեջ» ՊՈԱԿ-ը հայտարարում է 2024-2025 ուսումնական տարվա ընդունելություն
                                                հետևյալ մասնագիտությունների գծով.
                                            </span>
                                        </p>
                                        <p className="c36 c30">
                                            <span className="c15 c13" />
                                        </p>
                                        <p className="c36">
                                            <span className="c15 c13">
                                                0213.02.5&nbsp;«Գեղանկարչություն», որակավորումը` նկարիչ–դասավանդող, ուսման
                                                տևողությունը` 5 տարի,
                                            </span>
                                        </p>
                                        <p className="c36">
                                            <span className="c15 c13">
                                                0213.03.5&nbsp;«Քանդակագործություն», որակավորումը` քանդակագործ-դասավանդող,
                                                ուսման տևողությունը` 5 տարի,
                                            </span>
                                        </p>
                                        <p className="c36">
                                            <span className="c15 c13">
                                                0212.01.5&nbsp;«Դիզայն», որակավորումը` դիզայներ, ուսման տևողությունը` 4
                                                տարի,
                                            </span>
                                        </p>
                                        <p className="c36">
                                            <span className="c15 c13">
                                                0214.01.5 «Զարդակիրառական արվեստ և ժողովրդական արհեստներ»,
                                                որակավորումը`նկարիչ-ձևավորող, ուսման տևողությունը` 4 տարի
                                            </span>
                                        </p>
                                        <p className="c36 c30">
                                            <span className="c15 c13" />
                                        </p>
                                        <p className="c36">
                                            <span className="c15 c13">
                                                Կրթության ձևը` առկա: Կրթության հիմքը` առնվազն հիմնական կամ միջնակարգ:
                                            </span>
                                        </p>
                                        <p className="c36 c30">
                                            <span className="c15 c13" />
                                        </p>
                                        <p className="c36">
                                            <span className="c15 c13">
                                                Դիմորդը կարող է ներկայացնել հայտ մեկ ուսումնական հաստատություն մեկ
                                                մասնագիտության` որակավորման, մասնագիտացման հայտագրմամբ՝ անվճար և վճարովի
                                                ուսուցման հիմունքով:
                                            </span>
                                        </p>
                                        <p className="c36 c30">
                                            <span className="c15 c13" />
                                        </p>
                                        <p className="c26">
                                            <span className="c13 c24">ՀԱՅԱՍՏԱՆԻ ՀԱՆՐԱՊԵՏՈՒԹՅԱՆ ՔԱՂԱՔԱՑԻՆԵՐԻ, </span>
                                            <span className="c13 c38 c24">
                                                Վրաստանի Սամցխե-Ջավախք ու Քվեմո-Քարթլի նահանգներում գրանցված և բնակվող
                                            </span>
                                            <span className="c13 c24 c78">&nbsp;</span>
                                            <span className="c13 c38 c24">
                                                հայազգի, Հայաստանի Հանրապետությունում փախստական ճանաչված և ապաստան ստացած
                                                անձանց, ինչպես նաև ծագումով հայ այն օտարերկրյա քաղաքացիների համար, որոնց
                                                մշտական բնակության օտարերկրյա պետությունում ստեղծվել է նրանց կյանքին կամ
                                                առողջությանը սպառնացող արտակարգ իրավիճակ,{" "}
                                            </span>
                                            <span className="c15 c13">
                                                քոլեջի բոլոր մասնագիտությունների գծով վարձավճարը կազմում է 350,000 / երեք
                                                հարյուր հիսուն հազար / ՀՀ դրամ: &nbsp;Իսկ ՕՏԱՐԵՐԿՐՅԱ ՔԱՂԱՔԱՑԻՆԵՐԻ համար
                                                քոլեջի բոլոր մասնագիտությունների գծով վարձավճարը կազմում է 550,000 / հինգ
                                                հարյուր հիսուն հազար / ՀՀ դրամ:
                                            </span>
                                        </p>
                                        <p className="c26 c30">
                                            <span className="c15 c13" />
                                        </p>
                                        <p className="c26">
                                            <span className="c15 c13">
                                                Մրցույթին մասնակցելու համար դիմորդի դիմում հայտը և փաստաթղթերն
                                                էլեկտրոնային եղանակով ընդունվում են կրթության կառավարման տեղեկատվական
                                                համակարգում ընդունելության պայմանների վերաբերյալ հայտարարության
                                                հրապարակման օրվանից սկսած մինչև օգոստոսի 8-ը ներառյալ` ժամը 18-00, իսկ
                                                քննություններն անցկացվում են օգոստոսի 9-ից 17-ը ընկած ժամանակահատվածում:
                                            </span>
                                        </p>
                                        <p className="c26">
                                            <span className="c15 c13">
                                                Անվճար և վճարովի ուսուցման տեղերում մրցույթն իրականացվում է քննությունների
                                                ավարտման հաջորդ աշխատանքային օրը:
                                            </span>
                                        </p>
                                        <p className="c36 c30">
                                            <span className="c15 c13" />
                                        </p>
                                        <p className="c36 c30">
                                            <span className="c15 c13" />
                                        </p>
                                        <p className="c36" id="h.30j0zll">
                                            <span className="c15 c48">
                                                Ընդունելության տեղերն ըստ մասնագիտությունների
                                            </span>
                                            <span className="c15 c13">.</span>
                                        </p>
                                        <p className="c29 c30 c44">
                                            <span className="c15 c13" />
                                        </p>
                                        <a id="t.78fbc739ae902a26cab3365835592fac499a654b" />
                                        <a id="t.0" />
                                        <table className="c67">
                                            <tbody>
                                                <tr className="c101">
                                                    <td className="c41" colSpan={1} rowSpan={2}>
                                                        <p className="c29">
                                                            <span className="c15 c13">ՄԱՍՆԱԳԻՏՈՒԹՅՈՒՆԸ</span>
                                                        </p>
                                                    </td>
                                                    <td className="c74" colSpan={3} rowSpan={1}>
                                                        <p className="c29">
                                                            <span className="c15 c13">Ընդունելության տեղերի թիվը</span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr className="c94">
                                                    <td className="c20" colSpan={1} rowSpan={1}>
                                                        <p className="c29">
                                                            <span className="c15 c13">անվճար ուսուցման</span>
                                                        </p>
                                                    </td>
                                                    <td className="c20" colSpan={1} rowSpan={1}>
                                                        <p className="c29">
                                                            <span className="c15 c13">վճարովի ուսուցման</span>
                                                        </p>
                                                    </td>
                                                    <td className="c11" colSpan={1} rowSpan={1}>
                                                        <p className="c29">
                                                            <span className="c15 c13">ընդամենը</span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr className="c43">
                                                    <td className="c21" colSpan={1} rowSpan={1}>
                                                        <p className="c29">
                                                            <span className="c15 c13">* Գեղանկարչություն</span>
                                                        </p>
                                                    </td>
                                                    <td className="c20" colSpan={1} rowSpan={1}>
                                                        <p className="c8">
                                                            <span className="c10 c38">33</span>
                                                        </p>
                                                    </td>
                                                    <td className="c20" colSpan={1} rowSpan={1}>
                                                        <p className="c8">
                                                            <span className="c10 c38">37</span>
                                                        </p>
                                                    </td>
                                                    <td className="c11" colSpan={1} rowSpan={1}>
                                                        <p className="c8">
                                                            <span className="c10 c38">70</span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr className="c12">
                                                    <td className="c21" colSpan={1} rowSpan={1}>
                                                        <p className="c29">
                                                            <span className="c15 c13">Գեղանկարչություն</span>
                                                        </p>
                                                    </td>
                                                    <td className="c20" colSpan={1} rowSpan={1}>
                                                        <p className="c8">
                                                            <span className="c10 c38">0</span>
                                                        </p>
                                                    </td>
                                                    <td className="c20" colSpan={1} rowSpan={1}>
                                                        <p className="c8">
                                                            <span className="c10 c38">5</span>
                                                        </p>
                                                    </td>
                                                    <td className="c11" colSpan={1} rowSpan={1}>
                                                        <p className="c8">
                                                            <span className="c10 c38">5</span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr className="c52">
                                                    <td className="c21" colSpan={1} rowSpan={1}>
                                                        <p className="c29">
                                                            <span className="c15 c13">* Քանդակագործություն</span>
                                                        </p>
                                                    </td>
                                                    <td className="c20" colSpan={1} rowSpan={1}>
                                                        <p className="c8">
                                                            <span className="c10 c38">10</span>
                                                        </p>
                                                    </td>
                                                    <td className="c20" colSpan={1} rowSpan={1}>
                                                        <p className="c8">
                                                            <span className="c10 c38">9</span>
                                                        </p>
                                                    </td>
                                                    <td className="c11" colSpan={1} rowSpan={1}>
                                                        <p className="c8">
                                                            <span className="c10 c38">19</span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr className="c72">
                                                    <td className="c21" colSpan={1} rowSpan={1}>
                                                        <p className="c29">
                                                            <span className="c15 c13">Քանդակագործություն</span>
                                                        </p>
                                                    </td>
                                                    <td className="c20" colSpan={1} rowSpan={1}>
                                                        <p className="c8">
                                                            <span className="c10 c38">0</span>
                                                        </p>
                                                    </td>
                                                    <td className="c20" colSpan={1} rowSpan={1}>
                                                        <p className="c8">
                                                            <span className="c10 c38">1</span>
                                                        </p>
                                                    </td>
                                                    <td className="c11" colSpan={1} rowSpan={1}>
                                                        <p className="c8">
                                                            <span className="c10 c38">1</span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr className="c43">
                                                    <td className="c21" colSpan={1} rowSpan={1}>
                                                        <p className="c29">
                                                            <span className="c15 c13">*Դիզայն </span>
                                                        </p>
                                                    </td>
                                                    <td className="c20" colSpan={1} rowSpan={1}>
                                                        <p className="c8">
                                                            <span className="c10 c38">33</span>
                                                        </p>
                                                    </td>
                                                    <td className="c20" colSpan={1} rowSpan={1}>
                                                        <p className="c8">
                                                            <span className="c10 c38">37</span>
                                                        </p>
                                                    </td>
                                                    <td className="c11" colSpan={1} rowSpan={1}>
                                                        <p className="c8">
                                                            <span className="c10 c38">70</span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr className="c83">
                                                    <td className="c21" colSpan={1} rowSpan={1}>
                                                        <p className="c29">
                                                            <span className="c15 c13">Դիզայն</span>
                                                        </p>
                                                    </td>
                                                    <td className="c20" colSpan={1} rowSpan={1}>
                                                        <p className="c8">
                                                            <span className="c10 c38">0</span>
                                                        </p>
                                                    </td>
                                                    <td className="c20" colSpan={1} rowSpan={1}>
                                                        <p className="c8">
                                                            <span className="c10 c38">5</span>
                                                        </p>
                                                    </td>
                                                    <td className="c11" colSpan={1} rowSpan={1}>
                                                        <p className="c8">
                                                            <span className="c10 c38">5</span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr className="c50">
                                                    <td className="c21" colSpan={1} rowSpan={1}>
                                                        <p className="c29">
                                                            <span className="c13 c24">*</span>
                                                            <span className="c13">
                                                                «Զարդակիրառական արվեստ և ժողովրդական արհեստներ»
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td className="c20" colSpan={1} rowSpan={1}>
                                                        <p className="c8">
                                                            <span className="c10 c38">11</span>
                                                        </p>
                                                    </td>
                                                    <td className="c20" colSpan={1} rowSpan={1}>
                                                        <p className="c8">
                                                            <span className="c10 c38">9</span>
                                                        </p>
                                                    </td>
                                                    <td className="c11" colSpan={1} rowSpan={1}>
                                                        <p className="c8">
                                                            <span className="c10 c38">20</span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr className="c61">
                                                    <td className="c21" colSpan={1} rowSpan={1}>
                                                        <p className="c29 c35">
                                                            <span className="c49 c48 c38 c24">Ընդամենը</span>
                                                        </p>
                                                    </td>
                                                    <td className="c20" colSpan={1} rowSpan={1}>
                                                        <p className="c8">
                                                            <span className="c25 c38">87</span>
                                                        </p>
                                                    </td>
                                                    <td className="c20" colSpan={1} rowSpan={1}>
                                                        <p className="c8">
                                                            <span className="c25 c38">103</span>
                                                        </p>
                                                    </td>
                                                    <td className="c11" colSpan={1} rowSpan={1}>
                                                        <p className="c8">
                                                            <span className="c25 c38">190</span>
                                                        </p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <p className="c27">
                                            <span className="c15 c25">
                                                *- հիմնական ընդհանուր կրթության հիմքով (9-ամյա)
                                            </span>
                                        </p>
                                        <p className="c27">
                                            <span className="c25 c24">
                                                &nbsp; - միջնակարգ կրթության հիմքով (12-ամյա)
                                            </span>
                                        </p>
                                        <p className="c4">
                                            <span className="c59 c38" />
                                        </p>
                                        <p className="c4">
                                            <span className="c59 c38" />
                                        </p>
                                        <p className="c27">
                                            <span className="c15 c48">ԺԱՄԱՆԱԿԱՑՈՒՅՑ</span>
                                        </p>
                                        <a id="t.5e590465e07f7e2304aee4002df69438ef97dd5f" />
                                        <a id="t.1" />
                                        <table className="c68">
                                            <tbody>
                                                <tr className="c77">
                                                    <td className="c57" colSpan={1} rowSpan={1}>
                                                        <p className="c34">
                                                            <span className="c7">N</span>
                                                        </p>
                                                    </td>
                                                    <td className="c86" colSpan={1} rowSpan={1}>
                                                        <p className="c0">
                                                            <span className="c7">
                                                                Առարկայի &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                                &nbsp; &nbsp; &nbsp; &nbsp;անվանումը
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td className="c9" colSpan={1} rowSpan={1}>
                                                        <p className="c0">
                                                            <span className="c7">
                                                                Քննության անցկացման ձևը &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td className="c84" colSpan={1} rowSpan={1}>
                                                        <p className="c0">
                                                            <span className="c7">Քննության ամսաթիվը, ժամը</span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr className="c80">
                                                    <td className="c57" colSpan={1} rowSpan={1}>
                                                        <p className="c66">
                                                            <span className="c15 c13">1</span>
                                                        </p>
                                                    </td>
                                                    <td className="c14" colSpan={1} rowSpan={1}>
                                                        <p className="c34">
                                                            <span className="c7">Գծանկար </span>
                                                        </p>
                                                    </td>
                                                    <td className="c9" colSpan={1} rowSpan={1}>
                                                        <p className="c34">
                                                            <span className="c7">գրավոր</span>
                                                        </p>
                                                    </td>
                                                    <td className="c3" colSpan={1} rowSpan={1}>
                                                        <p className="c34">
                                                            <span className="c7">
                                                                Օգոստոսի &nbsp; &nbsp;9, 10, 11, ժամը 9:00
                                                            </span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr className="c18">
                                                    <td className="c57" colSpan={1} rowSpan={1}>
                                                        <p className="c66">
                                                            <span className="c15 c13">2</span>
                                                        </p>
                                                    </td>
                                                    <td className="c14" colSpan={1} rowSpan={1}>
                                                        <p className="c34">
                                                            <span className="c7">Գունանկար</span>
                                                        </p>
                                                    </td>
                                                    <td className="c9" colSpan={1} rowSpan={1}>
                                                        <p className="c34">
                                                            <span className="c7">գրավոր</span>
                                                        </p>
                                                    </td>
                                                    <td className="c3" colSpan={1} rowSpan={1}>
                                                        <p className="c42">
                                                            <span className="c7">
                                                                Օգոստոսի &nbsp; &nbsp;13, &nbsp;ժամը 12:30
                                                            </span>
                                                        </p>
                                                        <p className="c42">
                                                            <span className="c7">
                                                                Օգոստոսի &nbsp; &nbsp;14, &nbsp;ժամը 9:00
                                                            </span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr className="c104">
                                                    <td className="c57" colSpan={1} rowSpan={1}>
                                                        <p className="c66">
                                                            <span className="c15 c13">3</span>
                                                        </p>
                                                    </td>
                                                    <td className="c14" colSpan={1} rowSpan={1}>
                                                        <p className="c34">
                                                            <span className="c7">Քանդակ</span>
                                                        </p>
                                                    </td>
                                                    <td className="c9" colSpan={1} rowSpan={1}>
                                                        <p className="c34">
                                                            <span className="c7">գրավոր</span>
                                                        </p>
                                                    </td>
                                                    <td className="c3" colSpan={1} rowSpan={1}>
                                                        <p className="c42">
                                                            <span className="c7">
                                                                Օգոստոսի &nbsp; &nbsp;13, &nbsp;ժամը 12:30
                                                            </span>
                                                        </p>
                                                        <p className="c42">
                                                            <span className="c7">
                                                                Օգոստոսի &nbsp; &nbsp;14, &nbsp;ժամը 9:00
                                                            </span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr className="c62">
                                                    <td className="c57" colSpan={1} rowSpan={1}>
                                                        <p className="c66">
                                                            <span className="c15 c13">4</span>
                                                        </p>
                                                    </td>
                                                    <td className="c14" colSpan={1} rowSpan={1}>
                                                        <p className="c34">
                                                            <span className="c7">Քանդակ - Հորինվածք </span>
                                                        </p>
                                                    </td>
                                                    <td className="c9" colSpan={1} rowSpan={1}>
                                                        <p className="c34">
                                                            <span className="c7">գրավոր</span>
                                                        </p>
                                                    </td>
                                                    <td className="c3" colSpan={1} rowSpan={1}>
                                                        <p className="c34">
                                                            <span className="c7">
                                                                Օգոստոսի &nbsp; &nbsp;16, &nbsp;ժամը 12:30
                                                            </span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr className="c39">
                                                    <td className="c57" colSpan={1} rowSpan={1}>
                                                        <p className="c66">
                                                            <span className="c15 c13">5</span>
                                                        </p>
                                                    </td>
                                                    <td className="c14" colSpan={1} rowSpan={1}>
                                                        <p className="c34">
                                                            <span className="c7">Հորինվածք /կոմպոզիցիա/</span>
                                                        </p>
                                                    </td>
                                                    <td className="c9" colSpan={1} rowSpan={1}>
                                                        <p className="c34">
                                                            <span className="c7">գրավոր</span>
                                                        </p>
                                                    </td>
                                                    <td className="c3" colSpan={1} rowSpan={1}>
                                                        <p className="c34">
                                                            <span className="c7">
                                                                Օգոստոսի &nbsp; &nbsp;16, &nbsp;ժամը 12:30
                                                            </span>
                                                        </p>
                                                        <p className="c34">
                                                            <span className="c7">
                                                                Օգոստոսի &nbsp; &nbsp;17, &nbsp;ժամը 9:00
                                                            </span>
                                                        </p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <p className="c8 c30 c44 c46">
                                            <span className="c15 c13" />
                                        </p>
                                        <p className="c8 c30 c44 c46">
                                            <span className="c15 c13" />
                                        </p>
                                        <p className="c8 c44 c46" id="h.gjdgxs-1">
                                            <span className="c48">ԸՆԴՈՒՆԵԼՈՒԹՅԱՆ ԴԻՄՈՒՄԸ</span>
                                        </p>
                                        <p className="c26">
                                            <span className="c13 c51">2024-2025թթ ուսումնական տարվա համար </span>
                                            <span className="c13">
                                                դիմումները գրանցելու նպատակով անհրաժեշտ է մուտք գործել{" "}
                                            </span>
                                            <span className="c31 c13">
                                                <a
                                                    className="c6"
                                                    href="https://www.google.com/url?q=https://dimord.emis.am/&sa=D&source=editors&ust=1722191177437967&usg=AOvVaw0ftx2VADX-l-U_f96i9PIF"
                                                >
                                                    dimord.emis.am
                                                </a>
                                            </span>
                                            <span className="c7">
                                                &nbsp;համակարգ և հետևել սահմանված կարգին ու պահանջներին: &nbsp;Դիմորդը`
                                                կրթության կառավարման տեղեկատվական համակարգում էլեկտրոնային եղանակով
                                                լրացնում է դիմում հայտը, որն իր մեջ ներառում է անձնական տվյալները (անուն
                                                ազգանուն հայրանուն, ծննդյան օր ամիս տարեթիվ, Էլեկտրոնային հասցե,
                                                հեռախոսահամար), ինչպես նաև Հանրային ծառայության համարանիշը կամ օրենքով
                                                սահմանված կարգով դրանից հրաժարվելու մասին տեղեկանքի համարը և կից
                                                ներբեռնում է նաև`
                                            </span>
                                        </p>
                                        <p className="c26">
                                            <span className="c7">1) լուսանկար,</span>
                                        </p>
                                        <p className="c26">
                                            <span className="c7">
                                                2) կրթության մասին փաստաթղթի (դիպլոմի դեպքում նաև ներդիրի) պատճենը,
                                            </span>
                                        </p>
                                        <p className="c26">
                                            <span className="c7">
                                                3) անձը հաստատող փաստաթղթի պատճենը (անձնագիր, կամ ծննդյան վկայական՝ մինչև
                                                16 տարեկան դիմորդի համար, կամ զինվորական գրքույկ, կամ Հայաստանի
                                                Հանրապետության ոստիկանության բաժինների կողմից տրվող անձը հաստատող
                                                ժամանակավոր փաստաթուղթ, կացության վկայական, փախստականի վկայական),
                                            </span>
                                        </p>
                                        <p className="c26">
                                            <span className="c7">
                                                4) Դիմում հայտ ներկայացրած ուսումնական հաստատության հաշվեհամարին փոխանցած
                                                գումարի անդորրագրի պատճենը:
                                            </span>
                                        </p>
                                        <p className="c26">
                                            <span className="c7">
                                                5) կցագրման վկայականի պատճենը (զորակոչային տարիք ունեցող դիմորդից
                                                ընդունվում են զինհաշվառման փաստաթղթերում տարկետման իրավունք տվող
                                                համապատասխան նշումի կամ չզորակոչվելու պատճառը հաստատող այլ նշումների
                                                առկայության դեպքում, իսկ նախկինում առողջության պատճառով տարկետում
                                                ունեցողներից՝ Հանրապետական զորակոչային հանձնաժողովի նշումների առկայության
                                                դեպքում),
                                            </span>
                                        </p>
                                        <p className="c26">
                                            <span className="c7">
                                                6) ընդունելության արտոնության իրավունքը հաստատող փաստաթղթի պատճենը
                                                (առկայության դեպքում),
                                            </span>
                                        </p>
                                        <p className="c26">
                                            <span className="c7">
                                                7) միջազգային և հանրապետական մրցույթների (օլիմպիադաների, փառատոների)
                                                դիպլոմների պատճենները (առկայության դեպքում),
                                            </span>
                                        </p>
                                        <p className="c26">
                                            <span className="c7">
                                                Ներկայացված կեղծ տեղեկատվության համար դիմորդը ենթակա է օրենքով սահմանված
                                                կարգով պատասխանատվության: Դիմորդի դիմում հայտը հիմնավորմամբ մերժվում է
                                                ընդունող հանձնաժողովի կողմից, եթե.
                                            </span>
                                        </p>
                                        <p className="c26">
                                            <span className="c7">
                                                1) դիմորդը դիմում հայտը և/կամ փաստաթղթերը ներկայացրել է թերի և
                                                թերությունները դիմորդի կողմից չեն վերացվել այն ներկայացնելուց 3
                                                աշխատանքային օրվա ընթացքում, բայց ոչ ուշ քան դիմում հայտերի ընդունման
                                                վերջնաժամկետից 1 օր առաջ,
                                            </span>
                                        </p>
                                        <p className="c26">
                                            <span className="c7">
                                                2) միջնակարգ կրթություն ստացած դիմորդը դիմել է ընդունվելու հիմնական
                                                կրթությամբ հատկացված տեղում կամ հակառակը` հիմնական կրթությամբ դիմորդը`
                                                միջնակարգ կրթությամբ:
                                            </span>
                                        </p>
                                        <p className="c26">
                                            <span className="c7">
                                                &nbsp;Դիմորդը` միջին մասնագիտական կրթական ծրագրով ընդունելության
                                                գործընթացի կազմակերպման և անցկացման համար, Հաստատության հաշվեհամարին
                                                վճարում է 1000 (հազար) ՀՀ դրամ:{" "}
                                            </span>
                                        </p>
                                        <p className="c26">
                                            <span className="c7">
                                                ՀՀ Ֆին. Նախ. Գործառնական վարչություն, Հ/Հ -900018001660, ՀՎՀՀ - 02538414
                                            </span>
                                        </p>
                                        <p className="c26">
                                            <span className="c7">
                                                Երևանի Փ. Թերլեմեզյանի անվան &nbsp;գեղարվեստի պետական քոլեջ
                                            </span>
                                        </p>
                                        <p className="c26">
                                            <span className="c7">
                                                Ազգանուն, անուն, հայրանուն, Նպատակը՝ Ընդունելություն
                                            </span>
                                        </p>
                                        <p className="c26">
                                            <span className="c7">
                                                Անկախ մրցույթին մասնակցելուց և ընդունելության արդյունքից՝ վճարված գումարը
                                                դիմորդին հետ չի վերադարձվում:
                                            </span>
                                        </p>
                                        <p className="c8 c44 c46">
                                            <span className="c60 c48 c38 c24">Ընդունելության արտոնության իրավունք</span>
                                        </p>
                                        <p className="c4">
                                            <span className="c48 c38 c24 c60" />
                                        </p>
                                        <p className="c36">
                                            <span className="c13 c24">
                                                Ընդունելության մրցույթից դուրս, պահուստային տեղերի հաշվին ընդունվում են`
                                                «Առանց ծնողական խնամքի մնացած երեխաների սոցիալական պաշտպանության մասին»
                                                Հայաստանի Հանրապետության օրենքի 7-րդ, «Հայաստանի Հանրապետությունում
                                                հաշմանդամների սոցիալական պաշտպանության մասին» Հայաստանի Հանրապետության
                                                օրենքի 15-րդ,{" "}
                                            </span>
                                            <span className="c13 c44 c24">
                                                «Զինվորական ծառայության և զինծառայողի կարգավիճակի մասին» օրենքի 67-րդ
                                            </span>
                                            <span className="c15 c13">
                                                &nbsp;հոդվածներով, Հայաստանի Հանրապետության կառավարության 1999 թվականի
                                                օգոստոսի 27-ի «Հայաստանի Հանրապետության միջին մասնագիտական պետական
                                                ուսումնական հաստատություններում դիմորդների ընդունելության արտոնությունները
                                                սահմանելու մասին» N 545 որոշման պահանջներին, ինչպես նաև ՀՀ օրենսդրությամբ
                                                սահմանված համապատասխան արտոնություններ ունեցող Դիմորդները:
                                            </span>
                                        </p>
                                        <p className="c26 c30">
                                            <span className="c15 c13" />
                                        </p>
                                        <p className="c36">
                                            <span className="c13 c44 c24">27 օգոստոսի 1999 թվականի&nbsp;N 545 </span>
                                            <span className="c13 c24">
                                                որոշման պահանջներ` «Կրթության մասին» Հայաստանի Հանրապետության օրենքի 15
                                                հոդվածի 4-րդ կետին համապատասխան` Հայաստանի Հանրապետության
                                                կառավարությունը&nbsp;
                                            </span>
                                            <span className="c48 c24 c97">որոշում է.</span>
                                        </p>
                                        <p className="c26">
                                            <span className="c15 c13">
                                                1. Սահմանել միջին մասնագիտական պետական ուսումնական հաստատություններում
                                                դիմորդների ընդունելության հետևյալ արտոնությունները`
                                            </span>
                                        </p>
                                        <p className="c26">
                                            <span className="c15 c13">
                                                ա) քննությունները դրական միավորներով հանձնած դիմորդներից պահուստային
                                                տեղերի հաշվին, ուսումնական հաստատության ընդունող հանձնաժողովի որոշմամբ
                                                մրցույթից դուրս ընդունվում են`
                                            </span>
                                        </p>
                                        <p className="c26">
                                            <span className="c15 c13">
                                                1-ին ու 2-րդ խմբերի և մինչև 16 տարեկան մանկուց հաշմանդամները, որոնց,
                                                բժշկասոցիալական փորձաքննական հանձնաժողովի եզրակացության համաձայն, տվյալ
                                                մասնագիտությամբ սովորելը հակացուցված չէ` միջին մասնագիտական ուսումնական
                                                հաստատությունների դիմորդների ընդունելության` օրենքով սահմանված բժշկական
                                                հակացուցումների ցանկին համապատասխան.
                                            </span>
                                        </p>
                                        <p className="c26">
                                            <span className="c15 c13">
                                                Հայաստանի Հանրապետության պաշտպանության ժամանակ զոհված (մահացած) և 1-ին
                                                խմբի հաշմանդամ դարձած զինծառայողների երեխաները, ժամկետային զինվորական
                                                ծառայության պարտականությունները կատարելիս հաշմանդամ դարձած քաղաքացիները,
                                                համաձայն Հայաստանի Հանրապետության պաշտպանության նախարարության, Հայաստանի
                                                Հանրապետության ներքին գործերի նախարարության, Հայաստանի Հանրապետության
                                                ազգային անվտանգության նախարարության և Հայաստանի Հանրապետության
                                                կառավարությանն առընթեր արտակարգ իրավիճակների վարչության կողմից տրված
                                                վկայականների.
                                            </span>
                                        </p>
                                        <p className="c26">
                                            <span className="c15 c13">
                                                երկկողմանի ծնողազուրկ և մինչև տվյալ ուսումնական տարվա սեպտեմբերի 1-ը 18
                                                տարին չլրացած անձինք:
                                            </span>
                                        </p>
                                        <p className="c26 c30">
                                            <span className="c15 c13" />
                                        </p>
                                        <p className="c26">
                                            <span className="c31 c13 c24">
                                                <a
                                                    className="c6"
                                                    href="https://www.google.com/url?q=https://www.arlis.am/DocumentView.aspx?docID%3D7494&sa=D&source=editors&ust=1722191177440322&usg=AOvVaw2odSFvUzlStS0WiqKUVvMk"
                                                >
                                                    https://www.arlis.am/DocumentView.aspx?docID=7494
                                                </a>
                                            </span>
                                        </p>
                                        <p className="c26 c30">
                                            <span className="c15 c13" />
                                        </p>
                                        <p className="c36" id="h.30j0zll-2">
                                            <span className="c15 c13">ՍԻՐԵԼԻ ԴԻՄՈՐԴ,</span>
                                        </p>
                                        <p className="c36 c30">
                                            <span className="c15 c13" />
                                        </p>
                                        <p className="c36">
                                            <span className="c15 c13">
                                                2024թ.-ի օգոստոսի 9-ին, ժամը 8:30 հանդիպում ենք քոլեջում` ք. Երևան,
                                                Արշակունյաց 39: Ձեզ հետ բերում և ներկայացնում եք.
                                            </span>
                                        </p>
                                        <ol className="c53 lst-kix_9le6rbncmbg4-0 start" start={1}>
                                            <li className="c29 c40 li-bullet-0">
                                                <span className="c31">
                                                    <a
                                                        className="c6"
                                                        href="https://www.google.com/url?q=http://www.dimord.emis.am&sa=D&source=editors&ust=1722191177440962&usg=AOvVaw11-7jwIL5w4Aik23PC5ASL"
                                                    >
                                                        www.dimord.emis.am
                                                    </a>
                                                </span>
                                                <span>&nbsp;</span>
                                                <span className="c13 c24">&nbsp;</span>
                                                <span className="c13 c19">&nbsp;</span>
                                                <span className="c15 c13">
                                                    -ի կողմից դիմումատուի անհատական էջում ձևավորված քննության մասնակցության
                                                    թերթիկը` թղթային տարբերակով,{" "}
                                                </span>
                                            </li>
                                            <li className="c29 c40 li-bullet-0">
                                                <span className="c15 c13">
                                                    անձնագրի (մինչև 16 տարեկան դիմորդը` ծննդյան վկայական, եթե անձնագիր
                                                    չունի) բնօրինակը և պատճենը,
                                                </span>
                                            </li>
                                            <li className="c29 c40 li-bullet-0">
                                                <span className="c15 c13">
                                                    հանրային ծառայությունների համարանիշի` սոցիալական քարտի պատճենը,
                                                </span>
                                            </li>
                                            <li className="c29 c40 li-bullet-0">
                                                <span className="c15 c13">լուսանկար` 4 հատ,</span>
                                            </li>
                                            <li className="c29 c40 li-bullet-0">
                                                <span className="c15 c13">
                                                    կրթության մասին փաստաթղթի (դիպլոմի դեպքում նաև ներդիրի) բնօրինակը և
                                                    պատճենը,
                                                </span>
                                            </li>
                                            <li className="c29 c40 li-bullet-0">
                                                <span className="c15 c13">
                                                    քոլեջի հաշվեհամարին փոխանցած` 1000 դրամ, գումարի անդորրագիրը,
                                                </span>
                                            </li>
                                            <li className="c29 c40 li-bullet-0">
                                                <span className="c15 c13">
                                                    Երկկողմանի ծնողազուրկ, 1-ին, 2-րդ խմբերի և մանկուց հաշմանդամ, զոհված
                                                    զինծառայողի երեխայի կարգավիճակ ունենալու հիմքով արտոնության իրավունքը
                                                    հաստատող փաստաթղթի պատճենը (առկայության դեպքում):
                                                </span>
                                            </li>
                                        </ol>
                                        <p className="c29 c30 c44">
                                            <span className="c15 c13" />
                                        </p>
                                        <p className="c29 c44">
                                            <span className="c15 c13">
                                                «Գծանկար» քննության համար (օգոստոսի 9,10,11) &nbsp;անհրաժեշտ է ունենալ
                                                &nbsp;50 x 70 չափսերով տախտակ (доска), մատիտներ, ռետին, սրիչ, կոճգամ
                                                (кнопка): Քանոնի կիրառությունն արգելվում է:
                                            </span>
                                        </p>
                                        <p className="c29 c44">
                                            <span className="c15 c13">
                                                «Գունանկար», «Քանդակ» քննությունների համար (օգոստոսի 13,14) անհրաժեշտ է
                                                ունենալ &nbsp;50 x 70 չափսերով տախտակ (доска), մատիտներ, ջրաներկ, ռետին,
                                                սրիչ, կոճգամ (кнопка), կավով և պլաստիլինով աշխատելու համար նախատեսված
                                                գործիքներ: Քանոնի կիրառությունն արգելվում է:
                                            </span>
                                        </p>
                                        <p className="c17">
                                            <span className="c15 c13">
                                                «Կոմպոզիցիա» (օգոստոսի 16,17) անհրաժեշտ է ունենալ 50 x 70 չափսերով տախտակ
                                                (доска), գուաշ, ջրաներկ, մատիտներ, տեմպերա, կավով և պլաստիլինով աշխատելու
                                                համար նախատեսված գործիքներ և այլն, կարող է օգտագործվել նաև քանոն, կարկին:{" "}
                                            </span>
                                        </p>
                                        <p className="c17">
                                            <span className="c15 c13">A2 ֆորմատի թուղթը տրամադրում է քոլեջը:</span>
                                        </p>
                                        <p className="c17">
                                            <span className="c15 c13">
                                                Արգելվում է քննասենյակ բերել բջջային հեռախոս, ականջակալներ:{" "}
                                            </span>
                                        </p>
                                        <p className="c17">
                                            <span className="c15 c13">
                                                Դիմորդն իր հետ կարող է բերել ջուր, թեթև սնունդ:
                                            </span>
                                        </p>
                                        <p className="c17">
                                            <span className="c15 c13">Մաղթում ենք քննությունների հաջող ընթացք:</span>
                                        </p>
                                        <p className="c30 c76">
                                            <span className="c15 c13" />
                                        </p>
                                        <p className="c76 c30">
                                            <span className="c15 c13" />
                                        </p>
                                        <p className="c8 c44 c46">
                                            <span className="c48 c24">ԸՆԴՈՒՆԵԼՈՒԹՅԱՆ ՄՐՑՈՒՅԹԸ</span>
                                        </p>
                                        <p className="c27">
                                            <span className="c15 c13">&nbsp;</span>
                                        </p>
                                        <p className="c27">
                                            <span className="c15 c13">
                                                ԿՏԱԿ-ի կողմից դիմումատուի անհատական էջում ձևավորվում է քննության
                                                մասնակցության թերթիկը, որում նշվում է քննության օրվա, ժամի և քննության
                                                անցկացման վայրի մասին տեղեկատվությունը, որը դիմորդը թղթային տարբերակով
                                                ներկայացնում է քննական հանձնաժողովին` քննասենյակ մտնելիս:
                                            </span>
                                        </p>
                                        <p className="c27">
                                            <span className="c15 c13">
                                                Դիմորդն ընդունելության քննության է ներկայանում անձնագրով (մինչև 16 տարեկան
                                                դիմորդը` ծննդյան վկայականով, եթե անձնագիր չունի), կամ զինվորական
                                                գրքույկով, կամ ՀՀ ոստիկանության բաժինների կողմից տրված համապատասխան ձևով և
                                                քննական թերթիկով, որոնք ստուգվում են ընդունող հանձնաժողովի կողմից՝
                                                Հաստատություն և քննասենյակ մտնելիս: Քննությունների ժամանակացույցով
                                                նախատեսված օրն անհարգելի պատճառով քննությանը չներկայացած դիմորդին չի
                                                թույլատրվում մասնակցել հետագա քննություններին: Հարգելի պատճառով քննությանը
                                                չներկայացած դիմորդին, ընդունող հանձնաժողովի որոշմամբ, հնարավորություն է
                                                տրվում այն հանձնել, եթե տվյալ առարկայի քննությունները Հաստատությունում չեն
                                                ավարտվել:
                                            </span>
                                        </p>
                                        <p className="c4">
                                            <span className="c13 c15" />
                                        </p>
                                        <p className="c27">
                                            <span className="c15 c13">
                                                Քննությունների համար նախատեսվում է հետևյալ տևողությունը.
                                            </span>
                                        </p>
                                        <p className="c27">
                                            <span className="c15 c13">
                                                ա) գծանկար` մինչև 540 րոպե (3 օր` յուրաքանչյուր օրը 4 ակադեմիական ժամ).
                                            </span>
                                        </p>
                                        <p className="c27">
                                            <span className="c15 c13">
                                                բ) գունանկար` մինչև 360 րոպե (2 օր` յուրաքանչյուր օրը 4 ակադեմիական ժամ).
                                            </span>
                                        </p>
                                        <p className="c27">
                                            <span className="c15 c13">
                                                գ) կոմպոզիցիա` մինչև 450 րոպե (2 օր` յուրաքանչյուր օրը 5 ակադեմիական ժամ).
                                            </span>
                                        </p>
                                        <p className="c27">
                                            <span className="c15 c13">
                                                դ) քանդակ` մինչև 360 րոպե (2 օր` յուրաքանչյուր օրը 4 ակադեմիական ժամ).
                                            </span>
                                        </p>
                                        <p className="c27">
                                            <span className="c15 c13">
                                                ե) քանդակի կոմպոզիցիա` մինչև 225 րոպե (1 օր` 5 ակադեմիական ժամ):
                                            </span>
                                        </p>
                                        <p className="c27">
                                            <span className="c15 c13">
                                                Ընդունելության քննությունները գնահատվում են 20 բալային համակարգով: Դրական
                                                են համարվում 8 և ավելի միավորները: 8 միավորից ցածր ստացած դիմորդը չի
                                                մասնակցում մրցույթին:
                                            </span>
                                        </p>
                                        <p className="c27">
                                            <span className="c15 c13">
                                                &nbsp;Հաստատության ընդունող հանձնաժողովի կողմից հաստատված քննությունների
                                                արդյունքների ցուցակները փակցվում են ի տես բոլորի ոչ ուշ, քան տվյալ
                                                քննության հաջորդ օրվա ժամը 12-ը:
                                            </span>
                                        </p>
                                        <p className="c27">
                                            <span className="c15 c13">
                                                &nbsp;Գրավոր քննության գնահատականը դիմորդը կարող է գրավոր բողոքարկել
                                                ընդունող հանձնաժողովի նախագահին՝ քննությունների արդյունքները
                                                հրապարակվելուց հետո` մինչև հաջորդ օրվա ժամը 12-ը: Դիմումը ստանալուց հետո 1
                                                աշխատանքային օրվա ընթացքում քննական հանձնաժողովը`
                                            </span>
                                        </p>
                                        <p className="c27">
                                            <span className="c15 c13">
                                                1) դիմորդի ներկայությամբ վերստուգում, քննարկում է բողոքարկված աշխատանքը.
                                            </span>
                                        </p>
                                        <p className="c27">
                                            <span className="c15 c13">
                                                2) պատճառաբանված մերժում է փոփոխել կամ փոփոխում է գնահատականը` այդ մասին
                                                տեղյակ պահելով դիմորդին:
                                            </span>
                                        </p>
                                        <p className="c27">
                                            <span className="c15 c13">&nbsp;</span>
                                        </p>
                                        <p className="c27">
                                            <span className="c15 c13">
                                                Ընդունելության մրցույթն անցկացվում է Լիազորված մարմնի կողմից ըստ
                                                մասնագիտությունների հատկացված անվճար և վճարովի ուսուցման տեղերում` հետևյալ
                                                սկզբունքով.
                                            </span>
                                        </p>
                                        <p className="c4">
                                            <span className="c15 c13" />
                                        </p>
                                        <p className="c26">
                                            <span className="c13 c24">
                                                Ոլորտային մասնագիտություններով` բացառությամբ Աղյուսակ 1-ի «0215
                                                Երաժշտություն և կատարողական արվեստ» գլխի,{" "}
                                            </span>
                                            <span className="c13 c24 c93">«</span>
                                            <span className="c13 c44 c24">Գեղանկարչություն», «Քանդակագործություն»</span>
                                            <span className="c13 c93 c24">&nbsp;</span>
                                            <span className="c15 c13">
                                                &nbsp;և սպորտի մասնագիտությունների (այսուհետ` արվեստի և սպորտ), անվճար
                                                տեղում առաջին հերթին ընդունվում է հիմնական կրթության վկայականի, միջնակարգ
                                                կրթության ատեստատի միավորների (այդ թվում` քննական), նախնական մասնագիտական
                                                (արհեստագործական), միջին մասնագիտական, բարձրագույն մասնագիտական կրթության
                                                դիպլոմի ներդիրի գնահատականների միջին թվաբանականի և սույն կարգի Աղյուսակ
                                                1-ի տվյալ մասնագիտության քննության/քննությունների գնահատականի(ների) ու
                                                տվյալ մասնագիտության համար ընդունելության մրցութային առարկաների
                                                գնահատականների/միավորների ընդհանուր առավելագույն գումար ունեցող դիմորդը,
                                                իսկ արվեստի և սպորտի մասնագիտություններով անվճար տեղում առաջին հերթին
                                                ընդունվում է սույն կարգի Աղյուսակ 1-ի տվյալ մասնագիտության
                                                քննության/քննությունների առավել բարձր գնահատական(ներ) ստացած դիմորդը:
                                            </span>
                                        </p>
                                        <p className="c36 c30">
                                            <span className="c15 c13" />
                                        </p>
                                        <p className="c27">
                                            <span className="c15 c13">
                                                Մրցութային հավասար միավորներ ունենալու դեպքում ըստ սահմանված
                                                հերթականության առավելությունը տրվում է՝
                                            </span>
                                        </p>
                                        <p className="c27">
                                            <span className="c15 c13">
                                                ա. մեդալակիր-դիմորդին, իսկ երաժշտական և սպորտի մասնագիտությունների գծով
                                                հիմնական կրթության վկայականի, միջնակարգ կրթության ատեստատի միավորների (այդ
                                                թվում` քննական), նախնական մասնագիտական (արհեստագործական), միջին
                                                մասնագիտական, բարձրագույն մասնագիտական կրթության դիպլոմի ներդիրի
                                                գնահատականների միջին թվաբանականի և սույն կարգի ցանկերով տվյալ
                                                մասնագիտության քննության/քննությունների գնահատականի(ների) ու տվյալ
                                                մասնագիտության համար ընդունելության մրցութային առարկաների
                                                գնահատականների/միավորների ընդհանուր առավելագույն գումար ունեցող դիմորդին.
                                            </span>
                                        </p>
                                        <p className="c27">
                                            <span className="c15 c13">
                                                Վճարովի ուսուցման տեղերը համալրվում են անվճար ուսուցման տեղերը
                                                համալրվելուց հետո` նույն սկզբունքով:
                                            </span>
                                        </p>
                                        <p className="c26 c30">
                                            <span className="c15 c13" />
                                        </p>
                                        <p className="c8 c30 c44 c46">
                                            <span className="c15 c13" />
                                        </p>
                                        <p className="c29" id="h.gjdgxs-3">
                                            <span className="c7">
                                                Yerevan State College of Fine Arts after P. Terlemzyan of the Ministry of
                                                ESCS of RA announces admission for the 2024-2025 academic year in the
                                                following specialties:
                                            </span>
                                        </p>
                                        <p className="c29 c30">
                                            <span className="c7" />
                                        </p>
                                        <p className="c29">
                                            <span className="c7">
                                                0213.02.5 "Painting", qualification: painter-teacher, duration of study: 5
                                                years,
                                            </span>
                                        </p>
                                        <p className="c29">
                                            <span className="c7">
                                                0212.01.5 "Design", qualification: designer, study period: 4 years,
                                            </span>
                                        </p>
                                        <p className="c29">
                                            <span className="c7">
                                                0213.03.5 "Sculpture", qualification - sculptor-teacher, duration of study
                                                - 5 years,
                                            </span>
                                        </p>
                                        <p className="c29">
                                            <span className="c7">
                                                0214.01.5 "Decorative arts and folk crafts", qualification
                                                -painter-designer, study period: 4 years.
                                            </span>
                                        </p>
                                        <p className="c29">
                                            <span className="c7">
                                                Type of the education: stationary. The basis of education: at least
                                                primary or secondary.
                                            </span>
                                        </p>
                                        <p className="c29">
                                            <span className="c7">
                                                The applicant can apply to one educational institution for one specialty -
                                                qualification, specialization on the basis of state and paid education.
                                            </span>
                                        </p>
                                        <p className="c58">
                                            <span className="c13">
                                                The tuition fee for all the specialties of the college{" "}
                                            </span>
                                            <span className="c13 c91">
                                                for the citizens od the REPUBLIC OF ARMENIA and Armenians registered and
                                                residing in Samtskhe-Javakhk and Kvemo-Kartli provinces of Georgia,
                                                persons recognized as refugees and granted asylum in the Republic of
                                                Armenia, as well as for foreign citizens of Armenian origin, whose
                                                permanent residence in a foreign country has created an emergency
                                                situation threatening their life or health,{" "}
                                            </span>
                                            <span className="c7">
                                                is 350,000 / three hundred fifty thousand / AMD. &nbsp;For the foreign
                                                citizens the tuition fee for all the specialties of the college is 550,000
                                                / five hundred fifty thousand / AMD.
                                            </span>
                                        </p>
                                        <p className="c29">
                                            <span className="c7">
                                                The documents for participation in the competition are accepted
                                                electronically from the date of publication of the announcement on the
                                                admission requirements in the Education Management Information System
                                                until August 8, inclusive, at 18:00, and the exams are held from August 9
                                                to 17.
                                            </span>
                                        </p>
                                        <p className="c29">
                                            <span className="c7">
                                                Applicants' application forms in the Education Management Information
                                                System may be changed by the end of the application deadline.
                                            </span>
                                        </p>
                                        <p className="c29">
                                            <span className="c13">
                                                In order to register applications for the 2024-2025 academic year, it is
                                                necessary to log in to the &nbsp;
                                            </span>
                                            <span className="c13 c31">
                                                <a
                                                    className="c6"
                                                    href="https://www.google.com/url?q=http://www.dimord.emis.am&sa=D&source=editors&ust=1722191177444372&usg=AOvVaw3j4rxxGjm4sjzlkGL5l7d4"
                                                >
                                                    www.dimord.emis.am
                                                </a>
                                            </span>
                                            <span className="c7">
                                                &nbsp; &nbsp;system and follow the established procedure and requirements.
                                                The applicant fills in the application form electronically in the
                                                education management information system, which includes personal data
                                                (name, surname, father’s name, date of birth, e-mail address, telephone
                                                number), as well as the Public Service number or the number of the
                                                certificate of refusal and also you should download
                                            </span>
                                        </p>
                                        <p className="c29">
                                            <span className="c7">1) photo,</span>
                                        </p>
                                        <p className="c29">
                                            <span className="c7">
                                                2) a copy of the document on education (insert in case of diploma);
                                            </span>
                                        </p>
                                        <p className="c29">
                                            <span className="c7">
                                                3) a copy of the identity document (passport, or birth certificate for an
                                                applicant under 16 years of age, or a military book, or a temporary
                                                identity document issued by the police departments of the Republic of
                                                Armenia, residence certificate, refugee certificate);
                                            </span>
                                        </p>
                                        <p className="c29">
                                            <span className="c7">
                                                4) A copy of the receipt of the amount transferred to the account of the
                                                educational institution that submitted the application.
                                            </span>
                                        </p>
                                        <p className="c29">
                                            <span className="c7">
                                                The applicant pays 1000 (thousand) AMD to the account of the Institution
                                                for organizing and conducting the admission process according to the
                                                secondary professional educational program.
                                            </span>
                                        </p>
                                        <p className="c29">
                                            <span className="c7">
                                                RA Min. Fin. &nbsp;Operations Department, N 900018001660, Tax code:
                                                02538414
                                            </span>
                                        </p>
                                        <p className="c29">
                                            <span className="c7">
                                                Yerevan State College of Fine Arts after P. Terlemzyan
                                            </span>
                                        </p>
                                        <p className="c29">
                                            <span className="c7">
                                                Last name, first name, patronymic, Purpose: Admission
                                            </span>
                                        </p>
                                        <p className="c29">
                                            <span className="c7">
                                                Regardless of the participation in the competition the result of the
                                                admission, the paid amount will not be returned to the applicant.
                                            </span>
                                        </p>
                                        <p className="c44 c102" id="h.30j0zll-4">
                                            <span className="c15 c13">Admission places by specialties. </span>
                                        </p>
                                        <a id="t.6cbcc2c650142ed4494af4dad8b15cc3daef02b2" />
                                        <a id="t.2" />
                                        <table className="c67">
                                            <tbody>
                                                <tr className="c101">
                                                    <td className="c63" colSpan={1} rowSpan={2}>
                                                        <p className="c8">
                                                            <span className="c15 c13">PROFESSION</span>
                                                        </p>
                                                    </td>
                                                    <td className="c74" colSpan={3} rowSpan={1}>
                                                        <p className="c8">
                                                            <span className="c15 c13">Number of admission places</span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr className="c94">
                                                    <td className="c20" colSpan={1} rowSpan={1}>
                                                        <p className="c8">
                                                            <span className="c15 c13">free tuition</span>
                                                        </p>
                                                    </td>
                                                    <td className="c20" colSpan={1} rowSpan={1}>
                                                        <p className="c8">
                                                            <span className="c15 c13">paid tuition</span>
                                                        </p>
                                                    </td>
                                                    <td className="c11" colSpan={1} rowSpan={1}>
                                                        <p className="c8">
                                                            <span className="c15 c13">total</span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr className="c43">
                                                    <td className="c32" colSpan={1} rowSpan={1}>
                                                        <p className="c29">
                                                            <span className="c15 c13">* Painting</span>
                                                        </p>
                                                    </td>
                                                    <td className="c20" colSpan={1} rowSpan={1}>
                                                        <p className="c8">
                                                            <span className="c10">33</span>
                                                        </p>
                                                    </td>
                                                    <td className="c20" colSpan={1} rowSpan={1}>
                                                        <p className="c8">
                                                            <span className="c10">37</span>
                                                        </p>
                                                    </td>
                                                    <td className="c11" colSpan={1} rowSpan={1}>
                                                        <p className="c8">
                                                            <span className="c10">70</span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr className="c12">
                                                    <td className="c32" colSpan={1} rowSpan={1}>
                                                        <p className="c29">
                                                            <span className="c15 c13">Painting</span>
                                                        </p>
                                                    </td>
                                                    <td className="c20" colSpan={1} rowSpan={1}>
                                                        <p className="c8">
                                                            <span className="c10">0</span>
                                                        </p>
                                                    </td>
                                                    <td className="c20" colSpan={1} rowSpan={1}>
                                                        <p className="c8">
                                                            <span className="c10">5</span>
                                                        </p>
                                                    </td>
                                                    <td className="c11" colSpan={1} rowSpan={1}>
                                                        <p className="c8">
                                                            <span className="c10">5</span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr className="c52">
                                                    <td className="c32" colSpan={1} rowSpan={1}>
                                                        <p className="c29">
                                                            <span className="c15 c13">* Sculpture</span>
                                                        </p>
                                                    </td>
                                                    <td className="c20" colSpan={1} rowSpan={1}>
                                                        <p className="c8">
                                                            <span className="c10">10</span>
                                                        </p>
                                                    </td>
                                                    <td className="c20" colSpan={1} rowSpan={1}>
                                                        <p className="c8">
                                                            <span className="c10">9</span>
                                                        </p>
                                                    </td>
                                                    <td className="c11" colSpan={1} rowSpan={1}>
                                                        <p className="c8">
                                                            <span className="c10">19</span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr className="c72">
                                                    <td className="c32" colSpan={1} rowSpan={1}>
                                                        <p className="c29">
                                                            <span className="c15 c13">Sculpture</span>
                                                        </p>
                                                    </td>
                                                    <td className="c20" colSpan={1} rowSpan={1}>
                                                        <p className="c8">
                                                            <span className="c10">0</span>
                                                        </p>
                                                    </td>
                                                    <td className="c20" colSpan={1} rowSpan={1}>
                                                        <p className="c8">
                                                            <span className="c10">1</span>
                                                        </p>
                                                    </td>
                                                    <td className="c11" colSpan={1} rowSpan={1}>
                                                        <p className="c8">
                                                            <span className="c10">1</span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr className="c43">
                                                    <td className="c32" colSpan={1} rowSpan={1}>
                                                        <p className="c29">
                                                            <span className="c15 c13">*Design</span>
                                                        </p>
                                                    </td>
                                                    <td className="c20" colSpan={1} rowSpan={1}>
                                                        <p className="c8">
                                                            <span className="c10">33</span>
                                                        </p>
                                                    </td>
                                                    <td className="c20" colSpan={1} rowSpan={1}>
                                                        <p className="c8">
                                                            <span className="c10">37</span>
                                                        </p>
                                                    </td>
                                                    <td className="c11" colSpan={1} rowSpan={1}>
                                                        <p className="c8">
                                                            <span className="c10">70</span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr className="c83">
                                                    <td className="c32" colSpan={1} rowSpan={1}>
                                                        <p className="c29">
                                                            <span className="c15 c13">Design</span>
                                                        </p>
                                                    </td>
                                                    <td className="c20" colSpan={1} rowSpan={1}>
                                                        <p className="c8">
                                                            <span className="c10">0</span>
                                                        </p>
                                                    </td>
                                                    <td className="c20" colSpan={1} rowSpan={1}>
                                                        <p className="c8">
                                                            <span className="c10">5</span>
                                                        </p>
                                                    </td>
                                                    <td className="c11" colSpan={1} rowSpan={1}>
                                                        <p className="c8">
                                                            <span className="c10">5</span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr className="c50">
                                                    <td className="c32" colSpan={1} rowSpan={1}>
                                                        <h1 className="c33">
                                                            <span className="c15 c13">*Decorative arts and folk crafts</span>
                                                        </h1>
                                                    </td>
                                                    <td className="c20" colSpan={1} rowSpan={1}>
                                                        <p className="c8">
                                                            <span className="c16 c10">11</span>
                                                        </p>
                                                    </td>
                                                    <td className="c20" colSpan={1} rowSpan={1}>
                                                        <p className="c8">
                                                            <span className="c10 c16">9</span>
                                                        </p>
                                                    </td>
                                                    <td className="c11" colSpan={1} rowSpan={1}>
                                                        <p className="c8">
                                                            <span className="c16 c10">20</span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr className="c50">
                                                    <td className="c32" colSpan={1} rowSpan={1}>
                                                        <p className="c29 c103">
                                                            <span className="c13 c38 c24 c49">TOTAL</span>
                                                        </p>
                                                    </td>
                                                    <td className="c20" colSpan={1} rowSpan={1}>
                                                        <p className="c8">
                                                            <span className="c25">87</span>
                                                        </p>
                                                    </td>
                                                    <td className="c20" colSpan={1} rowSpan={1}>
                                                        <p className="c8">
                                                            <span className="c25">103</span>
                                                        </p>
                                                    </td>
                                                    <td className="c11" colSpan={1} rowSpan={1}>
                                                        <p className="c8">
                                                            <span className="c25">190</span>
                                                        </p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <p className="c27">
                                            <span className="c15 c13">*- with basic general education</span>
                                        </p>
                                        <p className="c4">
                                            <span className="c15 c13" />
                                        </p>
                                        <p className="c4">
                                            <span className="c15 c13" />
                                        </p>
                                        <p className="c27">
                                            <span className="c15 c13">TIMETABLE</span>
                                        </p>
                                        <p className="c4">
                                            <span className="c15 c13" />
                                        </p>
                                        <a id="t.4994960698cf5028164ac00da69a8c7190addfc2" />
                                        <a id="t.3" />
                                        <table className="c68">
                                            <tbody>
                                                <tr className="c88">
                                                    <td className="c57" colSpan={1} rowSpan={1}>
                                                        <p className="c29">
                                                            <span className="c15 c13">N</span>
                                                        </p>
                                                    </td>
                                                    <td className="c86" colSpan={1} rowSpan={1}>
                                                        <p className="c8">
                                                            <span className="c15 c13">Subject</span>
                                                        </p>
                                                    </td>
                                                    <td className="c84" colSpan={1} rowSpan={1}>
                                                        <p className="c8">
                                                            <span className="c15 c13">Exam Date</span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr className="c100">
                                                    <td className="c57" colSpan={1} rowSpan={1}>
                                                        <p className="c65">
                                                            <span className="c15 c13">1</span>
                                                        </p>
                                                    </td>
                                                    <td className="c14" colSpan={1} rowSpan={1}>
                                                        <p className="c29">
                                                            <span className="c15 c13">Drawing</span>
                                                        </p>
                                                    </td>
                                                    <td className="c3" colSpan={1} rowSpan={1}>
                                                        <p className="c29">
                                                            <span className="c15 c13">August 9, 10, 11 , 9:00</span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr className="c18">
                                                    <td className="c57" colSpan={1} rowSpan={1}>
                                                        <p className="c65">
                                                            <span className="c15 c13">2</span>
                                                        </p>
                                                    </td>
                                                    <td className="c14" colSpan={1} rowSpan={1}>
                                                        <p className="c29">
                                                            <span className="c15 c13">Painting</span>
                                                        </p>
                                                    </td>
                                                    <td className="c3" colSpan={1} rowSpan={1}>
                                                        <p className="c34">
                                                            <span className="c13 c24">August </span>
                                                            <span className="c7">13, &nbsp;12:30</span>
                                                        </p>
                                                        <p className="c29">
                                                            <span className="c13 c24">August</span>
                                                            <span className="c13">&nbsp;14 , &nbsp;9:00</span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr className="c62">
                                                    <td className="c57" colSpan={1} rowSpan={1}>
                                                        <p className="c65">
                                                            <span className="c15 c13">3</span>
                                                        </p>
                                                    </td>
                                                    <td className="c14" colSpan={1} rowSpan={1}>
                                                        <p className="c29">
                                                            <span className="c15 c13">Sculpture</span>
                                                        </p>
                                                    </td>
                                                    <td className="c3" colSpan={1} rowSpan={1}>
                                                        <p className="c34">
                                                            <span className="c13 c24">August </span>
                                                            <span className="c7">13, &nbsp;12:30</span>
                                                        </p>
                                                        <p className="c29">
                                                            <span className="c13 c24">August</span>
                                                            <span className="c13">&nbsp;14 , &nbsp;9:00</span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr className="c62">
                                                    <td className="c57" colSpan={1} rowSpan={1}>
                                                        <p className="c65">
                                                            <span className="c15 c13">4</span>
                                                        </p>
                                                    </td>
                                                    <td className="c14" colSpan={1} rowSpan={1}>
                                                        <p className="c29">
                                                            <span className="c15 c13">Sculpture - Composition</span>
                                                        </p>
                                                    </td>
                                                    <td className="c3" colSpan={1} rowSpan={1}>
                                                        <p className="c34">
                                                            <span className="c13 c24">August </span>
                                                            <span className="c7">16, &nbsp;12:30</span>
                                                        </p>
                                                        <p className="c29 c30">
                                                            <span className="c15 c13" />
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr className="c62">
                                                    <td className="c57" colSpan={1} rowSpan={1}>
                                                        <p className="c65">
                                                            <span className="c15 c13">5</span>
                                                        </p>
                                                    </td>
                                                    <td className="c14" colSpan={1} rowSpan={1}>
                                                        <p className="c29">
                                                            <span className="c15 c13">Composition</span>
                                                        </p>
                                                    </td>
                                                    <td className="c3" colSpan={1} rowSpan={1}>
                                                        <p className="c34">
                                                            <span className="c13 c24">August </span>
                                                            <span className="c7">16, &nbsp;12:30</span>
                                                        </p>
                                                        <p className="c34">
                                                            <span className="c13 c24">August</span>
                                                            <span className="c13">&nbsp;17 , &nbsp;9:00</span>
                                                        </p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <p className="c4">
                                            <span className="c15 c13" />
                                        </p>
                                        <p className="c4">
                                            <span className="c15 c13" />
                                        </p>
                                        <p className="c4">
                                            <span className="c15 c13" />
                                        </p>
                                        <p className="c4">
                                            <span className="c15 c13" />
                                        </p>
                                        <p className="c29 c30">
                                            <span className="c15 c13" />
                                        </p>
                                        <p className="c29 c30">
                                            <span className="c15 c13" />
                                        </p>
                                        <p className="c29 c30">
                                            <span className="c15 c13" />
                                        </p>
                                        <p className="c29 c30">
                                            <span className="c15 c13" />
                                        </p>
                                        <p className="c29 c30">
                                            <span className="c15 c13" />
                                        </p>
                                        <p className="c29 c30">
                                            <span className="c15 c13" />
                                        </p>
                                        <p className="c29 c30">
                                            <span className="c15 c13" />
                                        </p>
                                        <p className="c29 c30">
                                            <span className="c15 c13" />
                                        </p>
                                        <p className="c29 c30">
                                            <span className="c15 c13" />
                                        </p>
                                        <p className="c29 c30">
                                            <span className="c15 c13" />
                                        </p>
                                        <p className="c29 c30">
                                            <span className="c15 c13" />
                                        </p>
                                        <p className="c29 c30">
                                            <span className="c15 c13" />
                                        </p>
                                        <p className="c42" id="h.gjdgxs-5">
                                            <span className="c13">
                                                Ереванский государственный художественный колледж имени{" "}
                                            </span>
                                            <span className="c70 c13 c44">Ф</span>
                                            <span className="c7">
                                                . Терлемезяна, МОНКС &nbsp;РА, объявляет набор на 2024-2025 учебный год по
                                                следующим специальностям:
                                            </span>
                                        </p>
                                        <p className="c29">
                                            <span className="c7">
                                                0213.02.5 «Живопись», квалификация: художник-педагог, срок обучения: 5
                                                лет,
                                            </span>
                                        </p>
                                        <p className="c29">
                                            <span className="c7">
                                                0212.01.5 «Дизайн», квалификация: дизайнер, срок обучения: 4 года,
                                            </span>
                                        </p>
                                        <p className="c29">
                                            <span className="c7">
                                                0213.03.5 «Скульптура», квалификация - скульптор-педагог, срок обучения -
                                                5 лет,
                                            </span>
                                        </p>
                                        <h1 className="c44 c89" id="h.142yncrshq17">
                                            <span className="c7">
                                                0212.01.5 «Декоративно-прикладное искусство и народные промыслы»,
                                                квалификация: художник-оформитель, срок обучения: 4 года
                                            </span>
                                        </h1>
                                        <p className="c29">
                                            <span className="c7">
                                                Способ обучения: стационарный. Основа образования: не ниже начального или
                                                среднего.
                                            </span>
                                        </p>
                                        <p className="c37">
                                            <span className="c13">
                                                Поступающий может подать заявку в одно учебное заведение на одну
                                                специальность - квалификацию, специализацию на базе бесплатного и платного
                                                обучения.{" "}
                                            </span>
                                            <span className="c13 c91">
                                                Стоимость обучения по всем специальностям колледжа для граждан РЕСПУБЛИКИ
                                                АРМЕНИЯ и армян, зарегистрированных и проживающих в Самцхе-Джавахкской и
                                                Квемо-Картлийских провинциях Грузии, лиц, признанных беженцами и
                                                получивших убежище в Республике Армения, а также для иностранных граждан
                                                армянского происхождения, постоянное проживание которых в иностранном
                                                государстве создало чрезвычайную ситуацию, угрожающую их жизни или
                                                здоровью, - 350 000/триста пятьдесят тысяч/драмов РА. &nbsp;Для
                                                иностранных граждан стоимость обучения по всем специальностям колледжа
                                                составляет 550 000/пятьсот пятьдесят тысяч/ драмов РА.
                                            </span>
                                            <span className="c7">
                                                &nbsp;Заявка абитуриента на участие в конкурсе и документы принимаются в
                                                электронном виде с даты публикации объявления о вступительных требованиях
                                                в Информационной системе управления образованием до 8 августа
                                                включительно, 18:00, а экзамены проводятся с 9 до 17 августа. Бланки
                                                заявлений абитуриентов в Информационной системе управления образованием
                                                могут быть изменены до истечения крайнего срока подачи заявок.
                                            </span>
                                        </p>
                                        <p className="c27">
                                            <span className="c13">
                                                Для регистрации заявок на 2024-2025 учебный год необходимо авторизоваться
                                                в системе{" "}
                                            </span>
                                            <span className="c31 c13">
                                                <a
                                                    className="c6"
                                                    href="https://www.google.com/url?q=http://www.dimord.emis.am&sa=D&source=editors&ust=1722191177464865&usg=AOvVaw2CUZKYb85s9GKltXKUDuSE"
                                                >
                                                    www.dimord.emis.am
                                                </a>
                                            </span>
                                            <span className="c7">
                                                &nbsp; и следовать установленному порядку и требованиям. Претендент
                                                заполняет анкету в электронном виде в информационной системе управления
                                                образованием, которая включает личные данные (имя, фамилию, отчество, дату
                                                рождения, адрес электронной почты, номер телефона), а также номер
                                                социальной карты или номер отказа от социальной карты, и скачать
                                            </span>
                                        </p>
                                        <p className="c27">
                                            <span className="c7">1) фото,</span>
                                        </p>
                                        <p className="c27">
                                            <span className="c7">
                                                2) копия документа об образовании (вкладка в случае диплома);
                                            </span>
                                        </p>
                                        <p className="c27">
                                            <span className="c7">
                                                3) копия документа, удостоверяющего личность (паспорт или свидетельство о
                                                рождении заявителя до 16 лет, либо военная книжка, либо временный
                                                документ, удостоверяющий личность, выданный УВД РА, свидетельство о
                                                проживании, свидетельство беженца) ;
                                            </span>
                                        </p>
                                        <p className="c27">
                                            <span className="c7">
                                                4) Копия квитанции о перечислении суммы на счет учебного заведения,
                                                подавшего заявку.
                                            </span>
                                        </p>
                                        <p className="c27">
                                            <span
                                                style={{
                                                    overflow: "hidden",
                                                    display: "inline-block",
                                                    margin: "0.00px 0.00px",
                                                    border: "0.00px solid #000000",
                                                    transform: "rotate(0.00rad) translateZ(0px)",
                                                    WebkitTransform: "rotate(0.00rad) translateZ(0px)",
                                                    width: "577.50px",
                                                    height: "344.92px"
                                                }}
                                            >
                                                <img
                                                    alt=""
                                                    src={image1}
                                                    style={{
                                                        width: "577.50px",
                                                        height: "344.92px",
                                                        marginLeft: "0.00px",
                                                        marginTop: "0.00px",
                                                        transform: "rotate(0.00rad) translateZ(0px)",
                                                        WebkitTransform: "rotate(0.00rad) translateZ(0px)"
                                                    }}
                                                    title=""
                                                />
                                            </span>
                                        </p>
                                        <p className="c4">
                                            <span className="c7" />
                                        </p>
                                        <p className="c27">
                                            <span className="c7">
                                                Поступающий платит 1000 (тысяч) драмов на счет Учреждения за организацию и
                                                проведение приема по программе среднего профессионального образования.
                                            </span>
                                        </p>
                                        <p className="c27">
                                            <span className="c7">
                                                РА Фин. Операционный отдел, N 900018001660, нал. код: 02538414
                                            </span>
                                        </p>
                                        <p className="c29 c44 c69">
                                            <span className="c13">
                                                Ереванский государственный художественный колледж имени{" "}
                                            </span>
                                            <span className="c13 c44 c70">Ф</span>
                                            <span className="c7">
                                                . Терлемезяна Фамилия, имя, отчество, Цель: прием
                                            </span>
                                        </p>
                                        <p className="c27">
                                            <span className="c7">
                                                Вне зависимости от результата участия в конкурсе, уплаченная сумма
                                                поступающему не возвращается.
                                            </span>
                                        </p>
                                        <p className="c44 c55" id="h.30j0zll-6">
                                            <span className="c15 c13">
                                                Приемные места по специальностям.*- на основе общего образования
                                            </span>
                                        </p>
                                        <p className="c29">
                                            <span className="c15 c13 c44">Дата экзаменов</span>
                                        </p>
                                        <p className="c29">
                                            <span
                                                style={{
                                                    overflow: "hidden",
                                                    display: "inline-block",
                                                    margin: "0.00px 0.00px",
                                                    border: "0.00px solid #000000",
                                                    transform: "rotate(0.00rad) translateZ(0px)",
                                                    WebkitTransform: "rotate(0.00rad) translateZ(0px)",
                                                    width: "541.50px",
                                                    height: "268.15px"
                                                }}
                                            >
                                                <img
                                                    alt=""
                                                    src={image2}
                                                    style={{
                                                        width: "541.50px",
                                                        height: "268.15px",
                                                        marginLeft: "0.00px",
                                                        marginTop: "0.00px",
                                                        transform: "rotate(0.00rad) translateZ(0px)",
                                                        WebkitTransform: "rotate(0.00rad) translateZ(0px)"
                                                    }}
                                                    title=""
                                                />
                                            </span>
                                        </p>
                                        <p className="c8 c30">
                                            <span className="c15 c25" />
                                        </p>
                                        <p className="c8">
                                            <span className="c15 c25">
                                                ՕՏԱՐԵՐԿՐԱՑԻ ԵՎ ՍՓՅՈՒՌՔԱՀԱՅ ԴԻՄՈՐԴՆԵՐԻ ՓԱՍՏԱԹՂԹԵՐԻ ԸՆԴՈՒՆՈՒՄ
                                            </span>
                                        </p>
                                        <p className="c8">
                                            <span className="c15 c25">(2024-2025 ուսումնական տարի)</span>
                                        </p>
                                        <p className="c8 c30">
                                            <span className="c15 c25" />
                                        </p>
                                        <p className="c47">
                                            <span className="c15 c10">
                                                ՀՀ ԿԳՄՍ նախարարությունը սկսում է օտարերկրացի և սփյուռքահայ դիմորդների
                                                փաստաթղթերի ընդունումը՝ 2024-2025 ուսումնական քոլեջ ընդունվելու
                                                &nbsp;համար: Սփյուռքահայ և օտարազգի դիմորդները, փաստաթղթերը պետք է
                                                ներկայացնեն Նախարարություն հետևյալ ժամկետներում՝
                                            </span>
                                        </p>
                                        <p className="c47 c30">
                                            <span className="c15 c10" />
                                        </p>
                                        <a id="t.aedf091b79279fb46bc95a6087efdabb3ceb77ec" />
                                        <a id="t.4" />
                                        <table className="c87">
                                            <tbody>
                                                <tr className="c45">
                                                    <td className="c98" colSpan={1} rowSpan={1}>
                                                        <p className="c8">
                                                            <span className="c16 c25">Հ/հ</span>
                                                        </p>
                                                    </td>
                                                    <td className="c105" colSpan={1} rowSpan={1}>
                                                        <p className="c8">
                                                            <span className="c16 c25">Կրթական ծրագիր</span>
                                                        </p>
                                                    </td>
                                                    <td className="c95" colSpan={1} rowSpan={1}>
                                                        <p className="c8">
                                                            <span className="c16 c25">Փաստաթղթերի ներկայացման ժամկետ</span>
                                                        </p>
                                                    </td>
                                                    <td className="c85" colSpan={1} rowSpan={1}>
                                                        <p className="c8">
                                                            <span className="c16 c25">Գիտելիքների ստուգում</span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr className="c45">
                                                    <td className="c56" colSpan={1} rowSpan={1}>
                                                        <ol className="c53 lst-kix_ksw90rhurm6d-0 start" start={1}>
                                                            <li className="c34 c64 c30 li-bullet-0">
                                                                <span className="c16 c10" />
                                                            </li>
                                                        </ol>
                                                    </td>
                                                    <td className="c81" colSpan={1} rowSpan={1}>
                                                        <p className="c8">
                                                            <span className="c16 c10">Վճարովի առկա ուսուցմամբ</span>
                                                        </p>
                                                        <p className="c8">
                                                            <span className="c16 c10">(ուսման վարձի չափը՝ 550,000 դրամ),</span>
                                                        </p>
                                                        <p className="c8">
                                                            <span className="c16 c10">միջին մասնագիտական կրթություն</span>
                                                        </p>
                                                    </td>
                                                    <td className="c92" colSpan={1} rowSpan={1}>
                                                        <p className="c8">
                                                            <span className="c16 c10">2024 թ. հուլիսի 3-ից</span>
                                                        </p>
                                                        <p className="c8">
                                                            <span className="c16 c10">մինչև</span>
                                                        </p>
                                                        <p className="c8">
                                                            <span className="c16 c10">օգոստոսի 15-ը ներառյալ, </span>
                                                        </p>
                                                        <p className="c8">
                                                            <span className="c16 c10">ՀՀ ԿԳՄՍ նախարարություն</span>
                                                        </p>
                                                    </td>
                                                    <td className="c99" colSpan={1} rowSpan={1}>
                                                        <p className="c8">
                                                            <span className="c16 c10">2024 թ. օգոստոսի 17,</span>
                                                        </p>
                                                        <p className="c8">
                                                            <span className="c54 c10 c38">ժամը 10:00,</span>
                                                        </p>
                                                        <p className="c8">
                                                            <span className="c10 c38 c54">
                                                                Երևանի Փ. Թերլեմեզյանի անվ. գեղարվեստի պետական քոլեջ
                                                            </span>
                                                        </p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <p className="c75 c90">
                                            <span className="c15 c25">
                                                Փաստաթղթերը Նախարարություն ներկայացվում են`{" "}
                                            </span>
                                        </p>
                                        <ul className="c53 lst-kix_q8rzbeuzvuep-0 start">
                                            <li className="c5 li-bullet-0">
                                                <span className="c15 c10">
                                                    դիմորդի կողմից անձամբ կամ նրա լիազորված անձի կողմից, &nbsp;
                                                </span>
                                            </li>
                                            <li className="c5 li-bullet-0">
                                                <span className="c15 c10">ուսումնական հաստատության կողմից,</span>
                                            </li>
                                            <li className="c5 li-bullet-0">
                                                <span className="c15 c10">
                                                    օտարերկրյա պետություններում ՀՀ դիվանագ. ներկայացուցչությունների միջոցով:
                                                </span>
                                            </li>
                                        </ul>
                                        <p className="c75 c30 c90">
                                            <span className="c15 c25" />
                                        </p>
                                        <p className="c75 c90">
                                            <span className="c15 c25">
                                                Դիմորդները ներկայացնում են հետևյալ փաստաթղթերը՝
                                            </span>
                                        </p>
                                        <ol className="c53 lst-kix_1pgvgs4dy4rh-0 start" start={1}>
                                            <li className="c22 li-bullet-0">
                                                <span className="c15 c10">Դիմում` համաձայն կից ներկայացված ձևի,</span>
                                            </li>
                                            <li className="c22 li-bullet-0">
                                                <span className="c10 c24">
                                                    Դիմում-հարցաթերթիկ` համաձայն կից ներկայացված ձևի,
                                                </span>
                                            </li>
                                            <li className="c22 li-bullet-0">
                                                <span className="c10 c24">
                                                    բժշկական տեղեկանք` ընդհանուր առողջական վիճակի մասին (սույն տեղեկանքը
                                                    կարող է տրված լինել նաև Հայաստանի Հանրապետության բժշկական
                                                    կազմակերպությունների կողմից),
                                                </span>
                                            </li>
                                            <li className="c22 li-bullet-0">
                                                <span className="c10 c24">անձնագրի պատճենը,</span>
                                            </li>
                                            <li className="c22 li-bullet-0">
                                                <span className="c10 c24">
                                                    սփյուռքահայ դիմորդների համար` ծննդյան վկայականի պատճենը` նոտարով
                                                    վավերացված կամ մկրտության վկայականի պատճենը,
                                                </span>
                                            </li>
                                            <li className="c22 li-bullet-0">
                                                <span className="c10 c24">
                                                    կրթական աստիճանի վերաբերյալ ավարտական փաստաթղթի պատճենը
                                                    (գնահատականներով)` թարգմանված և նոտարական, իսկ առանձին երկրների դեպքում`
                                                    &nbsp;նաև միջազգային վավերացմամբ (ապոստիլ),
                                                </span>
                                            </li>
                                            <li className="c22 li-bullet-0">
                                                <span className="c10 c24">3x4 չափսի 4 լուսանկար,</span>
                                            </li>
                                            <li className="c22 li-bullet-0">
                                                <span className="c10 c24">
                                                    ինքնակենսագրություն (CV)` հայերենով, ռուսերենով կամ անգլերենով,
                                                </span>
                                            </li>
                                        </ol>
                                        <p className="c47">
                                            <span className="c10 c44 c24">Դիմորդը</span>
                                            <span className="c10 c24">&nbsp;</span>
                                            <span className="c10 c44 c24">վերոնշյալ կետերով (1-9)</span>
                                            <span className="c10 c24">&nbsp;</span>
                                            <span className="c10 c44 c24">
                                                փաստաթղթերը սահմանված ժամկետներում պետք է ներկայացնի Նախարարություն
                                                արտաքին կապերի և սփյուռքի վարչություն կամ փաստաթղթերի էլեկտրոնային
                                                տարբերակները պետք է ուղարկի էլեկտրոնային հասցեին։
                                            </span>
                                        </p>
                                        <p className="c75">
                                            <span className="c10 c24">
                                                Համաձայն փաստաթղթերի ներկայացման ժամկետների՝ դիմումներն ու փաստաթղթերը
                                                Նախարարությունում ընդունվում են աշխատանքային օրերին, ժամը 10:00-ից մինչև
                                                17:00-ը, ընդմիջում՝ 13:00-ից մինչև 14:00-ը:
                                            </span>
                                            <span className="c25 c24">&nbsp;</span>
                                            <span className="c10 c24">
                                                2024-2025 ուսումնական տարում օտարերկրյա դիմորդների ընդունելությունն
                                                իրականացվելու է միջին մասնագիտական հաստատությունում լիցենզիայով սահմանված
                                                համապատասխան տեղերի առկայության պարագայում:{" "}
                                            </span>
                                            <span className="c10 c44 c24">Լրացուցիչ տեղեկությունների</span>
                                            <span className="c10 c24">&nbsp;</span>
                                            <span className="c10 c44 c24">
                                                համար կարելի է դիմել Նախարարության արտաքին
                                            </span>
                                            <span className="c24 c96">&nbsp;</span>
                                            <span className="c10 c44 c24">կապերի և</span>
                                            <span className="c96 c24">&nbsp;</span>
                                            <span className="c10 c44 c24">
                                                սփյուռքի վարչության հետևյալ բաժիններին՝{" "}
                                            </span>
                                            <span className="c25 c44 c71">secretariat@escs.am</span>
                                        </p>
                                        <ul className="c53 lst-kix_q8rzbeuzvuep-0">
                                            <li className="c5 li-bullet-0">
                                                <span className="c23">սփյուռքի հետ կապերի բաժին</span>
                                                <span className="c44 c24 c73">&nbsp;</span>
                                                <span className="c15 c25 c44">&nbsp;(հեռ.+374 10-599-647),</span>
                                            </li>
                                            <li className="c37 c64 li-bullet-0">
                                                <span className="c23">արտաքին կապերի բաժին (հեռ.+374 10-599-646)։</span>
                                            </li>
                                        </ul>
                                        <p className="c27">
                                            <span className="c79 c13">
                                                <a
                                                    className="c6"
                                                    href="https://www.google.com/url?q=https://drive.google.com/file/d/1gh4KRbdkHBbPpSqQEO89LaBNBGeChiuK/view?usp%3Dsharing&sa=D&source=editors&ust=1722191177473436&usg=AOvVaw0yUrsvwIneQp3nZMBLLc-i"
                                                >
                                                    https://drive.google.com/file/d/1gh4KRbdkHBbPpSqQEO89LaBNBGeChiuK/view?usp=sharing
                                                </a>
                                            </span>
                                        </p>
                                        <p className="c4">
                                            <span className="c7" />
                                        </p>
                                        <p className="c27">
                                            <span className="c13 c79">
                                                <a
                                                    className="c6"
                                                    href="https://www.google.com/url?q=https://drive.google.com/file/d/178E2rwZsmViOhyTzCywxthlntdd2YAk-/view?usp%3Dsharing&sa=D&source=editors&ust=1722191177474097&usg=AOvVaw0tT6nKtBMc30p7Xfgd72uR"
                                                >
                                                    https://drive.google.com/file/d/178E2rwZsmViOhyTzCywxthlntdd2YAk-/view?usp=sharing
                                                </a>
                                            </span>
                                        </p>
                                        <p className="c4">
                                            <span className="c7" />
                                        </p>
                                    </>

                                </article>
                            </div>
                        </section>
                    </>
                )
            }
        </>
    )
}

export default connect(r => r)(Admission)