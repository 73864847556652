import img1 from '../../images/contdir.jpg'
import img2 from '../../images/zam1.jpg'
import img3 from '../../images/zam2.jpg'
import img4 from '../../images/zam3.jpg'
import img5 from '../../images/zam5.jpg'
import img6 from '../../images/zam5_1.jpg'
import img7 from '../../images/zam6.jpg'

export default{
    contacts:[
        {img:img1,name:'Աննա Մնացականյան',position:'Տնօրեն',contact1:'Հեռ.` (+374) 10 443804',contact2:'Էլ. փոստ` terlemezyanartcollege@gmail.com'},
        {img:img2,name:'Արևիկ Սարգսյան',position:'Տնօրենի տեղակալ ուսումնական աշխատանքների գծով',contact1:'Հեռ.` (+374) 10 442672',contact2:'Էլ. փոստ` terlemezyanartcollege@gmail.com'},
        {img:img5,name:'Ելենա Մովսեսյան',position:'Տնօրենի տեղակալ կազմակերպադաստիարակչական աշխատանքների գծով',contact1:'Հեռ.` (+374) 10 443804',contact2:'Էլ. փոստ`  terlemezyangallery@gmail.com'},
        {img:img4,name:'Նարեկ Գասպարյան',position:'Տնօրենի տեղակալ վարչատնտեսական աշխատանքների գծով',contact1:'Հեռ.` (+374) 10 442542',contact2:'Էլ. փոստ` terlemezyanartcollege@gmail.com'},
        {img:img6,name:'Ալինա Բոյաջյան',position:'Որակի ապահովման  պատասխանատու',contact1:'Հեռ.` (+374) 10 443804',contact2:'Էլ. փոստ` terlemezyanartcollege@gmail.com'},
        {img:img7,name:'Արփի Հովսեփյան',position:'Կարիերայի կենտրոնի պատասխանատու',contact1:'Հեռ.` (+37410) 442542',contact2:'Էլ. փոստ`  terlemezyancareer@gmail.com'},
    ],
    contactInputs:{name:'',surname:'', email:'',message:''},
    errors:{error1:'',error2:''}
}