import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Contact from '../../parts/contactForm'

import './lib.css'
import img1 from '../../images/libbg.jpg'
import img2 from '../../images/lib1.jpg'
import img3 from '../../images/lib2.jpg'
import img4 from '../../images/lib3.jpg'
import img5 from '../../images/lib4.jpg'
import img6 from '../../images/lib5.jpg'

import 'react-slideshow-image/dist/styles.css'
 
import VisibilitySensor from 'react-visibility-sensor'
import CountUp, { useCountUp } from 'react-countup'
import { Typography } from '@material-ui/core'

import Aos from 'aos';
import 'aos/dist/aos.css';
import Preloader from '../../parts/preloader'


function Library({ className, ...rest }){   
  const[load,setLoad]=useState(true)
  
    useEffect(()=>{
      window.scrollTo(0, 0);
      setLoad(false)
    },[])

   
  document.title='Library - Terlemezyan'
  const [viewPortEntered, setViewPortEntered] = useState(false);
    return(
      <>
        {
          load==true?(
            <Preloader/>
          ):(
            <div className='home-page'>
        <div style={{backgroundImage:`url(${img1})`,textAlign:'center',height:'390px'}}>
            <img src={img2} style={{height:'100%',width:'100%', objectFit:'cover',objectPosition: '50% 70%'}} />
        </div>
        <section style={{backgroundColor:'white', paddingBottom:'40px'}}>
            <div className='libArt'>
                <h1 style={{fontSize:'36px', textAlign:'center',margin:0,padding:'50px'}}> Գրադարան </h1>
                <p>
                Երևանի Փ. Թերլեմեզյանի անվան գեղարվեստի պետական քոլեջի 1925 
                թվականին հիմնադրված գրադարանը, իրավամբ համարվում է Երևանի 
                ամենահին ու հարուստ գրադարաններից մեկը: Առաջին գրքերը թվագրվում 
                են 1926թ., դրանք ռուսական գույքամատյանում գրանցված “Большая 
                Советская Энциклопедия” հանրագիտարանի 55 հատորներն են, իսկ հայերեն 
                լեզվով առաջին գրքերը եղել են Մարքսիզմ-Լենինիզմի դասականների 
                աշխատությունները: Գրքերը ձեռք են բերվել պետության կողմից, 
                ինչպես նաև անհատ նվիրատուներից:
                </p><br/>
                <p>
                1945 թվականին` պատերազմից հետո, գրադարանի ֆոնդը համալրվել է 
                Գրադարանային կոլեկտորից (ББК), Երևանի գրախանութներից, Մոսկվայից, 
                ԽՍՀՄ այլ քաղաքներից և դեմոկրատական երկրների մայրաքաղաքներից ձեռք 
                բերված գրքերով: Գրադարանն ունի նաև գրքեր հրատարակված օտար լեզուներով` 
                լեհերեն, ռումիներեն, գերմաներեն, հունգարերեն, չեխերեն, անգլերեն, ֆրանսերեն:</p><br/>
                <p>
                2000 թվականից սկսած մինչ օրս գրադարանը պարբերաբար հարստանում է որակյալ տպագրությամբ  
                կերպարվեստի, երաժշտության, կինոյի ու թատրոնի և արվեստի այլ ոլորտների ամենատարբեր գրքերով:
                </p>
            </div>
            <div className='phRow' data-aos='fade-up'>
                <div className='ph'>
                    <img src={img3}/>
                </div>
                <div className='ph'>
                    <img src={img4}/>
                </div>
            </div>
            
            <div className='phRow' data-aos='fade-up'>
                 <div className='ph'>
                    <img src={img5}/>
                </div>
                <div className='ph'>
                    <img src={img6}/>
                </div>
             </div>
            <p>2007 թվականին գեղարվեստի քոլեջը 
                տեղափոխվեց նոր ու հարմարավետ 
                ուսումնական շենք: Գրադարանն ամբողջովին 
                վերահավաքվեց և այդ նվիրական գործում իր 
                մասնակցությունն ունեցավ Հայ Օգնության 
                Միությունը (ՀՕՄ), որը ժամանակակից 
                չափանիշներին համապատասխան կահավորեց 
                գրադարանը և համալրեց համապատասխան տեխնիկայով: 
                Այսօր գրադարանի ֆոնդը կազմում է 
                մոտ 13000 կտոր գիրք, որի մեծ մասը 
                արվեստի գրքեր են, ինչպես նաև գրադարանն 
                ունի հնատիպ գրքեր` հրատարակված  1912թ.:</p><br/>
            <p>Ընթերցասրահն ունի 30 նստատեղ, տարածքը` 69ք/մ: Գրադարանն աշխատում է  ժամը 9:00-16:00:</p>
        </section>
         
        <section className='bgAnalitics'>
        <div className='analitics'>
          <div className='overlay'></div>
          
            <div className='analiticBlock' data-aos='fade-up'>
              <div className='analiticHeading'>
                <h1>Վիճակագրություն</h1>
                <Typography>ՓԱՍՏԵՐ ԹՎԵՐՈՎ...</Typography> 
              </div>
              <div className='analiticIcons'>

                <div className='analiticGlobal'>
                <div className='analiticCard'>
                  <i className='fa fa-institution'></i>
                 </div>
                 <div className='counter'>
                 <CountUp {...rest} start={viewPortEntered ? null : 0} end={new Date().getFullYear() - 1921} duration={3} delay={0.3}>
                    {({ countUpRef }) => {
                      return (
                        <VisibilitySensor
                          active={!viewPortEntered}
                          onChange={isVisible => {
                            if (isVisible) {
                              setViewPortEntered(true);
                            }
                          }}
                          delayedCall
                        >
                          <span className={className} ref={countUpRef} />
                        </VisibilitySensor>
                      );
                    }}
                  </CountUp>
                      <Typography>ՏԱՐՎԱ ՊԱՏՄՈՒԹՅՈՒՆ</Typography>
                  </div>
                </div>

                <div className='analiticGlobal'>
                <div className='analiticCard'>
                  <i className='fa fa-group'></i>
                </div>
                  <div className='counter'>
                  <CountUp {...rest} start={viewPortEntered ? null : 0} end={809} duration={3} delay={0.3}>
                    {({ countUpRef }) => {
                      return (
                        <VisibilitySensor
                          active={!viewPortEntered}
                          onChange={isVisible => {
                            if (isVisible) {
                              setViewPortEntered(true);
                            }
                          }}
                          delayedCall
                        >
                          <span className={className} ref={countUpRef} />
                        </VisibilitySensor>
                      );
                    }}
                  </CountUp>                   
                      <Typography>ՈՒՍԱՆՈՂ</Typography>
                  </div>
                </div>
                  
                <div className='analiticGlobal'>
                <div className='analiticCard'>
                  <i className='fa fa-graduation-cap'></i>
                </div>
                <div className='counter'>
                <CountUp {...rest} start={viewPortEntered ? null : 0} end={7395} duration={3} delay={0.3}>
                    {({ countUpRef }) => {
                      return (
                        <VisibilitySensor
                          active={!viewPortEntered}
                          onChange={isVisible => {
                            if (isVisible) {
                              setViewPortEntered(true);
                            }
                          }}
                          delayedCall
                        >
                          <span className={className} ref={countUpRef} />
                        </VisibilitySensor>
                      );
                    }}
                  </CountUp>  
                      <Typography>ՇՐՋԱՆԱՎԱՐՏ</Typography>
                  </div>
                </div>
                
                <div className='analiticGlobal'>
                <div className='analiticCard'>
                  <i className='fa fa-book'></i>
                </div>
                <div className='counter'>
                <CountUp {...rest} start={viewPortEntered ? null : 0} end={13000} duration={3} delay={0.3}>
                    {({ countUpRef }) => {
                      return (
                        <VisibilitySensor
                          active={!viewPortEntered}
                          onChange={isVisible => {
                            if (isVisible) {
                              setViewPortEntered(true);
                            }
                          }}
                          delayedCall
                        >
                          <span className={className} ref={countUpRef} />
                        </VisibilitySensor>
                      );
                    }}
                  </CountUp>  
                       <Typography>ԳԻՐՔ՝ ԳՐԱԴԱՐԱՆՈՒՄ</Typography>
                  </div>
                </div>
                
              </div>
            </div>
        </div>  
        </section>

       <Contact/>
      </div>

          )
        }
      </>
    )
}

export default connect(r=>r)(Library)