import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import bg1 from '../../images/Untitled-1-2.jpg'
import './prCss.css'
import 'react-slideshow-image/dist/styles.css'
import { Fade } from 'react-slideshow-image';

import r1s1 from '../../images/p2s1.jpg'
import r1s2 from '../../images/p2s2.jpg'
import r1s3 from '../../images/p2s3.jpg'
import r1s4 from '../../images/p2s4.jpg'

import r2s1 from '../../images/p3s1.jpg'
import r2s2 from '../../images/p3s2.jpg'
import r2s3 from '../../images/p3s3.jpg'
import r2s4 from '../../images/p3s4.jpg'

import r3s1 from '../../images/r3s1.jpg'
import r3s2 from '../../images/r3s2.jpg'
import r3s3 from '../../images/r3s3.jpg'
import r3s4 from '../../images/r3s4.jpg'
import r3s5 from '../../images/r3s5.jpg'

import r4s1 from '../../images/r4s1.jpg'
import r4s2 from '../../images/r4s2.jpg'
import r4s3 from '../../images/r4s3.jpg'
import r4s4 from '../../images/r4s4.jpg'
import r4s5 from '../../images/r4s5.jpg'

import r5s1 from '../../images/r5s1.jpg'
import r5s2 from '../../images/r5s2.jpg'
import r5s3 from '../../images/r5s3.jpg'
 
import r6s1 from '../../images/r6s1.jpg'
import r6s2 from '../../images/r6s2.jpg'
import r6s3 from '../../images/r6s3.jpg'
 
import r7s1 from '../../images/r7s1.jpg'
import r7s2 from '../../images/r7s2.jpg'
import r7s3 from '../../images/r7s3.jpg'
import r7s4 from '../../images/r7s4.jpg'
import r7s5 from '../../images/r7s5.jpg'
import Preloader from '../../parts/preloader'
 
    




function Programm(props){
    document.title='Programms - Terlemezyan'
    const[load,setLoad]=useState(true)
  
    useEffect(()=>{
      window.scrollTo(0, 0);
      setLoad(false)
    },[])

   
    const sliderDescrip = [
        {title:'Թերլեմեզյանցիները Ֆրանսիայում',text:'2013 թվականի դեկտեմբերին Փ. Թերլեմեզյանի անվան գեղարվեստի պետական քոլեջի և ՀՀ Ֆրանսիայի դեսպանության համատեղ՝ «Պիկասոն իմ աչքերով» ցուցահանդես-մրցույթի շրջանակներում հաղթող ճանաչված ուսանողներն ու երկու մասնակից 2013/թ հոկտեմբերին ուղևորվեցին Ֆրանսիա՝ Մարսել, Փարիզ:', href:'https://www.facebook.com/pg/terlemezyan.college/photos/?tab=album&album_id=802325486492958', img:[r1s1,r1s2,r1s3,r1s4]},
        {title:'Թերլեմեզյանցիները Իտալիայում',text:'2014 թվականի նոյեմբերին Փ. Թերլեմեզյանի անվան գեղարվեստի պետական քոլեջի  ուսանողներն  անվճար  դասընթաց անցան  Ֆլորենցիայի Ակադեմիայում:', href:'https://www.facebook.com/pg/terlemezyan.college/photos/?tab=album&album_id=825300377528802', img:[r2s1,r2s2,r2s3,r2s4]},
        {title:'Թերլեմեզյանցիները Ռուսաստանում',text:'Միջազգային պլեներ ԱՊՀ երկրների գեղարվեստի քոլեջների ուսանողների մասնակցությամբՙ Ի. Կրամսկոյի ծննդավայր Օստրոգոժսկումՙ Վորոնեժի նահանգ: ', href:'https://www.facebook.com/pg/terlemezyan.college/photos/?tab=album&album_id=945629708829201', img:[r3s1,r3s2,r3s3,r3s4,r3s5]},
        {title:'Թերլեմեզյանցիները Իսպանիայում',text:'Իսպանական բալլադ. Թերլեմեզյանցիները Մալագայում…', href:'https://www.facebook.com/pg/terlemezyan.college/photos/?tab=album&album_id=1193608394031330&ref=page_internal', img:[r4s1,r4s2,r4s3,r4s4,r4s5]},
        {title:'Թերլեմեզյանցիները Չեխիայում',text:'Չեխիայի Բռնո քաղաքում մայիսի 19-26 տեղի ունեցան հայկական մշակույթի օրեր: Հայաստանի կրթության և գիտության նախարարության և Չեխիայի կրթության, երիտասարդության և սպորտի նախարարության համատեղ նախաձեռնությամբ իրականացվեց կերպարվեստի բնագավառի ուսանողական փոխանակման ծրագիր, որի շրջանակներում Բռնո այցելեցին Երևանի Փ. Թերլեմեզյանի անվան գեղարվեստի պետական քոլեջի 15 ուսանող: Չեխիայում նրանց հյուրընկալեց Բռնոյի Պավել Կրժիշկովսկու անվան արվեստների գիմնազիան:', href:'https://www.facebook.com/pg/terlemezyan.college/photos/?tab=album&album_id=730286977030143', img:[r5s1,r5s2,r5s3]},
        {title:'Թերլեմեզյանցիները Բրիտանիայում',text:'Այցելություն աշխարհի լավագույն թանգարաններ:', href:'https://www.facebook.com/pg/terlemezyan.college/photos/?tab=album&album_id=730286977030143', img:[r6s1,r6s2,r6s3]},
        {title:'Թերլեմեզյանցիները Լեհաստան',text:'Հայաստանը լեհ երիտասարդների աչքերով: Ուսանողների փոխանակման ծրագրեր և համատեղ նկարչական պլեներներ  Լեհաստանի մշակույթի և ազգային ժառանգության նախարարության ենթակայության ներքո գործող Կիելցե քաղաքի Յուզեֆ Շերմենտովսկու անվան Գեղարվեստի ուսումնարանի և Թերլեմեզյանի քոլեջի միջև համագործակցության շրջանակներում:', href:'https://www.facebook.com/pg/terlemezyan.college/photos/?tab=album&album_id=1824907327568097', img:[r7s1,r7s2,r7s3,r7s4,r7s5]},
    ]

    const photos = [
        r1s1,
        r1s2,
        r1s3,
        r1s4,
    ]
    const styles={
        head:{
            backgroundColor: `rgba(188,174,173,0.13)`,
            backgroundImage:`url(${bg1})`,
            backgroundPosition: '50% 80%',
            backgroundSize: 'cover',
            height:'366px'
        }
    }

    const slide = {
         slider:{
             maxHeight:300+'px'
         },
         slimg:{
            //  width:'100%'
         }
      };

    const properties = {
        autoplay: true,
        indicators: true,
        onChange: (previous, next) => {
          setPreviousIndex(previous);
          setNextIndex(next);
        }
      };
    const [previousIndex, setPreviousIndex] = useState(null);
    const [nextIndex, setNextIndex] = useState(null);

    return(
      <>
        {
            load==true?(
                <Preloader/>
            ):(
                <>
        <div className='programm-page' style={styles.head}></div>
            <div className='globalDescription'>
                {
                    sliderDescrip.map((item,index)=>{
                        return(
                            <div className='descripRow' key={index}>
                                <div className='descText'>
                                    <h2>{item.title}</h2>
                                    <p>{item.text}</p>
                                    <div className='link'>
                                        <a href={item.href}>Ավելին</a>
                                    </div>
                                </div>
                                <div className='descSlider'>
                                    <div className='descSlider'>
                                        <Fade {...properties}>
                                            {
                                                item.img.map((itm,i)=>{
                                                    return <div key={i} style={slide.slider}><img src={itm} style={slide.slimg}/></div>
                                                })
                                            }
                                        </Fade>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
                
               </div>
        </>
 
            )
        }
      </>  
    )
}

export default connect(r=>r)(Programm)