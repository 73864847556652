import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom';
import { getEvent,loadSpinner,openSlider } from '../../redux/actions';
import './event.css'
import Gallery from 'react-photo-gallery';
import SliderComponent from './sliderComponent';
import Loading from '../loadingComponent/Loading'
function Events(props){
  
    let path = useParams();
    let info = props.gal1.events 
     
     
    useEffect(()=>{
        props.dispatch(getEvent(path.id))
    },[])
    return(
        <>
        <SliderComponent/>
        {
            props.gal1.loadSpinner==false?(
                <Loading/>
            ):(
                <div className='events-page'>
                   <div className='event-card'>
                       <div className='event-img'>
                            <img src={`http://localhost:5000/${info.img}.jpg`}/>
                       </div>
                       <div className='event-detales'>
                            <h3>{info.title}</h3>
                            <p>{info.text}</p>
                            <p>{info.author}</p>
                       </div>
                   </div>
                   <div className='gallery-slide'>
                        <div className='all-images'>
                            <Gallery photos={props.gal1.imgs} onClick={(e)=>props.dispatch(openSlider(e.target.src))}/>
                        </div>
                   </div>
                </div> 

            )
        }
        </>
    )
}

export default connect(r=>r)(Events)