import navState from '../states/navState'

export default function(state=navState,action){
    const temp = {...state}
        if(action.type==='openBurger'){
            if(temp.burgerState===false){
                temp.burgerState=true
            }else{
                temp.burgerState=false
            }

            return temp
        }

        if(action.type==='closeburgerMenu'){
            
            temp.burgerState=false
            return temp
        }
    return temp
}